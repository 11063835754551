import {
  getKindergartenByIdClearRequest,
  getKindergartenByIdRequest,
  getKindergartensRequest,
  getPersonnelGroupByRequest,
  restoreKindergartenByIdRequest
} from "@/helpers/api/kindergartens";
import {
  postDeleteUserFromGroupRequest,
  deleteFromOrganizationByIdRequest,
  getUserByIdRequest
} from "@/helpers/api/user";
import { csvExport } from "@/helpers/CsvHelper";
import { getRoleGroupsRequest } from "@/helpers/api/role-groups";
import { getBasicAccessGroupsRequest } from "@/helpers/api/access-group";
import {
  getPersonnelKindergartensRequest
  // deletePersonnelKindergartensRequest
} from "@/helpers/api/kindergartens";
import { checkIsSameGroup } from "@/helpers/functions";
import { deleteArrPersonalRequest } from "@/helpers/api/user";

const state = {
  pageKindergartensList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  KindergartenId: "",
  KindergartenPersonnelGroup: [],
  kindergartenInfo: { results: null },

  KindergartenPersonnelList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
};
const getters = {
  getPageKindergartensList: state => state.pageKindergartensList,
  getKindergartenId: state => state.KindergartenId,
  getKindergartenPersonnelGroupID: state =>
    state.KindergartenPersonnelGroup[0].id,
  getKindergartenPersonnelList: state => state.KindergartenPersonnelList,
  getKindergartenInfo: state => state.kindergartenInfo
};

const actions = {
  async getKindergartenInfoById(context) {
    const id = context.getters.getPageQuery.id;
    return new Promise((resolve, reject) => {
      getKindergartenByIdRequest({ id })
        .then(response => {
          context.commit("setKindergartenInfo", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  downloadPageKindergartensList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getKindergartensRequest({
        query: { ...query, limit: 5000, offset: null }
      })
        .then(response => {
          csvExport(response.data, header, "ДетскиеСады");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPageKindergartensList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getKindergartensRequest({ query: { ordering, limit: 100, ...query } })
        .then(response => {
          context.commit("setKindergartensList", {
            listName: "pageKindergartensList",
            kindergartens: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async clearKindergartensPage(context) {
    context.commit("clearKindergartensList", {
      listName: "pageKindergartensList"
    });
    return Promise.resolve();
  },

  async fetchPersonnelGroup(context, query) {
    const prom = getPersonnelGroupByRequest(query).then(res => {
      context.commit("SET_PERSONNEL_GROUP", res.data.results);
    });
    return prom;
  },

  async deleteArrPersonal(context, { data }) {
    const prom = await deleteArrPersonalRequest({ data });
    return prom;
  },

  async deletePersonnelFromGroup(context, { id, kindergartenId, role }) {
    // при удалении пользователя из группы Воспитателя/администратора/другой персонал он автоматически удаляется из группы персонал
    const roleGroups = (
      await getRoleGroupsRequest({
        query: {
          organization: kindergartenId
        }
      })
    ).data.results;
    //нашли id группы воспитателей/заведущей/другой персонал
    const groupIdByRole = roleGroups.find(el => el.role == role).id;

    //id группы с бесплатным доступом к объекту
    const accessFreeGroupId = (
      await getBasicAccessGroupsRequest({
        query: {
          organization: kindergartenId,
          role: 120
        }
      })
    ).data.results[0].id;

    const userInfo = (await getUserByIdRequest({ id })).data;
    const userAccessGroups = userInfo.access_groups;

    // если пользоватлель входит в группу с бесплатным доступом
    if (checkIsSameGroup(userAccessGroups, accessFreeGroupId)) {
      await postDeleteUserFromGroupRequest({
        data: {
          group: accessFreeGroupId
        },
        id: id
      });
    }

    await postDeleteUserFromGroupRequest({
      data: {
        group: groupIdByRole
      },
      id: id
    });

    return;
  },

  async deletePersonnel(context, { id, kindergartens_pk }) {
    await deleteFromOrganizationByIdRequest({
      id,
      data: { organization: kindergartens_pk }
    });
    // await deletePersonnelKindergartensRequest({ id, kindergartens_pk });
    return;
  },

  async fetchKindergartenPersonnelList(context) {
    const id = context.getters.getCurrentOrganization;
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getKindergartenByIdClearRequest({ id });

      getPersonnelKindergartensRequest({ id, query })
        .then(response => {
          context.commit("SET_KINDERGARTEN_PERSONNEL_LIST", {
            listName: "KindergartenPersonnelList",
            payload: response.data
          });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  async clearKindergartenPersonnelList(context) {
    context.commit("CLEAR_KINDERGARTEN_PERSONNEL_LIST", {
      listName: "KindergartenPersonnelList"
    });
    return Promise.resolve();
  },
  //востановление детского сада из удаленных
  async restoreKindergarten(context, { id, data }) {
    return new Promise((resolve, reject) => {
      restoreKindergartenByIdRequest({ id, data })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  setKindergartensList(state, { listName, kindergartens }) {
    state[listName] = kindergartens;
  },
  clearKindergartensList(state, { listName }) {
    state[listName].results = [];
  },
  setKindergartensId(state, { id }) {
    state.KindergartenId = id;
  },
  SET_PERSONNEL_GROUP(state, payload) {
    state.KindergartenPersonnelGroup = payload;
  },
  SET_KINDERGARTEN_PERSONNEL_LIST(state, { listName, payload }) {
    state[listName].count = payload.count;
    state[listName].next = payload.next;
    state[listName].previous = payload.previous;
    state[listName].results = payload.results;
  },
  SET_KINDERGARTEN_PERSONNEL_ID_LIST(state, { listName, payload }) {
    state[listName].results = payload;
  },
  CLEAR_KINDERGARTEN_PERSONNEL_LIST(state, { listName }) {
    state[listName].count = 0;
    state[listName].next = null;
    state[listName].previous = null;
    state[listName].results = [];
  },
  setKindergartenInfo(state, objectInfo) {
    state.kindergartenInfo.results = objectInfo;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

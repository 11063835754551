import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/ru.json";
import moment from "moment";
import Vue from "vue";
import { getUsersClearRequest } from "@/helpers/api/user";

export function initValidation() {
  Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: messages[rule] // assign serviceTask);
    });
  });

  extend("price", {
    validate(value) {
      const priceRegExp = new RegExp("^\\d*\\.?\\d{0,2}$");
      return typeof value === "string" && priceRegExp.test(value);
    },
    message: "цена должна содержать только рубли и копейки"
  });

  extend("file", {
    validate(value) {
      if (value[0].size <= 10) {
        return false;
      } else return true;
    },
    message: "Файл не может быть пустым"
  });

  extend("promocode", {
    validate(value) {
      if (value.length >= 8) return true;
    },
    message: "Введите не менее 8 символов"
  });

  extend("promocodePeriod", {
    validate(value) {
      if (value >= 0 && value <= 90) return true;
    },
    message: "Убедитесь, что введённое значение меньше или равно 90"
  });

  extend("promocodePrecent", {
    validate(value) {
      if (value >= 5 && value <= 100) return true;
    },
    message: "Убедитесь, что введённое значение попадает в диапазон от 5 до 100"
  });

  extend("isEmpty", {
    validate(value) {
      if (value > 0) return true;
    },
    message: "Убедитесь, что введённое значение больше 0"
  });

  extend("duractionDays", {
    validate(value) {
      const date = new Date();
      const outputDate = `${date.getUTCFullYear()} ${date.getUTCMonth() +
        1} ${date.getUTCDate()} `;
      if (moment(value).unix() >= moment(outputDate).unix()) return true;
    },
    message:
      "Убедитесь, что дата начала активации текущее число либо более позднее"
  });

  extend("number", {
    validate(value) {
      const priceRegExp = new RegExp("^[0-9]*$");
      return (
        typeof value === "number" ||
        (typeof value === "string" && priceRegExp.test(value))
      );
    },
    message: "Вводите только цифры"
  });
  extend("phone", {
    validate(phone) {
      const phoneRegExp = new RegExp(
        "^(\\+7|7|8)?[\\s\\-]?\\(?[489][0-9]{2}\\)?[\\s\\-]?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}$"
      );
      return typeof phone === "string" && phoneRegExp.test(phone);
    },
    message: "Телефон должен соответствовать виду +7 (XXX) XXX-XX-XX"
  });
  extend("all_phone", {
    validate(phone) {
      const phoneRegExp = new RegExp(
        "^(\\+7|7|8)?[\\s\\-]?\\(?[0-9][0-9]{2}\\)?[\\s\\-]?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}$"
      );
      return typeof phone === "string" && phoneRegExp.test(phone);
    },
    message: "Телефон должен соответствовать виду +7 (XXX) XXX-XX-XX"
  });

  Vue.component("ValidationProvider", ValidationProvider);
  Vue.component("ValidationObserver", ValidationObserver);
}

async function unicFun(value) {
  return new Promise(resolve => {
    getUsersClearRequest({
      query: {
        phone_number: value.replace(/[^\d+]/g, "").slice(0, 13)
      }
    })
      .then(response => {
        if (response.data.count === 0) {
          return resolve({
            valid: true
          });
        } else {
          return resolve(
            "Данный номер уже используется, введите его выше для выбора существующего пользователя"
          );
        }
      })
      .catch(() => {
        return resolve("Ошибка доступа к серверу");
      });
  });
}

extend("uniquePhone", {
  validate: unicFun
});

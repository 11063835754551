import store from "@/store";
const guards = {
  requiresAuth: requiresAuth,
  recoveryStatusCheck: recoveryStatusCheck,
  registrationStatusCheck: registrationStatusCheck
};
// eslint-disable-next-line no-unused-vars
export function requiresAuth(to, from) {
  const requiresAuth = to.meta.requiresAuth;
  if (typeof requiresAuth === "boolean") {
    if (requiresAuth) {
      return store.getters.isAuthorized || { name: "Login" };
    } else {
      return !store.getters.isAuthorized || { path: "/" };
    }
  } else return true;
}

export function recoveryStatusCheck(to, from) {
  const requiresRecoveryStatus = to.meta.recoveryStatus;
  const recoveryStatus = store.getters.getRecoveryForm.status;
  if (typeof requiresRecoveryStatus === "number") {
    return recoveryStatus >= requiresRecoveryStatus || from;
  } else return true;
}

export function registrationStatusCheck(to, from) {
  const requiresRegistrationStatus = to.meta.registrationStatus;
  const registrationStatus = store.getters.getRegistrationForm.status;
  if (typeof requiresRegistrationStatus === "number") {
    return registrationStatus >= requiresRegistrationStatus || from;
  } else return true;
}

export function guardMaster(to, from) {
  let next = true;
  for (const guard of Object.values(guards)) {
    const res = guard(to, from);
    if (res !== true) {
      next = res;
      break;
    }
  }
  return next;
}

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getCardPricesRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/card-prices/",
      query: {
        ...query
      }
    })
  );
}
export async function createCardPriceRequest({ data }) {
  return Axios.post("/card-prices/", data);
}
export async function getCardPriceByIdRequest({ id }) {
  return Axios.get(`/card-prices/${id}/`);
}

export async function putCardPriceByIdRequest({ id, data }) {
  return Axios.put(`/card-prices/${id}/`, data);
}
export async function patchCardPriceByIdRequest({ id, data }) {
  return Axios.patch(`/card-prices/${id}/`, data);
}
export async function deleteCardPriceByIdRequest({ id }) {
  return Axios.delete(`/card-prices/${id}/`);
}

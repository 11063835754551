import {
  getServiceGroupsRequest,
  createServiceGroupRequest,
  getServiceGroupByIdRequest,
  deleteServiceGroupByIdRequest,
  patchServiceGroupByIdRequest
} from "@/helpers/api/service-groups";
import {
  getObjectsRequest,
  getObjectClearByIdRequest
} from "@/helpers/api/objects";
import Vue from "vue";
import router from "@/router";
import { csvExport } from "@/helpers/CsvHelper";
import * as objects from "@/helpers/api/objects";

const state = {
  pageServiceGroupsList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },

  serviceGroupCreateForm: {
    name: null,
    specialist: null,
    service_organization: null,
    served_objects: [],
    listObjects: []
  },
  serviceGroupEditForm: {
    name: { value: null, isEdit: false },
    specialist: { value: null, isEdit: false },
    service_organization: { value: null, isEdit: false },
    served_objects: { value: [], isEdit: false },
    listObjects: { value: [], isEdit: false }
  },

  serviceGroupInfo: {
    created_at: null,
    created_by: null,
    id: null,
    service_organization: null,
    specialist: null
  },

  serviceGroupObjects: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
};
const getters = {
  getPageServiceGroupsList: state => state.pageServiceGroupsList,
  getServiceGroupCreateForm: state => state.serviceGroupCreateForm,
  getServiceGroupEditForm: state => state.serviceGroupEditForm,
  getServiceGroupInfo: state => state.serviceGroupInfo,
  getServiceGroupObjects: state => state.serviceGroupObjects
};

const actions = {
  fetchPageServiceGroupsList(context) {
    const query = context.getters.getPageQuery.query;
    return new Promise((resolve, reject) => {
      getServiceGroupsRequest({
        query
      })
        .then(response => {
          context.commit("setServiceGroupsList", {
            listName: "pageServiceGroupsList",
            listData: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async prepareEditServiceGroup(context, { id }) {
    const serviceGroup = (await getServiceGroupByIdRequest({ id })).data;
    serviceGroup.listObjects = await Promise.all(
      serviceGroup.served_objects.map(async el => {
        const resObj = (await getObjectClearByIdRequest({ id: el })).data;
        return { value: el, text: resObj.name };
      })
    );
    context.commit("prepareEditServiceGroup", serviceGroup);
  },

  async editServiceGroup(context, { id }) {
    const editForm = context.getters.getServiceGroupEditForm;
    let editedData = {};
    for (let key in editForm) {
      if (editForm[key].isEdit) {
        editedData[key] = editForm[key].value;
      }
    }
    const editProm = patchServiceGroupByIdRequest({ id, data: editedData });
    editProm.then(() => {
      router.go(-1);
    });
    return editProm;
  },

  async createServiceGroup(context) {
    const createForm = context.getters.getServiceGroupCreateForm;
    const createResult = createServiceGroupRequest({
      data: {
        ...createForm
      }
    });
    createResult.then(() => {
      router.go(-1);
      context.commit("clearCreateServiceGroupForm");
    });
    return createResult;
  },

  async fetchPageServiceGroupInfo(context, { id }) {
    return new Promise((resolve, reject) => {
      getServiceGroupByIdRequest({
        id
      })
        .then(response => {
          context.commit("setServiceGroupInfoData", {
            listName: "serviceGroupInfo",
            serviceGroupInfo: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchPageServiceGroupObjects(context) {
    const query = context.getters.getPageQuery.query;
    return new Promise((resolve, reject) => {
      objects
        .getObjectsRequestFromChildren({
          query
        })
        .then(response => {
          context.commit("setServiceGroupsList", {
            listName: "serviceGroupObjects",
            listData: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteServiceGroup(context, { id }) {
    const deleteResult = deleteServiceGroupByIdRequest({
      id
    });

    deleteResult.then(() => {
      router.go(-1);
      context.commit("clearServiceGroupInfoList");
      context.commit("clearServiceGroupObjectsList");
    });
  },
  async downloadServiceGroupsList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getServiceGroupsRequest({
        query: { ...query, limit: 5000, offset: null }
      })
        .then(response => {
          csvExport(response.data, header, "ГруппаОбъектов");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadServiceGroupObjectsList(context, header) {
    const query = context.getters.getPageQuery.query;
    return new Promise((resolve, reject) => {
      getObjectsRequest({ query: { ...query } })
        .then(response => {
          csvExport(response.data, header, "ГруппаОбъектов");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchServiceGroupsList(context) {
    const query = context.getters.getPageQuery.query;
    const id = context.getters.getPageQuery.id;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getServiceGroupsRequest({
        query: { ...query, ordering, service_organization: id }
      })
        .then(response => {
          context.commit("setServiceGroupsList", {
            listName: "pageServiceGroupsList",
            listData: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async downloadServiceGroupsByIdRequest(context, header) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getServiceGroupsRequest({
        query: { ...query, service_organization: id, limit: 5000, offset: null }
      })
        .then(response => {
          csvExport(response.data, header, "ГруппаОбъектов");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async clearServiceGroupsPage(context) {
    context.commit("clearServiceGroupsPageList");
    return Promise.resolve();
  },
  async clearServiceGroupInfo(context) {
    context.commit("clearServiceGroupInfoList");
    return Promise.resolve();
  },
  async clearServiceGroupObjects(context) {
    context.commit("clearServiceGroupObjectsList");
    return Promise.resolve();
  }
};

const mutations = {
  deleteEditServiceGroupObject(state, { number }) {
    state.serviceGroupEditForm.served_objects.value.splice(number, 1);
    state.serviceGroupEditForm.listObjects.value.splice(number, 1);
    state.serviceGroupEditForm.served_objects.isEdit = true;
    state.serviceGroupEditForm.listObjects.isEdit = true;
  },
  setAllEditServiceGroupObjects(state, { objects }) {
    if (objects) {
      state.serviceGroupEditForm.served_objects.value = objects.map(
        el => el.value
      );
      state.serviceGroupEditForm.listObjects.value = objects;
      state.serviceGroupEditForm.served_objects.isEdit = true;
      state.serviceGroupEditForm.listObjects.isEdit = true;
    }
  },
  addEditServiceGroupObject(state, { object }) {
    if (object) {
      let id = object.value;
      let objects = state.serviceGroupEditForm.served_objects.value;
      if (!objects.length || objects.indexOf(id) === -1) {
        state.serviceGroupEditForm.served_objects.value.splice(0, 0, id);
        state.serviceGroupEditForm.listObjects.value.splice(0, 0, object);
      }
    }
  },
  prepareEditServiceGroup(state, serviceGroup) {
    Object.keys(state.serviceGroupEditForm).forEach(key => {
      state.serviceGroupEditForm[key].value = serviceGroup[key];
      state.serviceGroupEditForm[key].isEdit = false;
    });
  },
  changeEditServiceGroupValue(state, { fieldName, value }) {
    state.serviceGroupEditForm[fieldName].value = value;
    state.serviceGroupEditForm[fieldName].isEdit = true;
  },
  clearCreateServiceGroupForm(state) {
    state.serviceGroupCreateForm = {
      name: null,
      specialist: null,
      service_organization: null,
      served_objects: [],
      listObjects: []
    };
  },
  setServiceGroupsList(state, { listName, listData }) {
    state[listName] = listData;
  },
  setServiceGroupCreateForm(state, { fieldName, value }) {
    Vue.set(state.serviceGroupCreateForm, fieldName, value);
  },
  deleteServiceGroupObject(state, { number }) {
    state.serviceGroupCreateForm.served_objects.splice(number, 1);
    state.serviceGroupCreateForm.listObjects.splice(number, 1);
  },
  setAllServiceGroupObjects(state, { objects }) {
    if (objects) {
      state.serviceGroupCreateForm.served_objects = objects.map(el => el.value);
      state.serviceGroupCreateForm.listObjects = objects;
    }
  },
  addServiceGroupObject(state, { object }) {
    if (object) {
      let id = object.value;
      let objects = state.serviceGroupCreateForm.served_objects;
      if (!objects.length || objects.indexOf(id) === -1) {
        state.serviceGroupCreateForm.served_objects.splice(0, 0, id);
        state.serviceGroupCreateForm.listObjects.splice(0, 0, object);
      }
    }
  },
  setServiceGroupInfoData(state, { listName, serviceGroupInfo }) {
    state[listName] = serviceGroupInfo;
  },
  setServiceGroupObjects(state, { listName, listData }) {
    state[listName] = listData;
  },

  clearServiceGroupsPageList(state) {
    state.pageServiceGroupsList = {
      count: 0,
      next: null,
      previous: null,
      results: []
    };
  },
  clearServiceGroupInfoList(state) {
    state.serviceGroupInfo = {
      created_at: null,
      created_by: null,
      id: null,
      service_organization: null,
      specialist: null
    };
  },
  clearServiceGroupObjectsList(state) {
    state.serviceGroupObjects = {
      count: 0,
      next: null,
      previous: null,
      results: []
    };
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

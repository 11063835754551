import store from "@/store";
import { getToken } from "@/helpers/jwtHelper";
import Axios from "axios";

let isTokenRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(callback) {
  refreshSubscribers.push(callback);
}

function onRefreshed() {
  refreshSubscribers.map(callback => callback());
  refreshSubscribers = [];
}

function axiosInterceptor() {
  Axios.interceptors.response.use(
    response => {
      const token = getToken();
      const timeNow = Date.now();
      if (token) {
        if (token.expires * 1000 > timeNow) {
          store.commit("setToken", { token });
        } else if (token.expires * 1000 <= timeNow && !isTokenRefreshing) {
          isTokenRefreshing = true;
          store
            .dispatch("refreshAccessToken")
            .then(() => {
              isTokenRefreshing = false;
              onRefreshed(); // Уведомляем об успешном обновлении токена
            })
            .catch(() => {
              isTokenRefreshing = false;
              onRefreshed(); // Уведомляем об неудачном обновлении токена
            });
        }
      }
      return response;
    },
    error => {
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.data.code === "token_not_valid"
        ) {
          const retryOriginalRequest = new Promise(resolve => {
            subscribeTokenRefresh(() => {
              resolve(Axios(error.config));
            });
          });
          return retryOriginalRequest;
        }
      }
      // noinspection JSIgnoredPromiseFromCall
      if (
        error.response.config.url === "/auth/refresh-token/" &&
        ((error.response.status === 401 &&
          error.response.data.code === "authentication_failed") ||
          (error.response.status === 403 &&
            error.response.data.code === "permission-denied"))
      ) {
        store.commit("logout");
        store.dispatch("errorNotification", {
          errors: [
            {
              field_verbose: "Ошибка",
              message: "Выполнен вход с другого устройства"
            }
          ]
        });
      } else if (
        (error.response &&
          error.response.status === 403 &&
          error.response.data.code === "permission-denied" &&
          error.response.data.code !==
            "Данный метод доступен только неавторизованным пользователям" &&
          error.response.data.detail !==
            "Невозможно авторизоваться с указанными данными" &&
          error.response.data.detail !==
            "У вас недостаточно прав для данного действия" &&
          error.response.data.detail !==
            "Невозможно предоставить доступ на этот объект. У пользователя нет к нему доступа.") ||
        (error.response.status === 401 &&
          error.response.data.code !== "token_not_valid" &&
          error.response.data.code != "users-06" &&
          error.response.data.code != "users-05")
      ) {
        return new Promise((resolve, reject) => {
          store
            .dispatch("refreshAccessToken")
            .then(async () => {
              const { url, method, baseURL } = error.config;
              resolve(Axios.request({ url, method, baseURL }));
            })
            .catch(() => {
              reject(error);
            });
        });
      } else if (error.response && error.response.status === 500) {
        store.dispatch("errorNotification", {
          errors: error.response.data.errors
            ? error.response.data.errors
            : [
                {
                  field_verbose: "   ",
                  message: "Ошибка при обращении на сервер!"
                }
              ]
        });
        return Promise.reject(error);
      } else if (
        (error.response && error.response.status === 400) ||
        error.response.status === 404
      ) {
        store.dispatch("errorNotification", {
          errors: error.response.data.errors
            ? error.response.data.errors
            : [
                {
                  message: "Ошибка при получении данных"
                }
              ]
        });
        return Promise.reject(error);
      } else {
        store.dispatch("errorNotification", {
          errors: error.response.data.errors
            ? error.response.data.errors
            : [
                {
                  field_verbose: "Ошибка",
                  message: error.response.data.message
                    ? error.response.data.message
                    : error.response.data.detail
                }
              ]
        });

        return Promise.reject(error);
      }
    }
  );
}

export function initAxios() {
  //http://89.223.125.49/api/v1/
  let nonEnvBaseUrl =
    window.location.protocol === "https:"
      ? "https://portal.oppen.ru/api/v1/"
      : "http://dev.portal.oppen.ru/api/v1/";

  Axios.defaults.baseURL = process.env.VUE_APP_API_URL || nonEnvBaseUrl; //TODO: change to env

  axiosInterceptor();

  const token = getToken();
  const timeNow = Date.now();
  if (token) {
    if (token.expires * 1000 > timeNow) {
      store.commit("setToken", { token });
    } else if (token.expires * 1000 <= timeNow) {
      store.dispatch("refreshAccessToken");
    } else {
      store.commit("logout");
    }
  } else {
    store.commit("logout");
  }
}

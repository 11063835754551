import controllersRoutes from "@/router/childRoutes/controllersRoutes";
import userGroupRoutes from "@/router/childRoutes/userGroupRoutes";
import ChildrenGroupsRoutes from "@/router/childRoutes/ChildrenGroupsRoutes";
import ChildrenRoutes from "@/router/childRoutes/ChildrenRoutes";

const KindergartenRoutes = [
  {
    name: "PersonnelCreate",
    path: "/personnelCreate",
    component: () => import("@/views/PersonnelPages/personnelCreatePage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  ...ChildrenRoutes(),
  ...ChildrenGroupsRoutes(),
  {
    name: "Children",
    path: "/children/:id",
    component: () => import("@/views/ChildrenPages/ChildrenInfosPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CreateChildren",
    path: "/createChildren",
    component: () => import("@/views/ChildrenPages/ChildrenCreateFormPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "ChildrenGroupsInfo",
    path: "/childrenGroups/:id",
    component: () =>
      import("@/views/ChildrenGroupsPages/ChildrenGroupsInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenGroupsCreate",
    path: "/childrenGroupsCreate",
    component: () =>
      import("@/views/ChildrenGroupsPages/ChildrenGroupsCreatePage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateStaff",
    path: "/createStaff",
    component: () => import("@/views/StaffPages/CreateStaffPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "UsersCreate",
    path: "/createUser",
    component: () => import("@/views/UsersPages/CreateUserPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },

  {
    name: "CreateAdmin",
    path: "/createAdmin",
    component: () => import("@/views/AdminsPage/CreateAdminPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateUserGroup",
    path: "/createUserGroup",
    component: () => import("@/views/UserGroupsPages/CreateUserGroupPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateCardPage",
    path: "/createCardPage",
    component: () => import("@/views/CardsPages/CreateCardPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateController",
    path: "/createController",
    component: () => import("@/views/ControllersPages/ControllerCreatePage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "ControllersInfo",
    path: "/controllers/:id",
    component: () => import("@/views/ControllersPages/ControllersInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  ...controllersRoutes(),
  {
    name: "UserGroupsInfo",
    path: "/userGroups/:id",
    component: () => import("@/views/UserGroupsPages/UserGroupsInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "cardInfo",
    path: "/card/:id",
    component: () => import("@/views/CardsPages/CardsKinderInfosPages"),
    meta: {
      requiresAuth: true
    }
  },

  ...userGroupRoutes(),
  {
    name: "UsersInfo",
    path: "/users/:id",
    component: () => import("@/views/UsersPages/UsersInfoKindergartens.vue"),
    meta: {
      requiresAuth: true
    },
    props: {
      deletePermission: {
        from: ["kindergarten"],
        descriptions: "Пользователь будет удалён с указанного объекта",
        btnTitle: "ПОЛЬЗОВАТЕЛЯ С ОБЪЕКТА"
      }
    }
  },
  {
    name: "ParentList",
    path: "/ParentList",
    component: () => import("@/components/info/children/Parents"),
    meta: {
      requiresAuth: true
    }
  }
];

function fromKindergarten() {
  return KindergartenRoutes.map(el => {
    return {
      ...el,
      name: "FromKindergarten" + el.name,
      path: "/kindergartens/:kindergartenId" + el.path
    };
  });
}

export default fromKindergarten;

import {
  getUserKindergartensByIdRequest,
  patchUserOnKindergartensByIdRequest,
  patchUserPhoneByIdRequest
} from "@/helpers/api/user";
import {
  createRegestrCardRequest,
  exchangeCardsByIdRequest,
  patchCardByIdRequest
} from "@/helpers/api/cards";
import router from "@/router";
import { getKindergartenByIdRequest } from "@/helpers/api/kindergartens";
import { getIdentificatorUserRole } from "@/helpers/Constants/roles";

const state = {
  editParents: {
    // last_name: { value: "", isEdit: false },
    // first_name: { value: "", isEdit: false },
    // middle_name: { value: "", isEdit: false },
    user: { value: "", isEdit: false, id: null },
    comments: { value: "", isEdit: false, id: null },
    phone_number: { value: "", isEdit: false },
    user_payments: { value: "", isEdit: false },
    code: { value: "", isEdit: false },
    input_disabled: { value: "", isEdit: false },
    card_id: { value: "", isEdit: false },
    is_phone_confirmed: { value: "", isEdit: false }
  }
};
const getters = {
  getEditParent: state => state.editParents
};
const actions = {
  async prepareEditParent(context, { id }) {
    const editParents = (
      await getUserKindergartensByIdRequest({
        kindergardens_id: getIdentificatorUserRole(),
        user_id: id
      })
    ).data;
    const kinder = (
      await getKindergartenByIdRequest({ id: getIdentificatorUserRole() })
    ).data;
    let card = (
      await exchangeCardsByIdRequest({
        userId: context.rootState.userInterface.currentRole,
        data: {
          user: id,
          object: kinder.object.id
        }
      })
    ).data;

    if (card) {
      context.commit("prepareEditParent", {
        user: editParents.additional_fields[0].value,
        comments: editParents.additional_fields[1].value,
        phone_number: editParents.phone_number,
        user_payments: card.payment_status,
        is_phone_confirmed: editParents.is_phone_confirmed,
        code: card.card_code,
        input_disabled: card.input_disabled,
        card_id: card.card_id
      });
    } else {
      context.commit("prepareEditParent", {
        user: editParents.additional_fields[0].value,
        comments: editParents.additional_fields[1].value,
        is_phone_confirmed: editParents.is_phone_confirmed,
        phone_number: editParents.phone_number,
        code: ""
      });
    }
  },
  async editParent(context, { id, kinderId }) {
    const editForm = {
      user: context.getters.getEditParent.user,
      comments: context.getters.getEditParent.comments,
      phone_number: context.getters.getEditParent.phone_number,
      code: context.getters.getEditParent.code
    };
    let editedData = [];
    for (let key in editForm) {
      const { isEdit, id, value } = editForm[key];
      if (isEdit) editedData.push({ id, value });
    }
    if (editForm.phone_number.isEdit) {
      await patchUserPhoneByIdRequest({
        id,
        data: { phone_number: editForm.phone_number.value }
      });
    }
    let editProm;
    if (editForm.code.isEdit) {
      editProm = patchUserOnKindergartensByIdRequest({
        kindergartensId: kinderId,
        user_id: id,
        code: editedData
      });
    } else
      editProm = patchUserOnKindergartensByIdRequest({
        kindergartensId: kinderId,
        user_id: id,
        data: { additional_fields: editedData }
      });
    editProm.then(() => {
      router.go(-1);
    });
    if (editForm.code.isEdit) {
      if (
        context.getters.getEditParent.user_payments.value === "input-requied"
      ) {
        const currOrg = context.getters.getCurrentOrganization;
        const kinder = (
          await getKindergartenByIdRequest({ id: kinderId || currOrg })
        ).data;
        return new Promise((resolve, reject) => {
          createRegestrCardRequest({
            data: {
              code: editForm.code.value,
              object: kinder.object.id,
              user: id
            }
          })
            .then(response => {
              resolve();
              return response;
            })
            .catch(error => {
              reject(error);
            });
        });
      } else {
        const cardId = context.getters.getEditParent.card_id;
        const editCard = patchCardByIdRequest({
          id: cardId.value,
          data: { code: editedData.code }
        });
        return editCard;
      }
    }
  }
};
const mutations = {
  prepareEditParent(state, editParents) {
    Object.keys(state.editParents).forEach(key => {
      state.editParents[key].value = editParents[key];
      state.editParents[key].isEdit = false;
    });
  },

  changeEditParent(state, { fieldName, value, id }) {
    state.editParents[fieldName].value = value;
    state.editParents[fieldName].isEdit = true;
    state.editParents[fieldName].id = id;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getCardsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/cards/",
      query: {
        ...query,
        "optional-fields": [
          "user_details",
          "diller",
          "organization_type",
          "group_details",
          "validity",
          "access_time"
        ]
      }
    })
  );
}
export async function getkindergartensCardsRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${id}/cards/`,
      query: {
        ...query,
        "optional-fields": [
          "user_details",
          "diller",
          "organization_type",
          "group_details",
          "validity",
          "access_time"
        ]
      }
    })
  );
}
export async function getCardsServiceRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/cards/services/",
      query: {
        ...query,
        "optional-fields": [
          "user_details",
          "diller",
          "organization_type",
          "group_details",
          "validity",
          "access_time"
        ]
      }
    })
  );
}
export async function getCardsListRequests({ query, data }) {
  return Axios.post(`/cards/objects/${query.id}/report/`, data);
}
export async function getAllCardsListRequests({ data }) {
  return Axios.post(`/cards/report/`, data);
}
export async function getCardsClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/cards/",
      query: {
        ...query
      }
    })
  );
}
export async function exchangeCardsByIdRequest({ data, userId }) {
  if (userId != 21) {
    return Axios.post("/cards/user_card_payment_status/", data);
  } else return { data: null };
}

export async function downloadCardsListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/cards/",
      query: {
        ...query,
        "optional-fields": [
          "diller",
          "group_details",
          // "organization_type",
          "validity",
          "access_time"
        ],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "Карты.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function createCardRequest({ data }) {
  return Axios.post("/cards/", data);
}
export async function createCardListRequest({ data }) {
  return Axios.post("/cards/add-bulk/", data);
}
export async function createRegestrCardRequest({ data }) {
  return Axios.post("/accesses/register_card_on_object/", data);
}

// export async function getCardByIdRequest({ id }) {
//   return Axios.get(`/cards/${id}/`);
// }
export async function getCardByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/cards/${id}/`,
      query: {
        "optional-fields": [
          "user_details",
          "diller",
          "organization_type",
          "group_details",
          "validity",
          "access_time"
        ]
      }
    })
  );
}

export async function getKindergartensCardByIdRequest({ id, cadrId }) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${id}/cards/${cadrId}/`,
      query: {
        "optional-fields": [
          "user_details",
          "diller",
          "organization_type",
          "group_details",
          "validity",
          "access_time"
        ]
      }
    })
  );
}

export async function putCardByIdRequest({ id, data }) {
  return Axios.put(`/cards/${id}/`, data);
}
export async function patchCardByIdRequest({ id, data }) {
  return Axios.patch(`/cards/${id}/`, data);
}
export async function patchCardCodeByIdRequest({ id, data }) {
  return Axios.patch(`/cards/${id}/change-code/`, data);
}
export async function deleteCardByIdRequest({ id }) {
  return Axios.delete(`/cards/${id}/`);
}
export async function deleteArrCardRequest({ data }) {
  return Axios.post(`/cards/delete_cards_bulk/`, data);
}

export async function uploadCardFileRequest({ data }) {
  return Axios.post("/combined-format-card/", data, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export async function userRegisterCardRequest({ data }) {
  return Axios.post("/accesses/user_register_card/", data);
}

export async function userLostCardRequest({ id }) {
  return Axios.get(`/cards/${id}/card_is_lost/`);
}

export async function restoreCardByIdRequest({ id }) {
  return Axios.post(`/cards/${id}/restore/`);
}

import store from "@/store";

let orgNames = [
  "groups__organization",
  "organization",
  "object__organization",
  "kindergarten",
  "kindergarten_org"
];

let dealerNames = [
  "by_diller",
  "diller",
  "organization__diller",
  "groups__organization__diller",
  "object__organization__diller",
  "kindergarten__diller",
  "kindergarten_org__diller"
];

let serviceAdmNames = ["service_organization", "object__service_organization"];

function duplicateId(query = {}, keyNames) {
  let result = {};
  let queryKeys = Object.keys(query);

  let organization = queryKeys.reduce((result, queryEl) => {
    if (result) {
      return result;
    }
    if (keyNames.includes(queryEl)) {
      result = queryEl;
    }
    return result;
  }, null);

  if (organization) {
    const orgId = store.getters.getCurrentOrganization;
    keyNames.forEach(key => {
      result[key] = orgId; // Заполняем все ключи из keyNames orgId
    });
  }

  return { ...query, ...result };
}

function organizationHelper(query) {
  return duplicateId(query, orgNames);
}

function serviceOrganizationHelper(query) {
  return duplicateId(query, serviceAdmNames);
}

function dealerHelper(query) {
  return duplicateId(query, dealerNames);
}

function addOrgId(id, query) {
  return organizationHelper({ organization: id, ...query });
}

function addServiceOrgId(id, query) {
  return serviceOrganizationHelper({ service_organization: id, ...query });
}

function addDillerId(id, query) {
  return dealerHelper({ organization__diller: id, ...query });
}

const querySetter = {
  organizationHelper,
  dealerHelper,
  serviceOrganizationHelper,
  addOrgId,
  addDillerId,
  addServiceOrgId
};

export default querySetter;

<template>
  <v-app>
    <v-main>
      <router-view />
      <Notify />
    </v-main>
  </v-app>
</template>

<script>
import Notify from "@/components/notify/Notify";
export default {
  name: "App",
  components: { Notify },
  methods: {
    SessionCheck() {
      setInterval(() => {
        this.$store.commit("setUserGroupId", localStorage.getItem("groupId"));
      }, 1000);
    }
  },
  mounted() {
    this.SessionCheck(); // функция для выхода по завершению сессии1
  }
};
</script>

import * as organizationsRequests from "@/helpers/api/organizations";
import {
  createAcquiringCredentials,
  createOrganizationsRequest,
  deleteOrganizationsByIdRequest,
  getAcquiringCredentialsByOrgId,
  getOrganizationsByIdRequest,
  getOrganizationsRequest,
  getTypedOrganizationRequest,
  patchAcquiringCredentialsById,
  patchOrganizationsByIdRequest,
  postMarkVbDillerId,
  restoreOrganizationByIdRequest
} from "@/helpers/api/organizations";
import Vue from "vue";
import {
  createContractRequest,
  patchContractByIdRequest
} from "@/helpers/api/contracts";

import {
  createUserWithGroupRequest,
  getUsersRequest,
  postAddUserToGroupRequest,
  postDeleteUserFromGroupRequest
} from "@/helpers/api/user";
import { getRoleGroupsRequest } from "@/helpers/api/role-groups";
import {
  createObjectRequest,
  patchObjectByIdRequest
} from "@/helpers/api/objects";
import router from "@/router";
import { createOrganizationAcquiringCredentialRequest } from "@/helpers/api/organization-acquiring-credentials";
import {
  getKindergartenByIdRequest,
  getKindergartenByIdClearRequest
} from "@/helpers/api/kindergartens";
import { csvExport } from "@/helpers/CsvHelper";

const state = {
  pageOrganizationList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  kindergartenName: { results: null },
  dealerInfo: { results: null },
  dealerOrganizationsList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },

  serviceInfo: { results: null },

  contractsForm: {
    number: "",
    file: null
  },

  organizationCreateForm: {
    group_config: {
      // app_price: null, //ниже стоимость стандартного тарифа (App & Card)
      card_price: null,
      app_price_3: null,
      app_price_12: null,
      tariff_name: "",
      card_duration: null
    },
    free_participant_limit: null,
    code_contractor: "",
    allow_visitlog_control: false,
    serviceOrg: null,
    full_org_name: "",
    is_vb_diller: "",
    actual_address: "",
    name: "",
    ownership: null, //"ИП", "ООО" и тд
    director: null,
    TIN: "", //инн
    PSRN: "", //ОГРН
    IEC: "", //КПП
    CA: "", //"Корреспондентский счёт"
    giro: "", //Расчётный счёт
    RCBIC: "", //БИК
    bank: "", //Название банка
    address: "", //Адрес
    phone_number: "",
    email: "",
    city: null, //id города
    type: "",

    diller: null,
    service: null,
    parent: null,
    acquiring_provider: "no", //Эквайринг
    acquiring_entity: "oppen", //Организация принимающая платежи
    can_accept_payments: true, //Может принимать платежи
    acquiring_password: null,
    acquiring_login: null
  },
  organizationEditForm: {
    group_config: {
      // app_price: { value: "", isEdit: false }, //ниже стоимость стандартного тарифа (App & Card)
      card_price: { value: "", isEdit: false },
      app_price_3: { value: "", isEdit: false },
      app_price_12: { value: "", isEdit: false },
      tariff_name: { value: "", isEdit: false },
      card_duration: { value: "", isEdit: false }
    },
    free_participant_limit: { value: null, isEdit: false },
    code_contractor: { value: "", isEdit: false },
    is_vb_diller: { value: "", isEdit: false },
    actual_address: { value: "", isEdit: false },
    director: { value: "", isEdit: false },
    file: { value: null, isEdit: false },
    fileId: { value: null, isEdit: false },
    number: { value: null, isEdit: false },
    name: { value: "", isEdit: false },
    full_org_name: { value: "", isEdit: false },
    ownership: { value: "", isEdit: false }, //"ИП", "ООО" и тд
    TIN: { value: "", isEdit: false }, //инн
    PSRN: { value: "", isEdit: false }, //ОГРН
    IEC: { value: "", isEdit: false }, //КПП
    CA: { value: "", isEdit: false }, //"Корреспондентский счёт"
    giro: { value: "", isEdit: false }, //Расчётный счёт
    RCBIC: { value: "", isEdit: false }, //БИК
    bank: { value: "", isEdit: false }, //Название банка
    address: { value: "", isEdit: false }, //Адрес
    phone_number: { value: "", isEdit: false },
    email: { value: "", isEdit: false },
    city: { value: null, isEdit: false }, //id города
    type: { value: null, isEdit: false },

    diller: { value: null, isEdit: false },
    service: { value: null, isEdit: false },
    service_organizations: { value: null, isEdit: false }, // обслуживающая организация
    parent: { value: null, isEdit: false },
    acquiring_provider: { value: "no", isEdit: false }, //Эквайринг
    acquiring_login: { value: "", isEdit: false },
    acquiring_password: { value: "", isEdit: false },
    acquiring_id: { value: null, isEdit: false },
    acquiring_entity: { value: "oppen", isEdit: false }, //Организация принимающая платежи
    can_accept_payments: { value: true, isEdit: false } //Может принимать платежи
  },
  kindergartenObjectEditInfo: {}, //информация о объекте детского сада для изменения
  organizationCreateAdminForm: {
    foundUsers: [],
    createUsers: []
  },
  serviceOrganizationList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  addAdminForm: {
    phone_number: "",
    first_name: "",
    last_name: "",
    organizationId: null,
    middle_name: "",
    selectedId: null
  },
  addTechnicianForm: {
    phone_number: "",
    first_name: "",
    last_name: "",
    organizationId: null,
    middle_name: "",
    selectedId: null,
    role: 40
  }
};

const getters = {
  getContractsForm: state => state.contractsForm,
  getOrganizationEditForm: state => state.organizationEditForm,
  getOrganizationCreateAdminForm: state => state.organizationCreateAdminForm,
  getOrganizationCreateForm: state => state.organizationCreateForm,
  getPageOrganizationList: state => state.pageOrganizationList,
  getDealerInfo: state => state.dealerInfo,
  getDealerOrganizationsList: state => state.dealerOrganizationsList,
  getAddAdminForm: state => state.addAdminForm,
  getAddTechnicianForm: state => state.addTechnicianForm,
  getServiceOrganizationList: state => state.serviceOrganizationList,
  getKindergartenName: state => state.kindergartenName,
  getKindergartenObjectInfo: state => state.kindergartenObjectEditInfo
};

const actions = {
  async fetchKindergartensInfo(context) {
    const query = context.getters.getPageQuery.query;
    const id = context.getters.getCurrentOrganization;
    return new Promise((resolve, reject) => {
      organizationsRequests
        .getOrganizationsByIdRequest({ id, query })
        .then(response => {
          context.commit("setOrganizationInfo", {
            infoName: "kindergartenName",
            results: response.data
          });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async deleteAdmin(context, { id, organizationId }) {
    const roles = (
      await getRoleGroupsRequest({
        query: { organization: organizationId }
      })
    ).data.results.filter(el => [30, 50, 60, 70].includes(el.role));

    const prom = postDeleteUserFromGroupRequest({
      id,
      data: { group: roles[0].id }
    });
    // prom.then(() => router.go(-1));
    return prom;
  },
  async addOrganizationAdmin(context) {
    const adminForm = context.getters.getAddAdminForm;
    if (
      adminForm.organizationId == "" ||
      adminForm.organizationId == null ||
      adminForm.organizationId == undefined
    ) {
      const orgId = context.getters.getCurrentOrganization;
      adminForm.organizationId = orgId;
    }

    const roles = await getRoleGroupsRequest({
      query: { organization: adminForm.organizationId }
    });
    const adminRole = roles.data.results.find(
      el => el.role === 60 || el.role === 50 || el.role === 30
    );
    let prom;
    if (!adminForm.selectedId)
      prom = createUserWithGroupRequest({
        data: {
          ...adminForm,
          phone_number: adminForm.phone_number
            .replace(/[^\d+]/g, "")
            .slice(0, 13),
          group: adminRole.id
        }
      });
    else
      prom = postAddUserToGroupRequest({
        id: adminForm.selectedId,
        data: { group: adminRole.id }
      });
    prom.then(() => {
      router.go(-1);
    });
    return prom;
  },
  async addOrganizationTechnician(context) {
    const technicianForm = context.getters.getAddTechnicianForm;

    const roles = await getRoleGroupsRequest({
      query: { organization: technicianForm.organizationId }
    });
    const technicianRole = roles.data.results.find(
      el => el.role === technicianForm.role
    );
    let prom;
    if (!technicianForm.selectedId)
      prom = createUserWithGroupRequest({
        data: {
          ...technicianForm,
          phone_number: technicianForm.phone_number
            .replace(/[^\d+]/g, "")
            .slice(0, 13),
          group: technicianRole.id
        }
      });
    else
      prom = postAddUserToGroupRequest({
        id: technicianForm.selectedId,
        data: { group: technicianRole.id }
      });
    prom.then(() => {
      router.go(-1);
    });

    return prom;
  },
  async fetchPageOrganizationList(context, type = "default") {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getTypedOrganizationRequest({ type, query: { ordering, ...query } })
        .then(response => {
          context.commit("setOrganizationList", {
            listName: "pageOrganizationList",
            organizations: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchServiceOrganizationList(context) {
    const { id, query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getOrganizationsRequest({
        query: { ...query, "service-orgs-for-org": id, ordering }
      })
        .then(response => {
          context.commit("setOrganizationList", {
            listName: "serviceOrganizationList",
            organizations: response.data
          });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  // TODO: продумать логику для обновления всех организации при выборе роли
  async fetchUpdateInfo(context) {
    const { query } = context.getters.getPageQuery;
    const comp = (
      await organizationsRequests.getOrganizationsByIdRequest({
        id: query.organization,
        query
      })
    ).data;
    const kinder = (
      await getKindergartenByIdRequest({ id: query.kindergarten })
    ).data;
    localStorage.setItem("UserFieldId", kinder.additional_fields[0].id);
    localStorage.setItem("CommentsFieldId", kinder.additional_fields[4].id);
    context.commit("setOrganizationInfo", {
      infoName: "dealerInfo",
      results: { ...comp, ...kinder }
    });
  },
  async fetchKinderInfo(context) {
    const { query, id } = context.getters.getPageQuery;
    const comp = (
      await organizationsRequests.getOrganizationsByIdRequest({
        id: id ? id : query.organization,
        query
      })
    ).data;
    const kinder = (
      await getKindergartenByIdRequest({ id: id ? id : query.kindergarten })
    ).data;
    localStorage.setItem("UserFieldId", kinder.additional_fields[0].id);
    localStorage.setItem("CommentsFieldId", kinder.additional_fields[4].id);
    context.commit("setOrganizationInfo", {
      infoName: "dealerInfo",
      results: { ...comp, ...kinder }
    });
  },
  async fetchDealerInfo(context) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      organizationsRequests
        .getOrganizationsByIdRequest({ id, query })
        .then(response => {
          context.commit("setOrganizationInfo", {
            infoName: "dealerInfo",
            results: response.data
          });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchGeneralInformationInfo(context) {
    const { query, id } = context.getters.getPageQuery;
    const organization = (await getOrganizationsByIdRequest({ query, id }))
      .data;
    const users = (
      await getUsersRequest({
        query: {
          "technician-of-kg": id == 1 ? context.getters.getKindergartenId : id
        }
      })
    ).data;
    context.commit("setOrganizationInfo", {
      infoName: "dealerInfo",
      results: { ...organization, service: users }
    });
  },
  async downloadDealerCompanyList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getOrganizationsRequest({
        query: {
          ...query,
          limit: 5000,
          offset: null,
          diller: id,
          types: ["default", "kindergarten"]
        }
      })
        .then(response => {
          csvExport(response.data, header, "Компании");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadDealerList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getTypedOrganizationRequest({
        type: "dealer",
        query: { ...query, types: ["diller"], limit: 5000, offset: null }
      })
        .then(response => {
          csvExport(response.data, header, "Компании");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadOrgList(context, header) {
    const { query } = context.getters.getPageQuery;

    return new Promise((resolve, reject) => {
      getTypedOrganizationRequest({
        type: "company",
        query: { ...query, types: ["default"], limit: 5000, offset: null }
      })
        .then(response => {
          csvExport(response.data, header, "Компании");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchDealerOrganizationList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      organizationsRequests
        .getOrganizationsRequest({
          query: {
            ...query,
            ordering,
            diller: id,
            types: ["default"]
          }
        })
        .then(response => {
          context.commit("setOrganizationList", {
            listName: "dealerOrganizationsList",
            organizations: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async fetchDealerOrganizationListKg(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      organizationsRequests
        .getOrganizationsRequest({
          query: {
            ...query,
            ordering,
            diller: id,
            types: ["kindergarten"]
          }
        })
        .then(response => {
          context.commit("setOrganizationList", {
            listName: "dealerOrganizationsList",
            organizations: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async clearOrganizationPage(context) {
    context.commit("clearOrganizationList", {
      listName: "pageOrganizationList"
    });
    return Promise.resolve();
  },
  async createCompany(context) {
    const organizationCreateForm = context.getters.getOrganizationCreateForm;
    if (!Array.isArray(organizationCreateForm.types))
      organizationCreateForm.types = [organizationCreateForm.types];
    organizationCreateForm.phone_number = organizationCreateForm.phone_number.replace(
      /[^\d+]/g,
      ""
    );
    const { file, number } = context.getters.getContractsForm;

    const {
      foundUsers,
      createUsers
    } = context.getters.getOrganizationCreateAdminForm;
    const createOrganizationResponse = await createOrganizationsRequest({
      data: organizationCreateForm
    });
    const orgId = createOrganizationResponse.data.id;

    if (
      organizationCreateForm.acquiring_login &&
      organizationCreateForm.acquiring_password
    ) {
      await createOrganizationAcquiringCredentialRequest({
        data: {
          login: organizationCreateForm.acquiring_login,
          password: organizationCreateForm.acquiring_password,
          provider: organizationCreateForm.acquiring_provider,
          organization: orgId
        }
      });
    }

    if (organizationCreateForm.is_vb_diller) {
      await postMarkVbDillerId({
        id: orgId,
        data: context.state.organizationCreateForm
      });
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("number", number);
    formData.append("organization", orgId);
    await createContractRequest(formData);

    const accessGroupResponse = await getRoleGroupsRequest({
      query: { organization: orgId }
    });
    const adminRole = accessGroupResponse.data.results.find(
      el => el.role === 60 || el.role === 50 || el.role === 30
    );
    const createUsersPromises = createUsers.map(createUser => {
      return createUserWithGroupRequest({
        data: {
          ...createUser,
          phone_number: createUser.phone_number
            .replace(/[^\d+]/g, "")
            .slice(0, 13),
          group: adminRole.id
        }
      });
    });
    const updateUsersPromises = foundUsers.map(foundUser => {
      return postAddUserToGroupRequest({
        id: foundUser.value.id,
        data: { group: adminRole.id }
      });
    });
    const usersPromises = createUsersPromises.concat(updateUsersPromises);
    await Promise.all(usersPromises);
    let path;
    switch (organizationCreateForm.type) {
      case "default": {
        path = "company";
        break;
      }
      case "service": {
        path = "serviceOrganization";
        break;
      }
      case "diller": {
        path = "dealer";
        break;
      }
      case "kindergarten": {
        path = "kindergartens";
        break;
      }
    }
    router
      .push({ path: `${path}/${createOrganizationResponse.data.id}` })
      .finally(() => {
        context.commit("clearCreateOrganizationForm");
      });
    return createOrganizationResponse;
  },

  async createCompanyKindergarten(context) {
    const organizationCreateForm = JSON.parse(
      JSON.stringify(context.getters.getOrganizationCreateForm)
    );
    const org = (await context.dispatch("createCompany")).data;
    await createObjectRequest({
      data: {
        free_participant_limit: organizationCreateForm.free_participant_limit,
        service_organization: organizationCreateForm.serviceOrg,
        name: organizationCreateForm.name,
        city: organizationCreateForm.city,
        address: organizationCreateForm.address,
        organization: org.id,
        type: "kindergarten"
      }
    });
    return org;
  },
  async deleteCompany(context, { id }) {
    const prom = deleteOrganizationsByIdRequest({ id });
    prom.finally(() => router.go(-1));
    return prom;
  },
  async blockOrganization(context, { id }) {
    const prom = patchOrganizationsByIdRequest({
      id,
      data: { is_active: false }
    });
    prom.then(() => {
      // router.go(-1);
      context.dispatch("fetchDealerInfo");
    });
    return prom;
  },
  async unBlockOrganization(context, { id }) {
    const prom = patchOrganizationsByIdRequest({
      id,
      data: { is_active: true }
    });
    prom.then(() => {
      context.dispatch("fetchDealerInfo");
    });
    return prom;
  },
  async prepareEditCompany(context, { id }) {
    const editOrg = (await getOrganizationsByIdRequest({ id })).data;
    if (editOrg.contract) {
      editOrg.number = editOrg.contract.number;
      editOrg.fileId = editOrg.contract.id;
      editOrg.file = new File([new Blob()], editOrg.contract.name);
    }

    const acquiring = (await getAcquiringCredentialsByOrgId({ id })).data
      .results;
    if (
      acquiring.length > 0 &&
      acquiring.find(el => el.provider === "sberbank")
    ) {
      const sber = acquiring.find(el => el.provider === "sberbank");
      editOrg.acquiring_password = sber.password;
      editOrg.acquiring_login = sber.login;
      editOrg.acquiring_id = sber.id;
    } else {
      editOrg.acquiring_password = "";
      editOrg.acquiring_login = "";
      editOrg.acquiring_id = null;
    }
    context.commit("prepareEditCompany", editOrg);
  },

  async prepareEditKindergarten(context, { id }) {
    const editOrg = (await getOrganizationsByIdRequest({ id })).data;
    const kindergartenObjectInfo = (
      await getKindergartenByIdClearRequest({ id })
    ).data;
    context.commit(
      "prepareKindergartenObjectEditInfo",
      kindergartenObjectInfo.object
    );

    if (editOrg.contract) {
      editOrg.number = editOrg.contract.number;
      editOrg.fileId = editOrg.contract.id;
      editOrg.file = new File([new Blob()], editOrg.contract.name);
    }

    editOrg.free_participant_limit =
      kindergartenObjectInfo.free_participant_limit;
    if (context.rootState.userInterface.currentRole == 70) {
      const acquiring = (await getAcquiringCredentialsByOrgId({ id })).data
        .results;
      if (
        acquiring.length > 0 &&
        acquiring.find(el => el.provider === "sberbank")
      ) {
        const sber = acquiring.find(el => el.provider === "sberbank");
        editOrg.acquiring_password = sber.password;
        editOrg.acquiring_login = sber.login;
        editOrg.acquiring_id = sber.id;
      } else {
        editOrg.acquiring_password = "";
        editOrg.acquiring_login = "";
        editOrg.acquiring_id = null;
      }
    }
    context.commit("prepareEditCompany", editOrg);
  },

  async editCompany(context, { id }) {
    const editForm = context.getters.getOrganizationEditForm;
    editForm.phone_number.value = editForm.phone_number.value.replace(
      /[^\d+]/g,
      ""
    );
    const filekeys = ["number", "file"];

    let editedData = {};
    for (let key in editForm) {
      if (editForm[key].isEdit && !filekeys.includes(key)) {
        if (key == "service_organizations") {
          const KindergartenObjectId =
            context.getters.getKindergartenObjectInfo.id;
          const data = {
            service_organization: editForm[key].value[0].id
          };
          if (editForm.free_participant_limit.isEdit) {
            await patchObjectByIdRequest({
              id: KindergartenObjectId,
              data: context.state.organizationEditForm
            });
          }
          await patchObjectByIdRequest({ id: KindergartenObjectId, data });
        } else {
          editedData[key] = editForm[key].value;
        }
      }
      if (
        key == "group_config" &&
        state.dealerInfo.results?.type == "kindergarten"
      ) {
        editedData[key] = {};
        for (let gc in editForm.group_config) {
          if (editForm[key][gc].isEdit) {
            editedData[key][gc] = editForm[key][gc].value;
          }
        }
      }
    }
    if (
      (editForm.number && editForm.number.isEdit) ||
      (editForm.file && editForm.file.isEdit)
    ) {
      const formData = new FormData();
      if (editForm.file.isEdit) {
        formData.append("file", editForm.file.value);
      }
      if (editForm.number.isEdit) {
        formData.append("number", editForm.number.value);
      }

      await patchContractByIdRequest({
        id: editForm.fileId.value,
        data: formData
      });
    }

    if (editForm.acquiring_id.value) {
      patchAcquiringCredentialsById({
        id: editForm.acquiring_id.value,
        data: {
          login: editForm.acquiring_login.value,
          password: editForm.acquiring_password.value,
          provider: "sberbank",
          organization: id
        }
      });
    } else {
      if (
        editForm.acquiring_login.isEdit ||
        editForm.acquiring_password.isEdit
      ) {
        createAcquiringCredentials({
          data: {
            login: editForm.acquiring_login.value,
            password: editForm.acquiring_password.value,
            provider: "sberbank",
            organization: id
          }
        });
      }
    }
    if (editForm.is_vb_diller.isEdit) {
      await postMarkVbDillerId({
        id,
        data: context.state.organizationEditForm
      });
    }

    const editProm = patchOrganizationsByIdRequest({ id, data: editedData });
    editProm.then(() => {
      router.go(-1);
    });
    return editProm;
  },
  //востановление организации (дилера/компании)
  async restoreOrganization(context, { id, data }) {
    return new Promise((resolve, reject) => {
      restoreOrganizationByIdRequest({ id, data })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  clearAdminForm(state) {
    state.addAdminForm = {
      phone_number: "",
      first_name: "",
      last_name: "",
      organizationId: null,
      middle_name: "",
      selectedId: null
    };
  },
  clearTechnicianForm(state) {
    state.addTechnicianForm = {
      phone_number: "",
      first_name: "",
      last_name: "",
      organizationId: null,
      middle_name: "",
      selectedId: null,
      role: 40
    };
  },
  clearEditCompany(state) {
    state.organizationEditForm = {
      group_config: {
        // app_price: { value: "", isEdit: false }, //ниже стоимость стандартного тарифа (App & Card)
        card_price: { value: "", isEdit: false },
        app_price_3: { value: "", isEdit: false },
        app_price_12: { value: "", isEdit: false },
        tariff_name: { value: "", isEdit: false },
        card_duration: { value: "", isEdit: false }
      },

      free_participant_limit: { value: null, isEdit: false },
      code_contractor: { value: "", isEdit: false },
      is_vb_diller: { value: "", isEdit: false },
      actual_address: { value: "", isEdit: false },
      file: { value: null, isEdit: false },
      fileId: { value: null, isEdit: false },
      number: { value: null, isEdit: false },
      name: { value: "", isEdit: false },
      ownership: { value: "", isEdit: false }, //"ИП", "ООО" и тд
      TIN: { value: "", isEdit: false }, //инн
      PSRN: { value: "", isEdit: false }, //ОГРН
      director: { value: "", isEdit: false },
      IEC: { value: "", isEdit: false }, //КПП
      CA: { value: "", isEdit: false }, //"Корреспондентский счёт"
      giro: { value: "", isEdit: false }, //Расчётный счёт
      full_org_name: { value: "", isEdit: false },
      RCBIC: { value: "", isEdit: false }, //БИК
      bank: { value: "", isEdit: false }, //Название банка
      address: { value: "", isEdit: false }, //Адрес
      phone_number: { value: "", isEdit: false },
      email: { value: "", isEdit: false },
      city: { value: null, isEdit: false }, //id города
      type: { value: null, isEdit: false },

      service_organizations: { value: null, isEdit: false }, // обслуживающая организация
      diller: { value: null, isEdit: false },
      service: { value: null, isEdit: false },
      parent: { value: null, isEdit: false },
      acquiring_login: { value: "", isEdit: false },
      acquiring_password: { value: "", isEdit: false },
      acquiring_id: { value: null, isEdit: false },
      acquiring_provider: { value: "no", isEdit: false }, //Эквайринг
      acquiring_entity: { value: "oppen", isEdit: false }, //Организация принимающая платежи
      can_accept_payments: { value: true, isEdit: false } //Может принимать платежи
    };
  },
  clearCreateOrganizationForm(state) {
    state.organizationCreateForm = {
      group_config: {
        // app_price: null, //ниже стоимость стандартного тарифа (App & Card)
        card_price: null,
        app_price_3: null,
        app_price_12: null,
        tariff_name: "",
        card_duration: null
      },
      free_participant_limit: null,
      allow_visitlog_control: false,
      serviceOrg: null,
      acquiring_password: "",
      acquiring_login: "",
      full_org_name: "",
      code_contractor: null,
      is_vb_diller: "",
      actual_address: "",
      name: "",
      ownership: null, //"ИП", "ООО" и тд
      TIN: "", //инн
      PSRN: "", //ОГРН
      IEC: "", //КПП
      director: null,
      CA: "", //"Корреспондентский счёт"
      giro: "", //Расчётный счёт
      RCBIC: "", //БИК
      bank: "", //Название банка
      address: "", //Адрес
      phone_number: "",
      email: "",
      city: null, //id города
      types: "",

      diller: null,
      service: null,
      parent: null,
      acquiring_provider: "no", //Эквайринг
      acquiring_entity: "oppen", //Организация принимающая платежи
      can_accept_payments: true //Может принимать платежи
    };
    state.contractsForm = {
      number: "",
      file: null
    };

    state.organizationCreateAdminForm = {
      foundUsers: [],
      createUsers: []
    };
  },
  setAddAdminFormValue(state, { fieldName, value }) {
    state.addAdminForm[fieldName] = value;
  },
  setAddTechnicianFormValue(state, { fieldName, value }) {
    state.addTechnicianForm[fieldName] = value;
  },

  prepareEditCompany(state, editOrg) {
    Object.keys(state.organizationEditForm).forEach(key => {
      if (
        key == "group_config" &&
        state.dealerInfo.results?.type == "kindergarten"
      ) {
        Object.keys(state.organizationEditForm.group_config).forEach(
          configKey => {
            state.organizationEditForm.group_config[configKey].value =
              editOrg[key][configKey];
            state.organizationEditForm.group_config[configKey].isEdit = false;
          }
        );
      } else {
        state.organizationEditForm[key].value = editOrg[key];
        state.organizationEditForm[key].isEdit = false;
      }
    });
  },
  prepareKindergartenObjectEditInfo(state, infoObj) {
    state.kindergartenObjectEditInfo = infoObj;
  },
  changeEditOrganizationsValue(state, { fieldName, value }) {
    let arr = fieldName.split(".");
    if (arr[0] == "group_config") {
      fieldName = arr[1];
      state.organizationEditForm.group_config[fieldName].value = value;
      state.organizationEditForm.group_config[fieldName].isEdit = true;
    } else {
      state.organizationEditForm[fieldName].value = value;
      state.organizationEditForm[fieldName].isEdit = true;
    }
  },

  change_service_organizations_EditOrganizationsValue(state, value) {
    state.organizationEditForm.service_organizations = {
      value: [{ id: value }],
      isEdit: true
    };
  },
  setContractsFormValue(state, { fieldName, value }) {
    state.contractsForm[fieldName] = value;
  },

  addCreateAdmin(state) {
    state.organizationCreateAdminForm.createUsers.push({
      phone_number: "",
      middle_name: "",
      first_name: "",
      last_name: ""
    });
  },
  setCreateAdminValue(state, { index, fieldName, value }) {
    Vue.set(
      state.organizationCreateAdminForm.createUsers[index],
      fieldName,
      value
    );
  },
  removeCreateAdmin(state, { index }) {
    state.organizationCreateAdminForm.createUsers.splice(index, 1);
  },
  addSearchCreateAdmin(state) {
    state.organizationCreateAdminForm.foundUsers.push({});
  },
  setSearchCreateAdmin(state, { index, admin }) {
    Vue.set(state.organizationCreateAdminForm.foundUsers, index, admin);
  },

  removeSearchCreateAdmin(state, { index }) {
    state.organizationCreateAdminForm.foundUsers.splice(index, 1);
  },
  setOrganizationCreateForm(state, { fieldName, value }) {
    Vue.set(state.organizationCreateForm, fieldName, value);
  },
  setOrganizationCreateFormGroupConfig(state, { fieldName, value }) {
    Vue.set(state.organizationCreateForm.group_config, fieldName, value);
  },

  setOrganizationInfo(state, { infoName, results }) {
    state[infoName].results = results;
  },
  setOrganizationList(state, { listName, organizations }) {
    state[listName] = organizations;
  },
  clearOrganizationList(state, { listName }) {
    state[listName].results = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getReviewRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/app-review/",
      query: {
        ...query
      }
    })
  );
}

export async function getReviewIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/app-review/${id}/`,
      query: {
        "optional-fields": []
      }
    })
  );
}
// export async function deleteCodeByIdRequest({ key }) {
//   return Axios.delete(`/temp-tokens/${key}/`);
// }
//
// export async function getCodesClearRequest({ query }) {
//   return Axios.get(
//     getQueriedUrl({
//       url: "/temp-tokens/",
//       query: {
//         ...query
//       }
//     })
//   );
// }

import { getPaymentsDealer } from "@/helpers/api/stats";

const state = {
  payments: {},
  dealerCount: {},
  dealerWidgets: {},
  defaultPeriod: {
    dealer: "year"
  },
  periodList: [
    {
      value: "year",
      text: "C начала года",
      moment: "MMM"
    }
  ]
};

const getters = {
  getDealerCount: state => state.dealerCount,
  getDealerWidgets: state => state.dealerWidgets,
  getStatPeriodInfo: state => state.defaultPeriod,
  getStatPeriodListInfo: state => state.periodList
};

const actions = {
  async fetchDealerCountWidgetPage({ getters, commit }) {
    const yearQuery = getters.getPageQuery.query.year;
    const monthQuery = getters.getPageQuery.query.month;

    const response = await getPaymentsDealer({
      query: { year: yearQuery, month: monthQuery }
    });

    if (response.data === "STARTED") {
      await new Promise(resolve => setTimeout(resolve, 5000)); // Пауза в 5 секунд
      return actions.fetchDealerCountWidgetPage({ getters, commit });
    }

    commit("SET_STATS", { listName: "dealerCount", payload: response.data });
    return response;
  },

  async fetchDealerDate({ getters, commit }) {
    const yearQuery = getters.getPageQuery.query.year;

    const response = await getPaymentsDealer({ query: { year: yearQuery } });

    if (response.data === "STARTED") {
      await new Promise(resolve => setTimeout(resolve, 5000)); // Пауза в 5 секунд
      return actions.fetchDealerDate({ getters, commit });
    }

    commit("SET_STATS", { listName: "dealerWidgets", payload: response.data });
    return response;
  }
};

const mutations = {
  SET_STATS(state, { listName, payload }) {
    state[listName] = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

import { getUserCountStats, getPaymentsStats } from "@/helpers/api/stats";
// import router from "@/router";

const state = {
  usersCount: {},
  payments: {},
  defaultPeriod: {
    user: "year",
    payments: "year"
  },
  periodList: [
    {
      value: "year",
      text: "C начала года",
      moment: "MMM"
    },
    {
      value: "quarter",
      text: "C начала квартала",
      moment: "MMM"
    },
    {
      value: "month",
      text: "C начала месяца",
      moment: "D"
    }
  ]
};

const getters = {
  getUsersCount: state => state.usersCount,
  getStatPeriod: state => state.defaultPeriod,
  getStatPeriodList: state => state.periodList,
  getStatPayments: state => state.payments
};

const actions = {
  async fetchUserCountWidgetPage(context) {
    const period = context.getters.getStatPeriod.user;
    return new Promise((resolve, reject) => {
      getUserCountStats({ query: { period } })
        .then(response => {
          context.commit("SET_STATS", {
            listName: "usersCount",
            payload: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async fetchPaymentsWidgetPage(context) {
    const period = context.getters.getStatPeriod.payments;
    return new Promise((resolve, reject) => {
      getPaymentsStats({ query: { period } })
        .then(response => {
          context.commit("SET_STATS", {
            listName: "payments",
            payload: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_STATS(state, { listName, payload }) {
    state[listName] = payload;
  },
  SET_STAT_DEFAULT_PERIOD(state, { fieldName, payload }) {
    state.defaultPeriod[fieldName] = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

import * as objects from "@/helpers/api/objects";
import {
  getObjectsRequest,
  getObjectByIdRequest,
  createObjectRequest,
  deleteObjectByIdRequest,
  patchObjectByIdRequest,
  restoreObjectByIdRequest
} from "@/helpers/api/objects";
import Vue from "vue";
import router from "@/router";
import { csvExport } from "../../../helpers/CsvHelper";
import { getUserObjectsByIdRequest } from "@/helpers/api/user";
const state = {
  objectsAddress: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  pageObjectsList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  pageObjectsExtendedList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  OrganizationObjectsList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  objectCreateForm: {
    name: "",
    city: null,
    address: "",
    organization: null,
    type: null,
    service_organization: null,
    service_group: null
  },
  userObjectsList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  dealerObjectsList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  objectEditForm: {
    address: { value: "", isEdit: false },
    city: { value: null, isEdit: false },
    free_participant_limit: { value: null, isEdit: false },
    service_organization: { value: null, isEdit: false },
    name: { value: "", isEdit: false },
    type: { value: "", isEdit: false },
    organization_diller: { value: null, isEdit: false },
    organization: { value: null, isEdit: false }
  },
  objectInfo: { results: null }
};
const getters = {
  getUserObjectsList: state => state.userObjectsList,
  getObjectsAddress: state => state.objectsAddress,
  getPageObjectsList: state => state.pageObjectsList,
  getPageObjectsExtendedList: state => state.pageObjectsExtendedList,
  getOrganizationObjectsList: state => state.OrganizationObjectsList,
  getObjectCreateForm: state => state.objectCreateForm,
  getObjectEditForm: state => state.objectEditForm,
  getObjectInfo: state => state.objectInfo,
  getDealerObjectsList: state => state.dealerObjectsList
};
const actions = {
  async searchObjectAddress(context, { city = null, search = null }) {
    objects.getObjectsAddressRequest({ search, city }).then(response => {
      context.commit("setObjectsAddress", { objectsAddress: response.data });
    });
  },

  async downloadServiceOrganizationObjectsList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      objects
        .getObjectsRequestFromChildren({
          query: {
            ...query,
            limit: 5000,
            offset: null,
            service_organization: id
          }
        })
        .then(response => {
          csvExport(response.data, header, "Объекты");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
    // return downloadObjectsListRequest({
    //   query: { ...query, organization: id }
    // });
  },
  async downloadOrganizationObjectsList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getObjectsRequest({
        query: { ...query, limit: 5000, offset: null, organization: id }
      })
        .then(response => {
          csvExport(response.data, header, "Объекты");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadDealerObjectsList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getObjectsRequest({
        query: { ...query, limit: 5000, offset: null, organization__diller: id }
      })
        .then(response => {
          csvExport(response.data, header, "Объекты");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadPageObjectsList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      objects
        .getObjectsRequestFromChildren({
          query: { ...query, limit: 5000, offset: null }
        })
        .then(response => {
          csvExport(response.data, header, "Объекты");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchPageObjectList(context) {
    let query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    const userRole = context.getters.getCurrentRole;

    return new Promise((resolve, reject) => {
      if (userRole == "40") {
        const userId = context.getters.getUserId;

        query = {
          ...query,
          specialist: userId
        };
        objects
          .getObjectsRequestFromChildren({
            query: { ordering, ...query, specialist: userId }
          })
          .then(response => {
            context.commit("setObjectsList", {
              listName: "pageObjectsList",
              objects: response.data
            });
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      }
      if (userRole == "50") {
        objects
          .getObjectsRequestFromChildren({ query: { ordering, ...query } })
          .then(response => {
            context.commit("setObjectsList", {
              listName: "pageObjectsList",
              objects: response.data
            });
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      }
      if (userRole != "50" && userRole != "40") {
        getObjectsRequest({ query: { ordering, limit: 100, ...query } })
          .then(response => {
            context.commit("setObjectsList", {
              listName: "pageObjectsList",
              objects: response.data
            });
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  },
  async fetchPageObjectExtendedList(context) {
    const { query } = context.getters.getPageQuery;
    const id = context.getters.getUserId;
    return new Promise((resolve, reject) => {
      getUserObjectsByIdRequest({ query, id })
        .then(response => {
          context.commit("setObjectsList", {
            listName: "pageObjectsExtendedList",
            objects: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async clearObjectPage(context) {
    context.commit("clearObjectsList", {
      listName: "pageObjectsList"
    });
    return Promise.resolve();
  },
  async fetchOrganizationObjectsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      objects
        .getObjectsRequestFromChildren({
          query: { ...query, organization: id, ordering }
        })
        .then(response => {
          context.commit("setObjectsList", {
            listName: "OrganizationObjectsList",
            objects: response.data
          });
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchServiceOrganizationObjectsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      objects
        .getObjectsRequestFromChildren({
          query: { ...query, ordering, service_organization: id }
        })
        .then(response => {
          context.commit("setObjectsList", {
            listName: "OrganizationObjectsList",
            objects: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchDealerObjectsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      objects
        .getObjectsRequest({
          query: { ...query, ordering, organization__diller: id }
        })
        .then(response => {
          context.commit("setObjectsList", {
            listName: "dealerObjectsList",
            objects: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchUserObjectsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      objects
        .getObjectsRequest({
          query: { ...query, ordering, groups__user: id }
        })
        .then(response => {
          context.commit("setObjectsList", {
            listName: "userObjectsList",
            objects: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadUserObjectsList(context, header) {
    const { id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getUserObjectsByIdRequest({
        query: { limit: 5000, offset: null },
        id
      })
        .then(response => {
          csvExport(response.data, header, "Объекты");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchObjectsListUser(context) {
    const { id, query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getUserObjectsByIdRequest({
        id,
        query: {
          ...query,
          ordering
        }
      })
        .then(response => {
          context.commit("setObjectsList", {
            listName: "userObjectsList",
            objects: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchStaffObjects(context) {
    const { query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      objects
        .getObjectsRequestFromChildren({
          query: {
            ...query,
            ordering
          }
        })
        .then(response => {
          context.commit("setObjectsList", {
            listName: "userObjectsList",
            objects: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async getObjectInfoById(context) {
    const id = context.getters.getPageQuery.id;
    return new Promise((resolve, reject) => {
      getObjectByIdRequest({ id })
        .then(response => {
          localStorage.setItem(
            "UserFieldId",
            response.data.additional_fields[0].id
          );
          localStorage.setItem(
            "HouseFieldId",
            response.data.additional_fields[1].id
          );
          localStorage.setItem(
            "ApartmentFieldId",
            response.data.additional_fields[2].id
          );
          localStorage.setItem(
            "CarNumberFieldId",
            response.data.additional_fields[3].id
          );
          localStorage.setItem(
            "CommentsFieldId",
            response.data.additional_fields[4].id
          );
          context.commit("setObjectInfo", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async createObject(context) {
    const objectCreateForm = context.getters.getObjectCreateForm;
    const createResult = createObjectRequest({ data: objectCreateForm });

    createResult.then(res => {
      router.replace({ path: `objects/${res.data.id}` });
      context.commit("clearCreateObjectForm");
    });
    return createResult;
  },
  async prepareEditObject(context, { id }) {
    // const controllerInfo = context.getters.getControllerInfo.results;
    //
    const objectInfo = (await getObjectByIdRequest({ id })).data;
    context.commit("prepareEditObject", objectInfo);
  },
  async editObject(context, { id }) {
    const editForm = context.getters.getObjectEditForm;
    let editedData = {};
    for (let key in editForm) {
      if (editForm[key].isEdit) {
        editedData[key] = editForm[key].value;
      }
    }
    const editProm = patchObjectByIdRequest({ id, data: editedData });
    editProm.then(() => {
      if (!context.getters.getKindergartenId) {
        router.go(-1);
      }
    });
    return editProm;
  },

  async deleteObject(context, { id }) {
    router.go(-1);
    return deleteObjectByIdRequest({ id });
  },
  async banObject(context, { id }) {
    const prom = patchObjectByIdRequest({ id, data: { is_active: false } });
    prom.then(() => {
      context.dispatch("getObjectInfoById");
    });
    return prom;
  },
  async unBlockObject(context, { id }) {
    const prom = patchObjectByIdRequest({ id, data: { is_active: true } });
    prom.then(() => {
      context.dispatch("getObjectInfoById");
    });
    return prom;
  },
  //востановление объекта
  async restoreObject(context, { id, data }) {
    return new Promise((resolve, reject) => {
      restoreObjectByIdRequest({ id, data })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const mutations = {
  clearCreateObjectForm(state) {
    state.objectCreateForm = {
      name: "",
      city: null,
      address: "",
      organization: null,
      type: null,
      service_organization: null,
      service_group: null
    };
  },
  prepareEditObject(state, editObject) {
    Object.keys(state.objectEditForm).forEach(key => {
      if (key == "organization_diller") {
        state.objectEditForm[key].value = editObject[key].id;
        state.objectEditForm[key].isEdit = false;
      } else {
        state.objectEditForm[key].value = editObject[key];
        state.objectEditForm[key].isEdit = false;
      }
    });
  },
  changeEditObjectValue(state, { fieldName, value }) {
    state.objectEditForm[fieldName].value = value;
    state.objectEditForm[fieldName].isEdit = true;
  },
  setObjectCreateFormValue(state, { fieldName, value }) {
    Vue.set(state.objectCreateForm, fieldName, value);
  },
  setObjectsAddress(state, { objectsAddress }) {
    state.objectsAddress = objectsAddress;
  },
  setObjectsList(state, { listName, objects }) {
    state[listName] = objects;
  },
  clearObjectsList(state, { listName }) {
    state[listName].results = [];
  },
  setObjectInfo(state, objectInfo) {
    state.objectInfo.results = objectInfo;
  },
  SET_ACCOUNTS_ENABLED(state, status) {
    state.objectInfo.results.accounts_enabled = status;
  },
  SET_GUESTACCESS_ENABLED(state, status) {
    state.objectInfo.results.guest_access_allowed = status;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

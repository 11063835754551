import Axios from "axios";
import router from "@/router";
import tabbarLinks from "@/helpers/tabbars";
import rolesDictionary from "@/helpers/userInterface";

const state = {
  currentRole: parseInt(localStorage.getItem("role"), 10) || null,
  currentOrganization:
    parseInt(localStorage.getItem("organization"), 10) || null,
  currentUserGroupId: parseInt(localStorage.getItem("groupId"), 10) || null,
  roles: []
};

const getters = {
  getCurrentOrganization: state => state.currentOrganization,
  getCurrentRole: state => {
    return state.currentRole;
  },
  getRoles: state => {
    let roles = state.roles.sort((a, b) => {
      return b.role - a.role;
    });

    let result = roles.reduce((resultArr, element) => {
      if (element.role !== 5 && element.role !== 10) {
        resultArr.push(element);
      } else {
        let founded = resultArr.find(el => el.role === 10 || el.role === 5);
        if (!founded) {
          resultArr.push({ ...element, count: 1 });
        } else {
          founded.count++;
        }
      }

      return resultArr;
    }, []);

    return result.map(el => {
      if (state.currentRole === 5 || state.currentRole === 10) {
        if (el.role === state.currentRole) return { ...el, active: true };
        else return { ...el, active: false };
      } else {
        if (
          el.role === state.currentRole &&
          el.id === Number(state.currentUserGroupId)
        ) {
          return { ...el, active: true };
        } else {
          return { ...el, active: false };
        }
      }
    });
  },
  getCurrentUserGroupId: state => {
    return state.currentUserGroupId;
  },
  getCurrentRoleName: state => rolesDictionary[state.currentRole]
};

const actions = {
  async fetchUserRoles(context) {
    return new Promise((resolve, reject) => {
      Axios.get(`/user-interface/`)
        .then(response => {
          const roles = response.data.results;
          const trueRoles = roles.map(el => {
            if (el.title.includes("Заведующие")) return { ...el, role: 31 };
            else if (el.title.includes("Воспитатели"))
              return { ...el, role: 21 };
            else return el;
          });
          context.commit("setRoles", trueRoles);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async firstFetchUsersRoles(context) {
    context.dispatch("fetchUserRoles").then(() => {
      if (context.getters.getRoles.length <= 1) {
        // const oldRole = context.getters.getCurrentRole;
        const selectRole = context.getters.getRoles[0] || {
          role: 10,
          id: null,
          organization_id: null
        };
        context.dispatch("selectRole", {
          role: selectRole.role,
          userGroupId: selectRole.id,
          organization: selectRole.organization_id
        });
        const avTabs = tabbarLinks.filter(el =>
          el.userRole.includes(selectRole.role)
        );
        if (avTabs.length > 0) {
          context.commit("SET_TABBAR_CHECKED", false);
          router.push(avTabs[0].to);
        } //Todo write push to firs available tab
      }
    });
  },
  async selectRole(context, test) {
    context.commit("setCurrentOrganization", test.organization);
    context.commit("updateCurrentRole", test.role);
    if (test.role !== 5 && test.role !== 10) {
      context.commit("setUserGroupId", test.userGroupId);
    } else {
      context.commit("setUserGroupId", null);
    }
  }
};

const mutations = {
  updateCurrentRole(state, role) {
    state.currentRole = role;
    localStorage.setItem("role", role);
  },
  setCurrentOrganization(state, organization) {
    localStorage.setItem("organization", organization);
    state.currentOrganization = organization;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },

  setUserGroupId(state, userGroupId) {
    localStorage.setItem("groupId", userGroupId);
    state.currentUserGroupId = userGroupId;
    Axios.defaults.headers["X-OPPEN-USER-INTERFACE"] = userGroupId;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

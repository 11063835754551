import {
  getControllerFirmwaresByIdRequest,
  deleteFirmwareByIdRequest,
  getControllerFirmwareRequest,
  deleteHashByIdRequest
} from "@/helpers/api/controller-firmwares";
import router from "@/router";

const state = {
  pageFirmwaresList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  firmwareInfo: null,
  newFile: null,
  addingFirmware: {
    chip: null,
    is_active: null,
    is_test: null,
    version: null,
    comment: null,
    board_version: null,
    file: null
  } // при добавлении новой прошивки
};

const getters = {
  getFirmwaresList: state => state.pageFirmwaresList,
  getFirmwareInfo: state => state.firmwareInfo,
  getNewFile: state => state.newFile,
  getAddingFirmware: state => state.addFirmware
};

const actions = {
  fetchPageFirmwaresList(context) {
    const ordering = context.getters.getOrdering;
    const queries = context.getters.getUrlQueris; // url queries

    return new Promise((resolve, reject) => {
      getControllerFirmwareRequest({
        query: {
          ordering,
          ...queries
        }
      })
        .then(response => {
          context.commit("SET_FIRMWARES_LIST", {
            listName: "pageFirmwaresList",
            listData: response.data
          });
        })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async clearFirmwaresListPage(context) {
    context.commit("CLEAR_FIRMWARES_LIST_PAGE");
    return Promise.resolve();
  },

  async fetchFirmwareInfo(context, { id }) {
    return new Promise((resolve, reject) => {
      getControllerFirmwaresByIdRequest({
        id
      })
        .then(response => {
          context.commit("SET_FIRMWARE_INFO", {
            listName: "firmwareInfo",
            payload: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async clearFirmwareInfo(context) {
    context.commit("CLEAR_FIRMWARE_INFO");
    return Promise.resolve();
  },

  async deleteFirmware(context, { id }) {
    const prom = deleteFirmwareByIdRequest({ id });
    prom.then(() => {
      router.go(-1);
    });
    return prom;
  },
  async deleteHash(context, { id }) {
    const prom = deleteHashByIdRequest({ id });
    return prom;
  }
};

const mutations = {
  SET_FIRMWARES_LIST(state, { listName, listData }) {
    state[listName].count = listData.count;
    state[listName].next = listData.next;
    state[listName].previous = listData.previous;
    state[listName].results = listData.results;
  },

  CLEAR_FIRMWARES_LIST_PAGE(state) {
    state.pageFirmwaresList = {
      count: 0,
      next: null,
      previous: null,
      results: []
    };
  },

  SET_FIRMWARE_INFO(state, { listName, payload }) {
    state[listName] = payload;
  },

  CLEAR_FIRMWARE_INFO(state) {
    state.firmwareInfo = null;
  },

  CHANGE_FIRMWARE_INFO_VALUE(state, { fieldName, value }) {
    state.firmwareInfo[fieldName] = value;
  },

  CHANGE_FIRMWARE_INFO_VALUE_FILE(state, { value }) {
    state.newFile = value;
  },

  CHANGE_ADDING_FIRMWARE_VALUE(state, { fieldName, value }) {
    state.addingFirmware[fieldName] = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

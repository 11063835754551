import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getOrganizationAcquiringCredentialsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organization-acquiring-credentials/",
      query: {
        ...query
      }
    })
  );
}
export async function createOrganizationAcquiringCredentialRequest({ data }) {
  return Axios.post("/organization-acquiring-credentials/", data);
}
export async function getOrganizationAcquiringCredentialByIdRequest({ id }) {
  return Axios.get(`/organization-acquiring-credentials/${id}/`);
}

export async function putOrganizationAcquiringCredentialByIdRequest({
  id,
  data
}) {
  return Axios.put(`/organization-acquiring-credentials/${id}/`, { data });
}
export async function patchOrganizationAcquiringCredentialByIdRequest({
  id,
  data
}) {
  return Axios.patch(`/organization-acquiring-credentials/${id}/`, { data });
}
export async function deleteOrganizationAcquiringCredentialByIdRequest({ id }) {
  return Axios.delete(`/organization-acquiring-credentials/${id}/`);
}

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getContractsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/contracts/",
      query: {
        ...query
      }
    })
  );
}
export async function createContractRequest(formData) {
  return Axios.post("/contracts/", formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}
export async function getContractByIdRequest({ id }) {
  return Axios.get(`/contracts/${id}/`);
}

export async function putContractByIdRequest({ id, data }) {
  return Axios.put(`/contracts/${id}/`, data);
}
export async function patchContractByIdRequest({ id, data }) {
  return Axios.patch(`/contracts/${id}/`, data);
}
export async function deleteContractByIdRequest({ id }) {
  return Axios.delete(`/contracts/${id}/`);
}

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getControllersRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/controllers/",
      query: {
        ...query,
        "optional-fields": [
          "object_service",
          "object_name",
          "organization_diller",
          "object_address",
          "primary_firmware_version",
          "groups",
          "users_count",
          "access_group_count",
          "organization_name",
          "gpio_configs",
          "technician",
          "secondary_firmware_version",
          "object_type"
        ]
      }
    })
  );
}

export async function getControllersClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/controllers/",
      query: {
        ...query
      }
    })
  );
}

export async function downloadControllersListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/controllers/",
      query: {
        ...query,
        "optional-fields": [
          "object_service",
          "object_name",
          "organization_diller",
          "object_address",
          "primary_firmware_version",
          "groups",
          "users_count",
          "access_group_count",
          "organization_name",
          "gpio_configs",
          "technician",
          "secondary_firmware_version",
          "object_type"
        ],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "контроллеры.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function createControllerRequest({ data }) {
  return Axios.post("/controllers/", data);
}
export async function getControllerByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/controllers/${id}/`,
      query: {
        "optional-fields": [
          "object_service",
          "object_name",
          "organization_diller",
          "object_address",
          "primary_firmware_version",
          "groups",
          "users_count",
          "access_group_count",
          "organization_name",
          "gpio_configs",
          "secondary_firmware_version",
          "object_type"
        ]
      }
    })
  );
}

export async function getControllerHashByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/controllers/${id}/hash-ttl/`,
      query: {
        "optional-fields": [
          "object_name",
          "object_address",
          "object_service",
          "organization_diller",
          "gpio_configs",
          "primary_firmware_version",
          "secondary_firmware_version",
          "organization_name",
          "access_group_count",
          "users_count",
          "last_service",
          "technician",
          "object_type"
        ]
      }
    })
  );
}

export async function getControllerRequestReport({ data }) {
  return Axios.post("/controllers/report/", data);
}
export async function putControllerByIdRequest({ id, data }) {
  return Axios.put(`/controllers/${id}/`, data);
}
export async function patchControllerByIdRequest({ id, data }) {
  return Axios.patch(`/controllers/${id}/`, data);
}
export async function deleteControllerByIdRequest({ id }) {
  return Axios.delete(`/controllers/${id}/`);
}
export async function deletePermanentControllerByIdRequest({ id }) {
  return Axios.delete(`/controllers/${id}/force_delete/`);
}

export async function setBleConfigRequest({ id, data }) {
  return Axios.post(`/controllers/${id}/set_ble_config/`, data);
}
export async function setExternalInterfacesRequest({ id, data }) {
  return Axios.post(`/controllers/${id}/set_external_interfaces/`, data);
}
export async function setGpioConfigRequest({ id, data }) {
  return Axios.post(`/controllers/${id}/set_gpio_config/`, data);
}
export async function setLockConfigRequest({ id, data }) {
  return Axios.post(`/controllers/${id}/set_lock_config/`, data);
}
export async function setNetworkConfigRequest({ id, data }) {
  return Axios.post(`/controllers/${id}/set_network_config/`, data);
}
export async function setTimeRequest({ id, data }) {
  return Axios.post(`/controllers/${id}/set_time/`, data);
}
export async function uploadCardAccessesRequest({ id, data }) {
  return Axios.post(`/controllers/${id}/upload_card_accesses/`, data);
}
export async function uploadUserAccessesRequest({ id, data }) {
  return Axios.post(`/controllers/${id}/upload_user_accesses/`, data);
}
export async function getOrganizationControllersRequest({ org_id, query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/organizations/${org_id}/controllers/`,
      query: {
        ...query,
        "optional-fields": [
          "primary_firmware_version",
          "object_address",
          "users_count"
        ]
      }
    })
  );
}
export async function createOrganizationControllerRequest({ org_id, data }) {
  return Axios.post(`/organizations/${org_id}/controllers/`, data);
}
export async function getOrganizationControllerByIdRequest({ org_id, id }) {
  return Axios.get(`/organizations/${org_id}/controllers/${id}/`);
}

export async function putOrganizationControllerByIdRequest({
  org_id,
  id,
  data
}) {
  return Axios.put(`/organizations/${org_id}/controllers/${id}/`, data);
}
export async function patchOrganizationControllerByIdRequest({
  org_id,
  id,
  data
}) {
  return Axios.patch(`/organizations/${org_id}/controllers/${id}/`, data);
}
export async function deleteOrganizationControllerByIdRequest({ org_id, id }) {
  return Axios.delete(`/organizations/${org_id}/controllers/${id}/`);
}
export async function restoreControllerByIdRequest({ id, data }) {
  return Axios.post(`/controllers/${id}/restore_deleted/`, data);
}

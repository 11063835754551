import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getAccessGroupsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/groups/access-groups/",
      query: {
        ...query,
        "optional-fields": [
          "appprices_details",
          "cardprices_details",
          "diller",
          "controllers_details",
          "object_details",
          "access_time",
          "user_count",
          "organizations_details",
          "city"
        ]
      }
    })
  );
}

export async function getBasicAccessGroupsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/groups/access-groups/",
      query
    })
  );
}

export async function getAccessGroupsClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/groups/access-groups/",
      query: {
        ...query,
        "optional-fields": [
          "appprices_details",
          "cardprices_details",
          "diller",
          "controllers_details",
          "object_details",
          "access_time",
          "user_count",
          "organizations_details",
          "city"
        ]
      }
    })
  );
}
export async function downloadGroupUsersListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/groups/access-groups/",
      query: {
        ...query,
        "optional-fields": [
          "appprices_details",
          "cardprices_details",
          "diller",
          "controllers_details",
          "object_details",
          "access_time",
          "user_count",
          "organizations_details",
          "city"
        ],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "группы пользователей.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function createAccessGroupRequest({ data }) {
  return Axios.post("/groups/access-groups/", data);
}
export async function getAccessGroupByIdRequest({ id, query = {} }) {
  // return Axios.get();
  return Axios.get(
    getQueriedUrl({
      url: `/groups/access-groups/${id}/`,
      query: {
        ...query,
        "optional-fields": [
          "appprices_details",
          "cardprices_details",
          "diller",
          "controllers_details",
          "object_details",
          "access_time",
          "user_count",
          "organizations_details",
          "city"
        ],
      }
    })
  );
}

export async function putAccessGroupByIdRequest({ id, data }) {
  return Axios.put(`/groups/access-groups/${id}/`, data);
}
export async function patchAccessGroupByIdRequest({ id, data }) {
  return Axios.patch(`/groups/access-groups/${id}/`, data);
}
export async function deleteAccessGroupByIdRequest({ id }) {
  return Axios.delete(`/groups/access-groups/${id}/`);
}

export async function getAccessTimesRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/access-times/",
      query: {
        ...query
      }
    })
  );
}
export async function createAccessTimeRequest({ data }) {
  return Axios.post("/access-times/", data);
}
export async function getAccessTimeByIdRequest({ id }) {
  return Axios.get(`/access-times/${id}/`);
}

export async function putAccessTimeByIdRequest({ id, data }) {
  return Axios.put(`/access-times/${id}/`, data);
}
export async function patchAccessTimeByIdRequest({ id, data }) {
  return Axios.patch(`/access-times/${id}/`, data);
}
export async function deleteAccessTimeByIdRequest({ id }) {
  return Axios.delete(`/access-times/${id}/`);
}

import { getQueriedUrl } from "@/helpers/queryStringHelper";
import Axios from "axios";

export async function getVisitLogsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/visit-logs/",
      query: {
        ...query,
        "optional-fields": ["date_start", "date_end", "show_global_avg"]
      }
    })
  );
}
export async function createVisitLogRequest({ data }) {
  return Axios.post("/visit-logs/", data);
}

export async function getVisitLogByIdRequest({ id }) {
  return Axios.get(`/visit-logs/${id}/`);
}

export async function getVisitLogByOrgIdRequest({ orgId, query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/visit-logs/report/${orgId}/`,
      query: {
        ...query,
        show_global_avg: true
      }
    })
  );
}

export async function getVisitLogByOrgIdAndGroupIdRequest({
  orgId,
  groupId,
  query
}) {
  return Axios.get(
    getQueriedUrl({
      url: `/visit-logs/report/${orgId}/${groupId}/`,
      query: {
        ...query,
        show_global_avg: true
      }
    })
  );
}
export async function getVisitLogByOrgIdAndGroupIdAndUserIdRequest({
  orgId,
  groupId,
  userId,
  query
}) {
  return Axios.get(
    getQueriedUrl({
      url: `/visit-logs/report/${orgId}/${groupId}/${userId}`,
      query: {
        ...query,
        show_global_avg: true
      }
    })
  );
}

export async function putVisitLogByIdRequest({ id, data }) {
  return Axios.put(`/visit-logs/${id}/`, data);
}
export async function patchVisitLogByIdRequest({ id, data }) {
  return Axios.patch(`/visit-logs/${id}/`, data);
}
export async function deleteVisitLogByIdRequest({ id }) {
  return Axios.delete(`/visit-logs/${id}/`);
}

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getDeallerOrganizationsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/",
      query: {
        ...query,
        "optional-fields": [
          "users_count",
          "city_details",
          "diller_details",
          "diller_clients_count",
          "diller_controllers_count",
          "diller_users_count",
          "diller_objects_count",
          "diller_payments_sum",
          `diller_payments_sum_last_month`,
          "diller_payments_sum_last_year"
        ]
      }
    })
  );
}
export async function downloadDeallerOrganizationsRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/",
      query: {
        ...query,
        "optional-fields": [
          "users_count",
          "city_details",
          "diller_details",
          "diller_clients_count",
          "diller_controllers_count",
          "diller_objects_count",
          "diller_payments_sum",
          `diller_payments_sum_last_month`,
          "diller_payments_sum_last_year"
        ],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "дилеры.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function getCompanyOrganizationsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/",
      query: {
        ...query,
        "optional-fields": [
          "city_details",
          `objects_count`,
          `controllers_count`,
          `users_count`,
          `groups_count`,
          "service_organizations",
          "service_organizations_count",
          "diller_details",
          "payments_sum_last_month",
          "payments_sum_last_year"
        ]
      }
    })
  );
}
export async function downloadCompanyOrganizationsRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/",
      query: {
        ...query,
        "optional-fields": [
          "city_details",
          `objects_count`,
          `controllers_count`,
          `users_count`,
          `groups_count`,
          "service_organizations",
          "service_organizations_count",
          "diller_details"
        ],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "компании.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}

export async function getServiceOrganizationsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/",
      query: {
        ...query,
        "optional-fields": [
          "city_details",
          "service_organizations_organizations_count",
          "service_organizations_organizations_users_count",
          `service_organizations_technicians_count`,
          `service_organizations_objects_count`,
          `service_organizations_objects_controllers_count`
        ]
      }
    })
  );
}
export async function downloadServiceOrganizationsRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/",
      query: {
        ...query,
        "optional-fields": [
          "city_details",
          `service_organizations_technicians_count`,
          `service_organizations_objects_count`,
          `service_organizations_objects_controllers_count`
        ],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "Сервис организации.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}

export async function getOrganizationsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/",
      query: {
        ...query,
        "optional-fields": [
          `payments_sum_last_month`,
          `payments_sum_last_year`,
          "city_details",

          `objects_count`,
          `controllers_count`,
          `users_count`,
          `groups_count`,
          "service_organizations",

          "diller_details",
          "diller_clients_count",
          "diller_controllers_count",
          "diller_objects_count",
          "diller_payments_sum",
          `diller_payments_sum_last_month`,
          "diller_payments_sum_last_year",

          `service_organizations_count`,
          `service_organizations_technicians_count`,
          `service_organizations_objects_count`,
          `service_organizations_objects_controllers_count`
        ]
      }
    })
  );
}

export async function getDillersRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/",
      query: {
        ...query,
        "optional-fields": [
          `payments_sum_last_month`,
          `payments_sum_last_year`,
          "city_details",

          `objects_count`,
          `controllers_count`,
          `users_count`,
          `groups_count`,
          "service_organizations",

          "diller_details",
          "diller_clients_count",
          "diller_controllers_count",
          "diller_objects_count",
          "diller_payments_sum",
          `diller_payments_sum_last_month`,
          "diller_payments_sum_last_year",

          `service_organizations_count`,
          `service_organizations_technicians_count`,
          `service_organizations_objects_count`,
          `service_organizations_objects_controllers_count`
        ]
      }
    })
  );
}
export async function getOrganizationsClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/",
      query: {
        ...query
      }
    })
  );
}

export async function getAllOrganizationsClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/service/",
      query: {
        ...query
      }
    })
  );
}

export async function downloadCompanyListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/organizations/",
      query: {
        ...query,
        "optional-fields": [
          "users_count",
          "city_details",
          "diller_details",
          "diller_clients_count",
          "diller_controllers_count",
          "diller_objects_count",
          "diller_payments_sum",
          `diller_payments_sum_last_month`,
          "diller_payments_sum_last_year"
        ],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "компании.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}

export async function getOrganizationsFilledRequest(
  { query },
  // eslint-disable-next-line no-unused-vars
  { fillingFields = {} }
) {
  return getOrganizationsRequest({
    query: {
      ...query,
      "optional-fields": [
        `payments_sum_last_month`,
        `payments_sum_last_year`,
        "city_details",

        `objects_count`,
        `controllers_count`,
        `users_count`,
        `groups_count`,
        "service_organizations",

        "diller_details",
        "diller_clients_count",
        "diller_controllers_count",
        "diller_objects_count",
        "diller_payments_sum",
        `diller_payments_sum_last_month`,
        "diller_payments_sum_last_year",

        `service_organizations_count`,
        `service_organizations_technicians_count`,
        `service_organizations_objects_count`,
        `service_organizations_objects_controllers_count`
      ]
    }
  });
}

export async function createOrganizationsRequest({ data }) {
  return Axios.post("/organizations/", data);
}
export async function getOrganizationsClearByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/organizations/${id}/`,
      query: {}
    })
  );
}
export async function getOrganizationsByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/organizations/${id}/`,
      query: {
        "optional-fields": [
          `payments_sum_last_month`,
          `payments_sum_last_year`,
          "city_details",
          "cards_count",
          `objects_count`,
          `controllers_count`,
          `users_count`,
          `groups_count`,
          `group_config`,
          "service_organizations",
          "service_specialists",
          "children_groups_count",
          "personnel_count",
          "diller_details",
          "diller_clients_count",
          "diller_controllers_count",
          "diller_objects_count",
          "diller_payments_sum",
          `diller_payments_sum_last_month`,
          "diller_payments_sum_last_year",
          "diller_kindergartens_count",
          `service_organizations_count`,
          `service_organizations_technicians_count`,
          `service_organizations_objects_count`,
          `service_organizations_objects_controllers_count`
        ]
      }
    })
  );
}

export async function putOrganizationsByIdRequest({ id, data }) {
  return Axios.put(`/organizations/${id}/`, data);
}
export async function patchOrganizationsByIdRequest({ id, data }) {
  return Axios.patch(`/organizations/${id}/`, data);
}
export async function deleteOrganizationsByIdRequest({ id }) {
  return Axios.delete(`/organizations/${id}/`);
}

export async function getAcquiringCredentials() {
  return Axios.get("/organization-acquiring-credentials/");
}

export async function getAcquiringCredentialsByOrgId({ id }) {
  return Axios.get(`/organization-acquiring-credentials/?organization=${id}`);
}

export async function patchAcquiringCredentialsById({ id, data }) {
  return Axios.patch(`/organization-acquiring-credentials/${id}/`, data);
}

export async function createAcquiringCredentials({ data }) {
  return Axios.post(`/organization-acquiring-credentials/`, data);
}

export async function postMarkVbDillerId({ id, data }) {
  return Axios.post(`/organizations/${id}/mark-as-vb-diller/`, data);
}

export async function getTypedOrganizationRequest({ type, query }) {
  switch (type) {
    case "company": {
      return getCompanyOrganizationsRequest({ query });
    }
    case "service": {
      return getServiceOrganizationsRequest({ query });
    }
    case "dealer": {
      return getDeallerOrganizationsRequest({ query });
    }
    case "default": {
      return getOrganizationsRequest({ query });
    }
    default: {
      return getOrganizationsRequest({ query });
    }
  }
}
export async function downloadTypedOrganizationRequest({ type, query }) {
  switch (type) {
    case "company": {
      return downloadCompanyOrganizationsRequest({ query });
    }
    case "service": {
      return downloadServiceOrganizationsRequest({ query });
    }
    case "dealer": {
      return downloadDeallerOrganizationsRequest({ query });
    }
    case "default": {
      return downloadCompanyListRequest({ query });
    }
    default: {
      return downloadCompanyListRequest({ query });
    }
  }
}
export async function restoreOrganizationByIdRequest({ id, data }) {
  return Axios.post(`/organizations/${id}/restore_deleted/`, data);
}

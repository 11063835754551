import {
  getVisitLogByOrgIdAndGroupIdAndUserIdRequest,
  getVisitLogByOrgIdAndGroupIdRequest,
  getVisitLogByOrgIdRequest
} from "@/helpers/api/visits";
import moment from "moment";

const state = {
  selectedVisitLogsParams: {
    selectedGroupId: null,
    selectedUserId: null,
    startDate: moment()
      .startOf("month")
      .unix(),
    endDate: moment()
      .endOf("month")
      .unix()
  },
  orgsVisitLogsList: {
    id: null,
    title: "",
    visitlogs: [],
    global_avg: { average_in: null, average_out: null, average_inside: null }
  },
  orgsGroupVisitLogsList: {
    id: null,
    title: "",
    visitlogs: [],
    global_avg: { average_in: null, average_out: null, average_inside: null }
  },
  orgsGroupsUserVisitLogsList: {
    id: null,
    title: "",
    visitlogs: [],
    global_avg: { average_in: null, average_out: null, average_inside: null }
  }
};
const getters = {
  getOrgsVisitLogsList: state => state.orgsVisitLogsList,
  getSelectedVisitLogsIds: state => state.selectedVisitLogsParams,
  getCurrentVisits: state => {
    if (state.selectedVisitLogsParams.selectedUserId) {
      return state.orgsGroupsUserVisitLogsList;
    }
    if (state.selectedVisitLogsParams.selectedGroupId) {
      return state.orgsGroupVisitLogsList;
    } else {
      return state.orgsVisitLogsList;
    }
  }
};

const actions = {
  async fetchOrgVisitsLogs(context) {
    const form = context.getters.getSelectedVisitLogsIds;
    return new Promise((resolve, reject) => {
      getVisitLogByOrgIdRequest({
        orgId: context.getters.getCurrentOrganization,
        query: {
          date_end: form.endDate,
          date_start: form.startDate
        }
      })
        .then(res => {
          const result = {
            id: res.data.organization_id,
            title: res.data.organization_name,
            visitlogs: res.data.visitlogs_by_object
              .reduce((resArr, obj) => {
                resArr = [...resArr, ...obj.visitlogs_by_group];
                return resArr;
              }, [])
              .map(el => {
                return {
                  id: el.group_id,
                  name: el.group_title,
                  average_in: el.avg_by_group.average_in,
                  average_out: el.avg_by_group.average_out,
                  average_inside: el.avg_by_group.average_inside
                };
              }),
            global_avg: res.data.global_avg
          };
          context.commit("setOrgsVisitsLogs", {
            stateName: "orgsVisitLogsList",
            value: result
          });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async fetchOrgGroupsVisitsLogs(context) {
    const form = context.getters.getSelectedVisitLogsIds;
    return new Promise((resolve, reject) => {
      getVisitLogByOrgIdAndGroupIdRequest({
        orgId: context.getters.getCurrentOrganization,
        groupId: form.selectedGroupId,
        query: {
          date_end: form.endDate,
          date_start: form.startDate
        }
      })
        .then(res => {
          const result = {
            id: res.data.group_id,
            title: res.data.group_title,
            visitlogs: res.data.visitlogs_by_user?.map(el => {
              return {
                id: el.user_id,
                name: el.user_full_name,
                average_in: el.avg_by_user.average_in,
                average_out: el.avg_by_user.average_out,
                average_inside: el.avg_by_user.average_inside
              };
            }),
            global_avg: res.data.global_avg
          };
          context.commit("setOrgsVisitsLogs", {
            stateName: "orgsGroupVisitLogsList",
            value: result
          });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async fetchOrgGroupUserVisitsLogs(context) {
    const form = context.getters.getSelectedVisitLogsIds;
    return new Promise((resolve, reject) => {
      getVisitLogByOrgIdAndGroupIdAndUserIdRequest({
        orgId: context.getters.getCurrentOrganization,
        groupId: form.selectedGroupId,
        userId: form.selectedUserId,
        query: {
          date_end: form.endDate,
          date_start: form.startDate
        }
      })
        .then(res => {
          const result = {
            id: res.data.user_full_name,
            title: res.data.user_full_name,
            visitlogs: res.data.visitlogs_by_day?.map(el => {
              return {
                day: el.day,
                average_in: el.avg_by_day.average_in,
                average_out: el.avg_by_day.average_out,
                average_inside: el.avg_by_day.average_inside,
                inside: el.inside,
                outside: el.outside
              };
            }),
            global_avg: res.data.global_avg
          };
          context.commit("setOrgsVisitsLogs", {
            stateName: "orgsGroupsUserVisitLogsList",
            value: result
          });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async visitLogSearch(context) {
    const form = context.getters.getSelectedVisitLogsIds;
    if (form.selectedGroupId && form.selectedUserId) {
      context.dispatch("fetchOrgGroupUserVisitsLogs");
    } else if (form.selectedGroupId) {
      context.dispatch("fetchOrgGroupsVisitsLogs");
    } else {
      context.dispatch("fetchOrgVisitsLogs");
    }
  }
};

const mutations = {
  setOrgsVisitsLogs(state, { stateName, value }) {
    state[stateName] = value;
  },
  setSelectedVisitLogsParams(state, { fieldName, value }) {
    state.selectedVisitLogsParams[fieldName] = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

import {
  getOrganizationUserRequest,
  getUserByIdRequest,
  getUsersRequest,
  postDeleteUserFromGroupRequest
} from "@/helpers/api/user";
import { csvExport } from "@/helpers/CsvHelper";
import {
  getOrganizationStaffByIdRequest,
  getOrganizationStaffsRequest
} from "@/helpers/api/serviceStaff";
import { getRoleGroupsRequest } from "@/helpers/api/role-groups";

const state = {
  serviceOrgStaffPageList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  ServiceOrganizationStaffList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  staffInfo: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
};
const getters = {
  getOrganizationStaffStaff(state) {
    return state.ServiceOrganizationStaffList;
  },
  getStaffInfo(state) {
    return state.staffInfo;
  },
  getServiceOrgStaffPageList: state => state.serviceOrgStaffPageList
};
const actions = {
  async fetchServiceOrgStaffPageList(context, { organization_pk }) {
    const { query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getOrganizationStaffsRequest({
        query: { ...query, ordering },
        organization_pk
      })
        .then(response => {
          context.commit("setServiceOrganizationStaffList", {
            listName: "serviceOrgStaffPageList",
            staff: response.data
          });
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async downloadServiceOrgStaffPageList(context, { organization_pk, header }) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getOrganizationStaffsRequest({
        query: { ...query, limit: 5000, offset: null },
        organization_pk
      })
        .then(response => {
          csvExport(response.data, header, "Техники");
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async downloadOrganizationStaffList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getOrganizationStaffsRequest({
        query: { ...query, limit: 5000, offset: null },
        organization_pk: id
      })
        .then(response => {
          csvExport(response.data, header, "Техники");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchOrganizationStaffList(context) {
    const id = context.getters.getCurrentOrganization;
    const { query } = context.getters.getPageQuery;
    const userList = (
      await getUsersRequest({
        query: {
          ...query,
          "organization-personal": id
        }
      })
    ).data;
    const userOrganization = (await getOrganizationUserRequest({ query, id }))
      .data;

    const usersPersonnel = userOrganization.results.filter(el =>
      userList.results.some(perEl => perEl.id === el.id)
    );
    //получили массив объектов из id access_groups
    const accessGroups = userList.results.map(function(elem) {
      return {
        access_groups: elem.access_groups,
        id: elem.id
      };
    });
    //соединили accessGroups и usersPersonnel по ключу id
    const result = this._vm.$leftJoin(usersPersonnel, accessGroups, "id", "id");

    context.commit("setServiceOrganizationStaffList", {
      listName: "ServiceOrganizationStaffList",
      staff: { count: usersPersonnel.length, results: result }
    });
  },
  async fetchServiceStaffById(context, { organization_pk }) {
    const { id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getOrganizationStaffByIdRequest({ organization_pk, id })
        .then(response => {
          context.commit("setServiceOrganizationStaffList", {
            listName: "staffInfo",
            staff: { results: response.data }
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchOrganizationStaffById(context) {
    const { query, id } = context.getters.getPageQuery;

    return new Promise((resolve, reject) => {
      getUserByIdRequest({ query, id })
        .then(response => {
          context.commit("setServiceOrganizationStaffList", {
            listName: "staffInfo",
            staff: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async deleteStaffFromGroup(context, { id, organizationId, role }) {
    const roleGroups = (
      await getRoleGroupsRequest({
        query: {
          organization: organizationId
        }
      })
    ).data.results;

    //нашли id группы персонала
    const groupIdByRole = roleGroups.find(el => el.role == role).id;

    await postDeleteUserFromGroupRequest({
      data: {
        group: groupIdByRole
      },
      id: id
    });

    return;
  }
};
const mutations = {
  setServiceOrganizationStaffList(state, { listName, staff }) {
    state[listName] = staff;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

const rolesDictionary = {
  5: "Гость",
  10: "Пользователь",
  20: "Менеджер организации",
  21: "Воспитатель",
  30: "Администратор Компании",
  31: "Администратор Детского Сада",
  40: "Техник",
  50: "Администратор Обслуживающей Организации",
  60: "Администратор Дилера",
  70: "Администратор OPPEN",
  80: "Другой персонал"
};

export default rolesDictionary;

import { getOrganizationsByIdRequest } from "@/helpers/api/organizations";
import tabbarLinksDictionary from "@/helpers/tabbars";

const state = {
  tabbarLinks: [],
  tabbar: true,
  isChecked: false
};

const getters = {
  getTabbarLinks: state => state.tabbarLinks,
  getTabbar: state => state.tabbar,
  getTabbarChecked: state => state.isChecked
};

const actions = {
  async toggleTabbar(context) {
    context.commit("setTabbar", { tabbar: !context.state.tabbar });
  },

  async fetchTabbarLinks(context) {
    const currentRole = context.getters.getCurrentRole;
    let tabBarList;
    if (context.getters.getTabbarChecked) {
      tabBarList = context.getters.getTabbarLinks.filter(el =>
        el.userRole.includes(currentRole)
      );
    } else {
      tabBarList = tabbarLinksDictionary.filter(el =>
        el.userRole.includes(currentRole)
      );
    }
    return new Promise(resolve => {
      if (
        (currentRole === 30 || currentRole === 31) &&
        context.getters.getTabbarChecked == false
      ) {
        getOrganizationsByIdRequest({
          id: context.getters.getCurrentOrganization
        })
          .then(res => {
            const isAllow = res.data.allow_visitlog_control;
            if (!isAllow) {
              const newTabBarList = tabBarList.filter(
                el => el.text != "Контроль персонала"
              );
              context.commit("SET_TABBAR_LINKS", newTabBarList);
            } else {
              context.commit("SET_TABBAR_LINKS", tabBarList);
            }
          })
          .then(() => {
            resolve();
          });
      } else {
        context.commit("SET_TABBAR_LINKS", tabBarList);
        resolve();
      }
    });
  }
};

const mutations = {
  setTabbar(state, { tabbar }) {
    state.tabbar = tabbar;
  },

  SET_TABBAR_LINKS(state, payload) {
    state.tabbarLinks = payload;
  },

  SET_TABBAR_CHECKED(state, payload) {
    state.isChecked = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getOrganizationStaffsRequest({ query, organization_pk }) {
  return Axios.get(
    getQueriedUrl({
      url: `/organizations/${organization_pk}/personnel/`,
      query: {
        ...query
      }
    })
  );
}
export async function getOrganizationStaffByIdRequest({ organization_pk, id }) {
  return Axios.get(`/organizations/${organization_pk}/personnel/${id}/`);
}

export async function putOrganizationStaffByRequest({
  organization_pk,
  id,
  data
}) {
  return Axios.put(`/organizations/${organization_pk}/personnel/${id}/`, data);
}
export async function patchOrganizationStaffByRequest({
  organization_pk,
  id,
  data
}) {
  return Axios.patch(
    `/organizations/${organization_pk}/personnel/${id}/`,
    data
  );
}
export async function deleteOrganizationStaffByRequest({
  organization_pk,
  id
}) {
  return Axios.delete(`/organizations/${organization_pk}/personnel/${id}/`);
}

const tabbarLinks = [
  {
    to: { name: "Dealer" },
    src: "/img/images/tabbar/tabbar__icon_1.svg",
    text: "Дилеры",
    userRole: [70]
  },
  {
    to: { name: "infoPanel" },
    src: "/img/images/tabbar/statistics.svg",
    text: "Инфопанель",
    userRole: [60]
  },

  {
    to: { name: "Company" },
    src: "/img/images/tabbar/tabbar__icon_2.svg",
    text: "Компании",
    userRole: [70, 60]
  },
  {
    to: { name: "ServiceTask" },
    src: "/img/images/tabbar/tabbar__icon_13.svg",
    text: "Заявки",
    userRole: [50, 40]
  },
  {
    to: { name: "Objects" },
    src: "/img/images/tabbar/tabbar__icon_3.svg",
    text: "Объекты",
    userRole: [70, 60, 50, 40, 30]
  },
  {
    to: { name: "ServiceGroups" },
    src: "/img/images/tabbar/tabbar__icon_service-group.svg",
    text: "Группы объектов",
    userRole: [50]
  },
  {
    to: { name: "Kindergartens" },
    src: "/img/images/tabbar/tabbar__icon_4.svg",
    text: "Детские сады",
    userRole: [70, 60]
  },
  {
    to: { name: "Controllers" },
    src: "/img/images/tabbar/tabbar__icon_5.svg",
    text: "Контроллеры",
    userRole: [70, 60, 50, 40, 30]
  },
  {
    to: { name: "Firmwares" },
    src: "/img/images/tabbar/layers.svg",
    text: "Прошивки",
    userRole: [70]
  },
  {
    to: { name: "Cards" },
    src: "/img/images/tabbar/tabbar__icon_6.svg",
    text: "Карты",
    userRole: [70]
  },
  {
    to: { name: "Accounts" },
    src: "/img/images/tabbar/tabbar__icon_7.svg",
    text: "Лицевые счета",
    userRole: [70]
  },
  {
    to: { name: "GeneralInformation" },
    src: "/img/images/tabbar/info.svg",
    text: "Общая информация",
    userRole: [31, 21]
  },
  {
    to: { name: "ChildrenGroups" },
    src: "/img/images/tabbar/children_groups.svg",
    text: "Детские группы",
    userRole: [31, 21]
  },
  {
    to: { name: "Children" },
    src: "/img/images/tabbar/Group_2.svg",
    text: "Воспитанники",
    userRole: [31]
  },
  {
    to: { name: "ChildrenVisitLogPage" },
    src: "/img/images/tabbar/tabbar__icon_19.svg",
    text: "Табель посещаемости",
    userRole: [31, 21]
  },

  {
    to: { name: "Cards" },
    src: "/img/images/tabbar/tabbar__icon_6.svg",
    text: "Карты",
    userRole: [31]
  },
  {
    to: { name: "Users" },
    src: "/img/images/tabbar/tabbar__icon_9.svg",
    text: "Пользователи",
    userRole: [70, 60, 30]
  },
  {
    to: { name: "UsersKindergarten" },
    src: "/img/images/tabbar/tabbar__icon_9.svg",
    text: "Пользователи",
    userRole: [31]
  },
  {
    to: { name: "Staff" },
    src: "/img/images/tabbar/staff.svg",
    text: "Персонал",
    userRole: [50]
  },
  {
    to: { name: "Payments" },
    src: "/img/images/tabbar/tabbar__icon_10.svg",
    text: "Платежи",
    userRole: [70, 60, 30]
  },
  {
    to: { name: "Promocode" },
    src: "/img/images/tabbar/promocodes.svg",
    text: "Промокоды",
    userRole: [70]
  },
  {
    to: { name: "Notification" },
    src: "/img/images/tabbar/tabbar__icon_11.svg",
    text: "Уведомления",
    userRole: [70, 60, 30, 31, 21]
  },
  {
    to: { name: "Codes" },
    src: "/img/images/tabbar/codes.svg",
    text: "Коды аутентификации",
    userRole: [70]
  },

  {
    to: { name: "Access" },
    src: "/img/images/tabbar/access.svg",
    text: "Реестр доступов",
    userRole: [70]
  },
  // {
  //   to: { name: "Split" },
  //   src: "/img/images/tabbar/tabbar__icon_terminal.svg",
  //   text: "Тариф сплитования",
  //   userRole: [70]
  // },

  {
    to: { name: "ServesOrganization" },
    src: "/img/images/tabbar/tabbar__icon_12.svg",
    text: "Обслуживающие организации",
    userRole: [70]
  },
  {
    to: { name: "AboutCompany" },
    src: "/img/images/tabbar/tabbar__icon_2.svg",
    text: "О компании",
    userRole: [30]
  },
  {
    to: { name: "AboutDiller" },
    src: "/img/images/tabbar/tabbar__icon_1.svg",
    text: "О дилере",
    userRole: [60]
  },

  {
    to: { name: "PassageLog" },
    src: "/img/images/tabbar/tabbar__icon_13.svg",
    text: "Журнал проходов",
    userRole: [31]
  },
  {
    to: { name: "Personnel" },
    src: "/img/images/tabbar/staff.svg",
    text: "Персонал",
    userRole: [31]
  },
  {
    to: { name: "AdminsOppen" },
    src: "/img/images/admin-with-cogwheels.svg",
    text: "Администраторы",
    userRole: [70]
  },

  {
    to: { name: "MyObjects" },
    src: "/img/images/tabbar/tabbar__icon_14.svg",
    text: "Мои объекты",
    userRole: [10, 21, 31, 80]
  },
  {
    to: { name: "Profile" },
    src: "/img/images/tabbar/tabbar__icon_9.svg",
    text: "Профиль",
    userRole: [5, 10, 20, 21, 30, 31, 40, 50, 60, 70, 80]
  },
  {
    to: { name: "MyPayments" },
    src: "/img/images/tabbar/tabbar__icon_10.svg",
    text: "Платежи",
    userRole: [10, 20]
  },
  {
    to: { name: "Widgets" },
    src: "/img/images/tabbar/statistics.svg",
    text: "Статистика",
    userRole: [70]
  },
  {
    to: { name: "Review" },
    src: "/img/images/tabbar/tabbar__icon_19.svg",
    text: "Отзывы",
    userRole: [70]
  },
  {
    to: { name: "Instruction" },
    src: "/img/images/tabbar/statistics.svg",
    text: "Инструкция",
    userRole: [31]
  }
];

export default tabbarLinks;

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getGroupsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/groups/",
      query: {
        ...query,
        "optional-fields": [
          "controllers_details",
          "appprices_details",
          "cardprices_details"
        ]
      }
    })
  );
}
export async function createGroupRequest({ data }) {
  return Axios.post("/groups/", data);
}
export async function getGroupByIdRequest({ id }) {
  return Axios.get(getQueriedUrl({ url: `/groups/${id}/` }));
}

export async function putGroupByIdRequest({ id, data }) {
  return Axios.put(`/groups/${id}/`, data);
}
export async function patchGroupByIdRequest({ id, data }) {
  return Axios.patch(`/groups/${id}/`, data);
}
export async function deleteGroupByIdRequest({ id }) {
  return Axios.delete(`/groups/${id}/`);
}

export async function deleteArrUsersRequest({ group_id, data }) {
  return Axios({
    method: "delete",
    url: `/groups/${group_id}/users/remove/`,
    data
  });
}

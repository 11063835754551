const myMixin = {
  data() {
    return {
      isDateLoaded: false
    };
  },
  computed: {
    pageQuery() {
      return this.$store.getters.getPageQuery;
    },
    routerParams() {
      return this.$route.params;
    },
    allParams() {
      let retParams = {};
      Object.keys(this.routerParams).forEach(key => {
        if (key === "dealerId") {
          retParams.diller = this.routerParams[key];
          retParams.object__organization__diller = this.routerParams[key];
          retParams.organization__diller = this.routerParams[key];
        }
        if (key === "companyId") {
          retParams.object__organization = this.routerParams[key];
          retParams.organization = this.routerParams[key];
        }
        if (key === "objectId") {
          retParams.object = this.routerParams[key];
          retParams.group__object = this.routerParams[key];
        }
        if (key === "serviceOrganizationId") {
          retParams.service_organization = this.routerParams[key];
        }
        if (key === "kindergartenId") {
          retParams.children_group__object__organization = this.routerParams[
            key
          ];
        }
      });
      return retParams;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("setSearchQuery", {
      query: {}
    });
    next();
  }
};
export default myMixin;

export function csvExport(responseData, header, fileName = "export") {
  let tableData = responseData.results;
  let data = [];

  let headRow = header.map(head => {
    return head.text;
  });
  data.push(headRow.join(";"));

  tableData.map((item, ind) => {
    let row = [];
    header.map(head => {
      let temp = "";
      if (head.renderFunction) {
        temp = head.renderFunction(item);
      } else if (head.text === "№") {
        temp = ind + 1;
      } else {
        temp = item[head.value];
      }

      if (!temp) temp = "-";
      temp = String(temp);
      temp = temp.replace(/<\/?[^>]+(>|$)/g, "").replace(/(\r\n|\n|\r)/gm, " ");

      row.push('"' + temp.replace(/"/g, "'") + '"');
    });
    data.push(row.join(";"));
  });

  let csvContent = "data:text/csv;charset=utf-8,";
  const universalBOM = "\uFEFF";
  csvContent += universalBOM;
  csvContent += data.join("\n").replace(/(^\[)|(\]$)/gm, "");

  const dataCsv = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", dataCsv);
  link.setAttribute("download", `${fileName}.csv`);
  link.click();
}


export function csvNoResultExport(responseData, header, fileName = "export") {
  let tableData = responseData;
  let data = [];

  let headRow = header.map(head => {
    return head.text;
  });
  data.push(headRow.join(";"));

  tableData.map((item, ind) => {
    let row = [];
    header.map(head => {
      let temp = "";
      if (head.renderFunction) {
        temp = head.renderFunction(item);
      } else if (head.text === "№") {
        temp = ind + 1;
      } else {
        temp = item[head.value];
      }

      if (!temp) temp = "-";
      temp = String(temp);
      temp = temp.replace(/<\/?[^>]+(>|$)/g, "").replace(/(\r\n|\n|\r)/gm, " ");

      row.push('"' + temp.replace(/"/g, "'") + '"');
    });
    data.push(row.join(";"));
  });

  let csvContent = "data:text/csv;charset=utf-8,";
  const universalBOM = "\uFEFF";
  csvContent += universalBOM;
  csvContent += data.join("\n").replace(/(^\[)|(\]$)/gm, "");

  const dataCsv = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", dataCsv);
  link.setAttribute("download", `${fileName}.csv`);
  link.click();
}

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";
// import { getAccessGroupByIdRequest } from "@/helpers/api/access-group";

export async function downloadAccountsListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/accounts/",
      query: {
        ...query,
        "optional-fields": [
          "diller_name",
          "object_name",
          "organization_name",
          "users"
        ],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "лицевые счета.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}

export async function getAccountsRequest({ query }) {
  const accounts = await Axios.get(
    getQueriedUrl({
      url: "/accounts/",
      query: {
        ...query,
        "optional-fields": [
          "diller_name",
          "object_name",
          "organization_name",
          "users"
        ]
      }
    })
  );
  return accounts;
}

export async function getAccountsClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/accounts/",
      query: {
        ...query
      }
    })
  );
}

export async function createAccountRequest({ data }) {
  return Axios.post("/accounts/", data);
}
export async function getAccountByIdRequest({ id }) {
  return Axios.get(`/accounts/${id}/`);
}

export async function putAccountByIdRequest({ id, data }) {
  return Axios.put(`/accounts/${id}/`, data);
}
export async function patchAccountByIdRequest({ id, data }) {
  return Axios.patch(`/accounts/${id}/`, data);
}
export async function deleteAccountByIdRequest({ id }) {
  return Axios.delete(`/accounts/${id}/`);
}
export async function deleteArrAccountRequest({ data }) {
  return Axios.post(`/accounts/bulk-delete/`, data);
}

export async function uploadAccountFileRequest({ data }) {
  return Axios.post("/combined-format-account/", data, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}

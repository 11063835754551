import * as accountsRequests from "@/helpers/api/accounts";
import {
  createAccountRequest,
  getAccountsRequest,
  uploadAccountFileRequest,
  deleteAccountByIdRequest,
  deleteArrAccountRequest
} from "@/helpers/api/accounts";
import Vue from "vue";
import router from "@/router";
import { csvExport } from "@/helpers/CsvHelper";
// import {deleteArrCardRequest} from "@/helpers/api/cards";
const state = {
  pageAccountList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  objectOrganizationAccountsList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  createAccountFileForm: {
    file: null,
    object: null,
    group: null
  },
  createAccountManualForm: {
    accountNumber: {
      number: null,
      max_users: null,
      group: null
    },
    accountNumbers: [],
    object: null,
    group: null
  }
};

const getters = {
  getPageAccountsList: state => state.pageAccountList,
  getCreateAccountFileForm: state => state.createAccountFileForm,
  getCreateAccountManualForm: state => state.createAccountManualForm,
  getObjectOrganizationAccountsList: state =>
    state.objectOrganizationAccountsList
};

const actions = {
  async downloadAccountsList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getAccountsRequest({ query: { ...query, limit: 5000, offset: null } })
        .then(response => {
          csvExport(response.data, header, "ЛицевыеСчета");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
    // return downloadAccountsListRequest({ query });
  },
  async fetchPageAccountsList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      accountsRequests
        .getAccountsRequest({
          query: {
            ordering,
            ...query
          }
        })
        .then(response => {
          context.commit("setAccountList", {
            listName: "pageAccountList",
            accounts: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadOrganizationAccountsList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getAccountsRequest({
        query: { ...query, limit: 5000, offset: null, object: id }
      })
        .then(response => {
          csvExport(response.data, header, "ЛицевыеСчета");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchObjectAccountsList(context) {
    const { id, query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getAccountsRequest({ query: { ...query, ordering, object: id } })
        .then(response => {
          context.commit("setObjectOrganizationAccountsList", {
            listName: "objectOrganizationAccountsList",
            accounts: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async clearAccountsPage(context) {
    context.commit("clearAccountList", {
      listName: "pageAccountList"
    });
    return Promise.resolve();
  },
  async uploadAccounts(context) {
    const uploadForm = context.getters.getCreateAccountFileForm;
    const groupIds = [];
    for (let i = 0; i < uploadForm.group.length; i++) {
      groupIds.push(uploadForm.group[i].value);
    }
    const formData = new FormData();
    formData.append("file", uploadForm.file);
    formData.append("object", uploadForm.object);
    formData.append("groups", groupIds);
    const createResult = uploadAccountFileRequest({
      data: formData
    });
    createResult.then(() => {
      router.go(-1);
      context.commit("clearCreateAccountForm");
    });
    return createResult;
  },
  async createAccounts(context) {
    const createForm = context.getters.getCreateAccountManualForm;
    const createResult = Promise.all(
      createForm.accountNumbers.map(accountNumber => {
        const group_list = accountNumber.group_create.map(group => ({
          id: group.value
        }));

        return createAccountRequest({
          data: {
            number: accountNumber.number,
            object: createForm.object,
            groups: group_list,
            max_users: accountNumber.max_users
          }
        });
      })
    );

    createResult.then(() => {
      router.go(-1);
      context.commit("clearCreateAccountForm");
    });
    return createResult;
  },

  async deleteAccount(context, { id }) {
    return deleteAccountByIdRequest({ id });
  },
  async deleteArrAccount(context, { data }) {
    const prom = await deleteArrAccountRequest({ data });
    return prom;
  }
};

const mutations = {
  clearCreateAccountForm(state) {
    state.createAccountManualForm = {
      accountNumber: {
        number: null,
        max_users: null
      },
      accountNumbers: [],
      object: null
    };

    state.createAccountFileForm = {
      file: null,
      object: null
    };
  },
  setObjectOrganizationAccountsList(state, { listName, accounts }) {
    state[listName] = accounts;
  },
  setAccountList(state, { listName, accounts }) {
    state[listName] = accounts;
  },
  clearAccountList(state, { listName }) {
    state[listName].results = [];
  },
  setCreateAccountFileForm(state, { fieldName, value }) {
    console.log("fieldName", fieldName);
    console.log("value", value);
    Vue.set(state.createAccountFileForm, fieldName, value);
  },
  setCreateAccountManualForm(state, { fieldName, value }) {
    console.log("fieldName", fieldName);
    console.log("value", value);
    Vue.set(state.createAccountManualForm, fieldName, value);
  },
  SET_CREATE_ACCOUNT_MANUAL_FORM_ACCOUNT_NUMBER(state, { fieldName, value }) {
    Vue.set(state.createAccountManualForm.accountNumber, fieldName, value);
  },
  deleteAccountNumber(state, { id }) {
    state.createAccountManualForm.accountNumbers.splice(id, 1);
  },
  addAccountNumber(state, { number, max_users, group_create }) {
    state.createAccountManualForm.accountNumbers.splice(0, 0, {
      number,
      max_users,
      group_create
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

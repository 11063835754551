const state = {
  // чекбоксы на удаление
  deleteList: [], // список id элементов для удаления с попощью чекбоксов
  checkedAll: [], // выбраны все чекбоксы

  //постраничная навигация
  dropdownDefault: 100,
  dropdown: [100, 1000]
};

const getters = {
  getDeleteList: state => state.deleteList,
  getCheckedAll: state => state.checkedAll,

  getDropdown: state => state.dropdown,
  getDropdownDefault: state => state.dropdownDefault
};

const actions = {
  async setAllValues(context, allValues) {
    context.commit("UPDATE_DELETE_LIST", allValues);
  },
  clearCheckedList(context) {
    context.commit("CLEAR_CHECKED_ALL");
    context.commit("CLEAR_DELETE_LIST");
  }
};

const mutations = {
  UPDATE_DELETE_LIST(state, value) {
    state.deleteList = value;
  },
  SET_CHECKED_ALL(state, values) {
    state.checkedAll = values;
  },
  CLEAR_CHECKED_ALL(state) {
    state.checkedAll = [];
  },
  CLEAR_DELETE_LIST(state) {
    state.deleteList = [];
  },

  SET_DROPDOWN_DEFAULT(state, value) {
    state.dropdownDefault = value;
  },
  CLEAR_DROPDOWN_DEFAULT(state) {
    state.dropdownDefault = 100;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};

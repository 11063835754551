const phoneFilter = {
  name: "phoneformat",
  execute: value => {
    if (!value || typeof value !== "string") return "-";
    const piece0 = value.substring(0, 2); //+7
    const piece1 = value.substring(2, 5); //927
    const piece2 = value.substring(5, 8); //432
    const piece3 = value.substring(8, 10); //34
    const piece4 = value.substring(10); //34

    return `${piece0} (${piece1}) ${piece2}-${piece3}-${piece4}`;
  }
};

export default phoneFilter;

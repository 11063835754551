export const UserRoles = {
  GUEST: 5,
  ORG_USER: 10,
  ORG_MANAGER: 20,
  KINDERGARTEN_MANAGER: 21,
  ORG_ADMIN: 30,
  KINDERGARTEN_ADMIN: 31,
  SERVICE_SPECIALIST: 40,
  SERVICE_ADMIN: 50,
  DEALER_ADMIN: 60,
  OPEN_ADMIN: 70,
  WORKER: 80,
  PAID_USER: 110,
  UNPAID_USER: 120
};

export function getIdentificatorUserRole() {
  const organizationId = localStorage.getItem("organization");
  const kindergartenId = localStorage.getItem("KindergartenId");
  const adminRole = localStorage.getItem("role");
  const role = parseInt(adminRole, 10);

  if (
    role === UserRoles.KINDERGARTEN_ADMIN ||
    role === UserRoles.KINDERGARTEN_MANAGER
  ) {
    return organizationId;
  } else if (role === UserRoles.DEALER_ADMIN) {
    return kindergartenId;
  }
  return kindergartenId;
}

const userGroupRoutes = [
  {
    name: "UsersCreate",
    path: "/createUser",
    component: () => import("@/views/UsersPages/CreateUserPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "UsersCreateObjects",
    path: "/createUserObjects",
    component: () => import("@/views/UsersPages/CreateUserPageFromObjects.vue"),
    meta: {
      requiresAuth: true
    },
    props: true
  },

  {
    name: "UsersInfo",
    path: "/users/:id",
    component: () => import("@/views/UsersPages/UsersInfoKindergartens.vue"),
    meta: {
      requiresAuth: true
    },
    props: {
      deletePermission: {
        from: ["groups"],
        descriptions: "Пользователь будет удалён из указанной группы",
        btnTitle: "ПОЛЬЗОВАТЕЛЯ ИЗ ГРУППЫ"
      }
    }
  },
  {
    name: "UsersInfoOnObject",
    path: "/usersObject/:id",
    component: () => import("@/views/UsersPages/UsersOnObjectInfosPages.vue"),
    meta: {
      requiresAuth: true
    },
    props: {
      deletePermission: {
        from: ["groups"],
        descriptions: "Пользователь будет удалён из указанной группы",
        btnTitle: "ПОЛЬЗОВАТЕЛЯ ИЗ ГРУППЫ"
      }
    }
  }
];
function fromUserGroupRoutes() {
  return userGroupRoutes.map(el => {
    return {
      ...el,
      name: "FromUserGroup" + el.name,
      path: "/userGroups/:userGroupsId" + el.path
    };
  });
}

export default fromUserGroupRoutes;

import { getCodesRequest, deleteCodeByIdRequest } from "@/helpers/api/codes";

const state = {};

const getters = {};
const actions = {
  fetchPageCodesList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getCodesRequest({
        query: {
          ordering,
          ...query
        }
      })
        .then(response => {
          context.commit("setCardsList", {
            listName: "pageCardsList",
            cards: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async deleteCodes(context, { key }) {
    const prom = deleteCodeByIdRequest({ key: key });
    return prom;
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};

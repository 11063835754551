import {
  getPromocodeRequest,
  createPromocodeRequest,
  CheckPromocodesRequest,
  deleteArrPromocodesRequest
} from "@/helpers/api/promocode";

import moment from "moment";
import { csvExport } from "@/helpers/CsvHelper";

const state = {
  pagePromocodesList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  checkPromocodes: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  errMessages: {
    detail: null
  },
  promocodeCreateForm: {
    discount_percent: null,
    duration_days: null,
    started: null,
    count: null,
    by_city: null,
    by_object_type: null,
    by_access_type: ""
  }
};

const getters = {
  getPagePromocodesList: state => state.pagePromocodesList,
  getCheckPromocodes: state => state.checkPromocodes,
  getPromocodeCreateForm: state => state.promocodeCreateForm
};

const actions = {
  async fetchPagePromocodeList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPromocodeRequest({ query: { ...query, ordering } })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "pagePromocodesList",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadPagePromocodesList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getPromocodeRequest({ query: { ...query, limit: 5000, offset: null } })
        .then(response => {
          csvExport(response.data, header, "Журнал промокодов");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchCheckPromocodes(context, { data }) {
    return new Promise(resolve => {
      CheckPromocodesRequest({
        data: { code: data.promoCode, price: data.card_price || data.app_price }
      })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "checkPromocodes",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          context.commit("setErrMessages", {
            listName: "errMessages",
            value: error.response.data.message
              ? error.response.data.message
              : error.response.data.detail
          });
        });
    });
  },

  async deleteArrPromocodes(context, { data }) {
    const prom = await deleteArrPromocodesRequest({ data });
    return prom;
  },

  async createPromocodes(context) {
    const {
      count,
      by_object_type,
      by_city,
      by_access_type,
      started,
      discount_percent,
      duration_days
    } = context.getters.getPromocodeCreateForm;
    let started_at = moment(started).unix();
    let data = {
      count,
      by_object_type,
      by_city,
      by_access_type,
      started_at,
      discount_percent,
      duration_days
    };

    const createResult = createPromocodeRequest({ data });

    return createResult;
  },

  async clearPromocodePage(context) {
    context.commit("errMessages", {
      listName: "promocodeCreateForm"
    });
    return Promise.resolve();
  },
  async clearCheckPromocode(context) {
    context.commit("clearCheckPromocode", {
      listName: "checkPromocodes"
    });
    return Promise.resolve();
  }
};

const mutations = {
  setPromocodeCreateForm(state, { fieldName, value }) {
    state.promocodeCreateForm[fieldName] = value;
  },
  setErrMessages(state, { value }) {
    state.errMessages.detail = value;
  },
  setPaymentList(state, { listName, payments }) {
    state[listName] = payments;
  },
  clearPromocodePage(state) {
    state.promocodeCreateForm.discount_percent = null;
    state.promocodeCreateForm.duration_days = null;
    state.promocodeCreateForm.started = null;
    state.promocodeCreateForm.count = null;
    state.promocodeCreateForm.by_city = null;
    state.promocodeCreateForm.by_object_type = null;
    state.promocodeCreateForm.by_access_type = "";
    state.errMessages.detail = null;
  },
  clearCheckPromocode(state, { listName }) {
    state[listName].discount_amount = null;
    state[listName].discount_percent = null;
    state[listName].original_amount = null;
    state[listName].result_amount = null;
    state[listName].results = [];
    state.errMessages.detail = null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

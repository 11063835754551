import fromChildrenRoutes from "@/router/childRoutes/ChildrenRoutes";
import fromPersonnelRoutes from "@/router/childRoutes/PersonnelRoutes";

const childrenGroup = [
  {
    name: "ChildrenGroupEdit",
    path: "/childrenGroupEdit/:id",
    component: () =>
      import("@/views/ChildrenGroupsPages/EditChildrenGroupPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "ChildrenInfo",
    path: "/children/:id",
    component: () => import("@/views/ChildrenPages/ChildrenInfosPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  ...fromChildrenRoutes(),
  {
    name: "ManageressCreate",
    path: "/manageressCreate",
    component: () =>
      import("@/views/ChildrenGroupsPages/ManageressCreateFormPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateChildren",
    path: "/createChildren",
    component: () => import("@/views/ChildrenPages/ChildrenCreateFormPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "PersonnelInfo",
    path: "/personnel/:id",
    component: () => import("@/views/PersonnelPages/personnelInfoPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  ...fromPersonnelRoutes()
];
function fromChildrenGroupRoutes() {
  return childrenGroup.map(el => {
    return {
      ...el,
      name: "FromChildrenGroup" + el.name,
      path: "/childrenGroups/:childrenGroupsId" + el.path
    };
  });
}

export default fromChildrenGroupRoutes;

import { getReviewIdRequest, getReviewRequest } from "@/helpers/api/review";

const state = {
  pageReviewList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
};

const getters = {
  getPageReviewList: state => state.pageReviewList
};
const actions = {
  fetchPageReviewList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getReviewRequest({
        query: {
          ordering,
          ...query
        }
      })
        .then(response => {
          context.commit("setReviewList", {
            listName: "pageReviewList",
            review: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPageReviewIdList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    const id = context.getters.getPageQuery.id;
    return new Promise((resolve, reject) => {
      getReviewIdRequest({
        id,
        query: {
          ordering,
          ...query
        }
      })
        .then(response => {
          context.commit("setReviewList", {
            listName: "pageReviewList",
            review: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  setReviewList(state, { listName, review }) {
    state[listName] = review;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

import * as cities from "@/helpers/api/cities";

const state = {
  cities: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
};
const getters = {
  getCities: state => state.cities
};
const actions = {
  searchCities(context, { search = null }) {
    cities.getCitiesRequest({ search }).then(response => {
      context.commit("setCities", { cities: response.data });
    });
  }
};
const mutations = {
  setCities(state, { cities }) {
    state.cities = cities;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

const personnel = [
  {
    name: "ChildrenGroupsCreate",
    path: "/childrenGroupsCreate",
    component: () =>
      import("@/views/ChildrenGroupsPages/ChildrenGroupsCreatePage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "PersonnelCreate",
    path: "/personnelCreate",
    component: () => import("@/views/PersonnelPages/personnelCreatePage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenInfo",
    path: "/children/:id",
    component: () => import("@/views/ChildrenPages/ChildrenInfosPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  }
];
function fromPersonnelRoutes() {
  return personnel.map(el => {
    return {
      ...el,
      name: "FromPersonnelGroup" + el.name,
      path: "/personnel/:personnelId" + el.path
    };
  });
}

export default fromPersonnelRoutes;

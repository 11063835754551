import userGroupRoutes from "@/router/childRoutes/userGroupRoutes";

const controllersRoutes = [
  {
    name: "UserGroupsInfo",
    path: "/userGroups/:id",
    component: () => import("@/views/UserGroupsPages/UserGroupsInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  ...userGroupRoutes()
];
function fromControllers() {
  return controllersRoutes.map(el => {
    return {
      ...el,
      name: "FromControllers" + el.name,
      path: "/controllers/:controllerId" + el.path
    };
  });
}
export default fromControllers;

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getPaymentsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/user-payments/",
      query: {
        ...query,
        // status__in: "completed,reversed,refunded,awa-card",
        // ordering: "-id",
        "optional-fields": [
          `group`,
          `object`,
          `organization`,
          "diller_name",
          `user_details`,
          "date_end",
          "days_left"
        ]
      }
    })
  );
}

export async function getKindergartensPaymentsRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${id}/payments/`,
      query: {
        ...query,
        "optional-fields": [
          `group`,
          `object`,
          `organization`,
          "diller_name",
          `user_details`,
          "date_end",
          "days_left"
        ]
      }
    })
  );
}

export async function getKindergartensPaymentsIdRequest({
  query,
  kinderId,
  user_id
}) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${kinderId}/payments/${user_id}`,
      query: {
        ...query,
        "optional-fields": [
          `group`,
          `object`,
          `organization`,
          "diller_name",
          `user_details`,
          "date_end",
          "days_left"
        ]
      }
    })
  );
}

export async function getPaymentsObjectRequest({ id, query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/objects/${id}/payments/`,
      query: {
        ...query,
        // status__in: "completed,reversed,refunded,awa-card",
        // ordering: "-id",
        "optional-fields": [
          `group`,
          `object`,
          `organization`,
          "diller_name",
          `user_details`,
          "date_end",
          "days_left"
        ]
      }
    })
  );
}

export async function getPaymentsRequestReport({ data }) {
  return Axios.post("/user-payments/report/", data);
}

export async function getPaymentsClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/user-payments/",
      query: {
        // status: "completed,awa-card",
        ...query,
        "optional-fields": ["user_details"]
      }
    })
  );
}

export async function downloadPaymentsListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/user-payments/",
      query: {
        ...query,
        // status: "completed,awa-card",
        format: "xlsx",
        "optional-fields": [
          `group`,
          `object`,
          `organization`,
          "diller_name",
          `user_details`,
          "date_end"
        ]
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "платежи пользователей.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}

export async function createPaymentRequest({ data }) {
  return Axios.post("/user-payments/", data);
}

export async function getPaymentByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/user-payments/${id}/`,
      query: {
        "optional-fields": [
          "user_details",
          "group",
          "object",
          "organization",
          "diller_name",
          "date_end",
          "days_left"
        ]
      }
    })
  );
}

export async function putPaymentByIdRequest({ id, data }) {
  return Axios.put(`/user-payments/${id}/`, data);
}

export async function patchPaymentByIdRequest({ id, data }) {
  return Axios.patch(`/user-payments/${id}/`, data);
}

export async function deletePaymentByIdRequest({ id }) {
  return Axios.delete(`/user-payments/${id}/`);
}
export async function refundSbpPaymentByIdRequest({ id }) {
  return Axios.post(`/user-payments/${id}/refund-sbp/`);
}
export async function refundPaymentByIdRequest({ id }) {
  return Axios.post(`/user-payments/${id}/refund/`);
}

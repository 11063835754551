import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getAccessesRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/accesses/`,
      query: {
        ...query,
        "optional-fields": ["object_details", "user_details", "card_details"]
      }
    })
  );
}

export async function getAccessesRestoreRequest({ userId, objectId }) {
  const response = await Axios.get(
    getQueriedUrl({
      url: `/accesses/restore_access/access_groups/`,
      query: {
        user_id: userId,
        object_id: objectId
      }
    })
  );
  return response;
}

export async function checkUserAccess(userId, objectId) {
  const response = await getAccessesRestoreRequest({ userId, objectId });
  if (response.data.length > 0 && typeof response.data !== "string") {
    return response.data;
  }
}
export async function restoreUserAccessRequest({ data }) {
  return Axios.post("/accesses/restore_access/", data);
}

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getCodesRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/temp-tokens/",
      query: {
        ...query,
        "optional-fields": ["user_detail"]
      }
    })
  );
}
export async function deleteCodeByIdRequest({ key }) {
  return Axios.delete(`/temp-tokens/${key}/`);
}

export async function getCodesClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/temp-tokens/",
      query: {
        ...query
      }
    })
  );
}

import { getQueriedUrl } from "@/helpers/queryStringHelper";
import { getIdentificatorUserRole } from "@/helpers/Constants/roles";
import Axios from "axios";
import fileDownload from "js-file-download";

export async function getChildrenRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/children/",
      query: {
        ...query,
        "optional-fields": ["parents_details"]
      }
    })
  );
}

export async function getkindergartensChildrenRequest({ query, type }) {
  const id = getIdentificatorUserRole();

  const url = getQueriedUrl({
    url: `kindergartens/${id}/children/`,
    query: {
      ...query,
      type: type
    }
  });

  return Axios.get(url);
}

export async function getkindergartensChildrenGroupsRequest({ query }) {
  const id = getIdentificatorUserRole();

  return Axios.get(
    getQueriedUrl({
      url: `kindergartens/${id}/children/`,
      query: {
        ...query
      }
    })
  );
}

// продублировалл ф-цию
export async function getAllChildrenRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/children/",
      limit: 1000,
      offset: 0,
      query: {
        ...query
      }
    })
  );
}

export async function getParentsRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/organizations/${id}/users/`,
      query: {
        ...query
      }
    })
  );
}
export async function downloadChildrenListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/children/",
      query: {
        ...query,
        "optional-fields": ["parents_details"],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "Воспитанники.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function createChildrenRequest({ data }) {
  return Axios.post("/children/", data);
}

export async function createKindergartensChildrenRequest({ data, id }) {
  return Axios.post(`/kindergartens/${id}/children/`, data);
}
export async function getChildrenByRequest({ id, query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/children/${id}/`,
      query: {
        ...query,
        "optional-fields": ["parents_details"]
      }
    })
  );
}

export async function putChildrenByRequest({ id, data }) {
  return Axios.put(`/children/${id}/`, data);
}
export async function patchChildrenByRequest({ id, data }) {
  return Axios.patch(`/children/${id}/`, data);
}
export async function deleteChildrenByRequest({ id }) {
  return Axios.delete(`/children/${id}/`);
}
export async function deleteChildrenParentByIdRequest({
  kindergartens_id,
  children_id,
  data
}) {
  return Axios.post(
    `/kindergartens/${kindergartens_id}/children/${children_id}/remove_parent/`,
    data
  );
}

export async function uploadChildrensFileRequest({ organization_pk, data }) {
  return Axios.post(`/organizations/${organization_pk}/users/populate/`, data, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}

import controllersRoutes from "@/router/childRoutes/controllersRoutes";

const serviceOrganizationRoutes = [
  {
    name: "CreateStaff",
    path: "/createStaff",
    component: () => import("@/views/TechniciansPages/CreateTechnicianPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateObject",
    path: "/createObject",
    component: () => import("@/views/ObjectsPages/CreateObjectPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateServiceOrganizationTask",
    path: "/createServiceOrganizationTask",
    component: () => import("@/views/ServiceTasksPages/CreateServiceTaskPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CompanyInfo",
    path: "/company/:id",
    component: () => import("@/views/CompanyPages/CompanyInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  // ...CompanyRoutes(),
  {
    name: "ObjectsInfo",
    path: "/objects/:id",
    component: () => import("@/views/ObjectsPages/ObjectsInfosPages"),
    meta: {
      requiresAuth: true
    }
  },

  {
    name: "CreateAdmin",
    path: "/createAdmin",
    component: () => import("@/views/AdminsPage/CreateAdminPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "ControllersInfo",
    path: "/controllers/:id",
    component: () => import("@/views/ControllersPages/ControllersInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "StaffInfo",
    path: "/staff/:id",
    component: () => import("@/views/StaffPages/StaffInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ServiceGroups",
    path: "/serviceGroups/:id",
    component: () => import("@/views/ServiceGroupsPages/ServiceGroupInfosPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ServiceGroupCreate",
    path: "/createServiceGroup",
    component: () =>
      import("@/views/ServiceGroupsPages/CreateServiceGroupPage"),
    meta: {
      requiresAuth: true
    }
  },
  ...controllersRoutes()
];
function fromServiceOrganization() {
  return serviceOrganizationRoutes.map(el => {
    return {
      ...el,
      name: "FromServesOrganization" + el.name,
      path: "/serviceOrganization/:serviceOrganizationId" + el.path
    };
  });
}

export default fromServiceOrganization;

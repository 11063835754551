import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getUsersRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/users/",
      query: {
        ...query,
        "optional-fields": [
          "objects_count",
          "dillers_count",
          "payments_total",
          "actual_accesses",
          "payments_total",
          "actual_payments",
          "organizations_count",
          // "user-position-for-org",
          "access_groups",
          "role_groups",
          "controllers_count",
          "cards",
          "app_paid_until",
          "card_paid_until",
          "card_code",
          "push_device_type"
          // ,"payment_summ" TODO response 500 error
        ]
      }
    })
  );
}

export async function getUsersByAccessGroup({ query, group_id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/groups/access-groups/${group_id}/users/`,
      query: {
        ...query,
        "optional-fields": [
          "objects_count",
          "dillers_count",
          "payments_total",
          "actual_accesses",
          "payments_total",
          "actual_payments",
          "organizations_count",
          // "user-position-for-org",
          "access_groups",
          "role_groups",
          "controllers_count",
          "cards",
          "app_paid_until",
          "card_paid_until",
          "card_code"
          // ,"payment_summ" TODO response 500 error
        ]
      }
    })
  );
}

export async function getUsersClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/users/",
      query: {
        ...query
      }
    })
  );
}

export async function getUsersKindergartensClearRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${id}/users/`,
      query: {
        ...query
      }
    })
  );
}

export async function getUsersObjectsClearRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/objects/${id}/users/`,
      query: {
        ...query
      }
    })
  );
}

export async function getAllUsersClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/users/all/",
      query: {
        ...query
      }
    })
  );
}

export async function downloadUsersListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/users/",
      query: {
        ...query,
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "пользователи.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}

export async function getOrganizationSelectUserRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/organizations/${id}/users/`,
      query: {
        ...query,
        "optional-fields": ["role_groups"]
      }
    })
  );
}

export async function getOrganizationUserRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/organizations/${id}/users/`,
      query: {
        ...query,
        "optional-fields": [
          "objects_count",
          "dillers_count",
          "payments_total",
          "actual_accesses",
          "actual_payments",
          "organizations_count",
          "access_groups",
          "controllers_count",
          // "user-position-for-org",
          "role_groups",
          "cards",
          "position",
          "app_paid_until",
          "card_paid_until",
          "total_payments",
          "card_code",
          "card_is_awaited"

          // ,"payment_summ" TODO response 500 error
        ]
      }
    })
  );
}

export async function getKindergartensUserRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${id}/users/`,
      query: {
        ...query,
        "optional-fields": [
          "objects_count",
          "dillers_count",
          "payments_total",
          "actual_accesses",
          "actual_payments",
          "organizations_count",
          "access_groups",
          "controllers_count",
          // "user-position-for-org",
          "role_groups",
          "cards",
          "position",
          "app_paid_until",
          "card_paid_until",
          "total_payments",
          "card_code",
          "card_is_awaited",
          "additional_fields"
          // ,"payment_summ" TODO response 500 error
        ]
      }
    })
  );
}
export async function getObjectUsersRequest({ query, objId }) {
  return Axios.get(
    getQueriedUrl({
      url: `/objects/${objId}/users/`,
      query: {
        ...query,
        "optional-fields": [
          "accounts",
          "role_groups",
          "cards",
          "position",
          "app_paid_until",
          "paid_until",
          "card_paid_until",
          "payments_total",
          "card_code",
          "card_is_awaited",
          "last_payment_date",
          "actual_accesses",
          "push_device_type",
          "additional_fields",
          "access_groups",
          "paid_for_object"
          // ,"payment_summ" TODO response 500 error
        ]
      }
    })
  );
}

export async function getOrganizationUserByIdRequest({ query, userId, orgId }) {
  return Axios.get(
    getQueriedUrl({
      url: `/organizations/${orgId}/users/${userId}`,
      query: {
        ...query,
        "optional-fields": [
          "role_groups",
          "cards",
          "position",
          "app_paid_until",
          "card_paid_until",
          "total_payments",
          "card_code",
          "card_is_awaited",
          "access_groups",

          "actual_accesses",
          "actual_payments",
          "organizations_count"

          // ,"payment_summ" TODO response 500 error
        ]
      }
    })
  );
}
export async function downloadOrganizationUsersListRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/organizations/${id.id}/users/`,
      query: {
        ...query,
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "пользователи.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function deleteProfileRequest({ id, data }) {
  return Axios.post(`/users/${id}/self-delete/`, data);
}
export async function restoreProfileRequest({ id }) {
  return Axios.post(`/users/${id}/self-delete/cancel/`);
}
export async function deleteProfileConfirmRequest({ id, data }) {
  return Axios.post(`/users/${id}/self-delete/confirm/`, data);
}
export async function deleteProfileForceRequest({ id }) {
  return Axios.delete(`/users/${id}/force_delete/`);
}
export async function createUserRequest({ data }) {
  return Axios.post("/users/add_user/", data);
}
export async function getUserByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/users/${id}/`,
      query: {
        "optional-fields": [
          "objects_count",
          "dillers_count",
          "payments_total",
          "actual_accesses",
          "actual_payments",
          "organizations_count",
          "card_is_awaited",
          "controllers_count",
          // "user-position-for-org",
          "access_groups",
          "role_groups",
          "cards"
          // ,"payment_summ" TODO response 500 error
        ]
      }
    })
  );
}

export async function getUserKindergartensByIdRequest({
  kindergardens_id,
  user_id
}) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${kindergardens_id}/users/${user_id}/`,
      query: {
        "optional-fields": [
          "objects_count",
          "dillers_count",
          "payments_total",
          "actual_accesses",
          "actual_payments",
          "organizations_count",
          "card_is_awaited",
          "controllers_count",
          // "user-position-for-org",
          "access_groups",
          "role_groups",
          "cards",
          "additional_fields"

          // ,"payment_summ" TODO response 500 error
        ]
      }
    })
  );
}
export async function getUserObjecByIdRequest({ object_id, user_id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/objects/${object_id}/users/${user_id}`,
      query: {
        "optional-fields": [
          "objects_count",
          "dillers_count",
          "payments_total",
          "actual_accesses",
          "actual_payments",
          "organizations_count",
          "card_is_awaited",
          "controllers_count",
          // "user-position-for-org",
          "access_groups",
          "role_groups",
          "cards",
          "additional_fields"
          // ,"payment_summ" TODO response 500 error
        ]
      }
    })
  );
}

export async function getUserClearByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/users/${id}/`
    })
  );
}
export async function getUserObjectsByIdRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/users/${id}/objects/`,
      query
    })
  );
}

export async function getReports() {
  return Axios.get(
    getQueriedUrl({
      url: `/reports/`
    })
  );
}

export async function getAccesses({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/accesses/`,
      query: {
        user: id,
        access_by: "trial"
      }
    })
  );
}

export async function putUserByIdRequest({ id, data }) {
  return Axios.put(`/users/${id}/`, data);
}
export async function patchUserByIdRequest({ id, data }) {
  return Axios.patch(`/users/${id}/`, data);
}

export async function patchUserOnObjectByIdRequest({
  object_id,
  user_id,
  data
}) {
  return Axios.patch(`/objects/${object_id}/users/${user_id}/`, data);
}

export async function patchUserOnKindergartensByIdRequest({
  kindergartensId,
  user_id,
  data
}) {
  return Axios.patch(
    `/kindergartens/${kindergartensId}/users/${user_id}/`,
    data
  );
}

export async function patchUserPhoneByIdRequest({ id, data }) {
  return Axios.post(`/users/${id}/change_phone_number_by_admin/`, data);
}

export async function deleteUserByIdRequest({ id }) {
  return Axios.delete(`/users/${id}/`);
}

export async function createUserWithGroupRequest({ data }) {
  return Axios.post("/users/add_user/", data);
}
export async function createUserOnObjectWithGroupRequest({ id, data }) {
  return Axios.post(`/objects/${id}/users/`, data);
}
export async function postAddUserToGroupRequest({ id, data }) {
  return Axios.post(`/users/${id}/add_to_group/`, data);
}
export async function postAddUserKindergartensToGroupRequest({ id, data }) {
  return Axios.post(`/kindergartens/${id}/users/`, data);
}

export async function postAddPersonnelKindergtensToGroupRequest({ id, data }) {
  return Axios.post(`/kindergartens/${id}/personnel/`, data);
}

export async function postChangeUserToGroupRequest({
  id,
  kindergartens_pk,
  data
}) {
  return Axios.post(
    `/kindergartens/${kindergartens_pk}/personnel/${id}/change-role/`,
    data
  );
}

export async function postAddUserToFreeTrialRequest({ data }) {
  return Axios.post(`/accesses/redeem_trial/`, data);
}
export async function postDeleteUserFromGroupRequest({ id, data }) {
  return Axios.post(`/users/${id}/exclude_from_group/`, data);
}

export async function deleteArrPersonalRequest({ data }) {
  return Axios.post(
    `/kindergartens/${data.kindergartens_pk}/personnel/exclude/`,
    data
  );
}

export async function getServiceOrganizationStaff({ id }) {
  return Axios.get(`/user/?organization_staff=${id}`);
}
export async function deleteChildrenGroupManageressByIdRequest({ id, data }) {
  return Axios.patch(`/children-groups/${id}/`, data);
}

export async function changeUserPhoneByIdRequest({ id, data }) {
  return Axios.post(`/users/${id}/change_phone_number/`, data);
}
export async function changeUserPhoneConfirm({ id, data }) {
  return Axios.post(`/users/${id}/change_phone_number_confirm/`, data);
}
export async function deleteFromOrganizationByIdRequest({ id, data }) {
  return Axios.post(`/users/${id}/exclude_from_organization/`, data);
}
export async function uploadUserFileRequest({ id, data }) {
  return Axios.post(`/groups/${id}/object-users/`, data, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}
// export async function uploadUserObjectFileRequest({ id, data }) {
//   return Axios.post(`/groups/${id}/object-users/`, data, {
//     headers: { "Content-Type": "multipart/form-data" }
//   });
// }

import controllersRoutes from "@/router/childRoutes/controllersRoutes";
import userGroupRoutes from "@/router/childRoutes/userGroupRoutes";

const objectRoutes = [
  {
    name: "UsersCreateObjects",
    path: "/createUserObjects",
    component: () => import("@/views/UsersPages/CreateUserPageFromObjects.vue"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateUserGroup",
    path: "/createUserGroup",
    component: () => import("@/views/UserGroupsPages/CreateUserGroupPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateController",
    path: "/createController",
    component: () => import("@/views/ControllersPages/ControllerCreatePage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateAccountPage",
    path: "/createAccountPage",
    props: true,
    component: () => import("@/views/AccountsPages/CreateAccountPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ControllersInfo",
    path: "/controllers/:id",
    component: () => import("@/views/ControllersPages/ControllersInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  ...controllersRoutes(),
  {
    name: "UserGroupsInfo",
    path: "/userGroups/:id",
    component: () =>
      import("@/views/UserGroupsPages/UserGroupsObjectInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "cardInfo",
    path: "/card/:id",
    component: () => import("@/views/CardsPages/CardsInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  ...userGroupRoutes(),
  {
    name: "UsersInfo",
    path: "/users/:id",
    component: () => import("@/views/UsersPages/UsersOnObjectInfosPages"),
    meta: {
      requiresAuth: true
    }
  }
];

function fromObject() {
  return objectRoutes.map(el => {
    return {
      ...el,
      name: "FromObject" + el.name,
      path: "/objects/:objectId" + el.path
    };
  });
}

export default fromObject;

import { getQueriedUrl } from "@/helpers/queryStringHelper";
import Axios from "axios";
import fileDownload from "js-file-download";

export async function getServiceTasksRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/service-tasks/",
      query: {
        ...query,
        "optional-fields": [
          `controller_details`,
          `object_details`,
          `specialist_details`
        ]
      }
    })
  );
}

export async function getServiceTasksClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/service-tasks/",
      query: {
        ...query
      }
    })
  );
}

export async function downloadServiceTasksListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/service-tasks/",
      query: {
        ...query,
        format: "xlsx",
        "optional-fields": [
          `controller_details`,
          `object_details`,
          `specialist_details`
        ]
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "заявки.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function createServiceTaskRequest({ data }) {
  return Axios.post("/service-tasks/", data);
}
export async function getServiceTaskByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/service-tasks/${id}/`,
      query: {
        "optional-fields": [
          `controller_details`,
          `object_details`,
          `specialist_details`
        ]
      }
    })
  );
}

export async function putServiceTaskByIdRequest({ id, data }) {
  return Axios.put(`/service-tasks/${id}/`, data);
}
export async function patchServiceTaskByIdRequest({ id, data }) {
  return Axios.patch(`/service-tasks/${id}/`, data);
}
export async function deleteServiceTaskByIdRequest({ id }) {
  return Axios.delete(`/service-tasks/${id}/`);
}

export async function getServiceTaskCommentsRequest({ id }) {
  // return Axios.get(`/service-tasks/${id}/comments/`);
  return Axios.get(
    getQueriedUrl({
      url: `/service-tasks/${id}/comments/`,
      query: {
        "optional-fields": "user_details"
      }
    })
  );
}

export async function createServiceTaskCommentsRequest({ id, data }) {
  return Axios.post(`/service-tasks/${id}/comments/`, data);
}

import router from "@/router";

const state = {
  sortQuery: {
    value: "id",
    type: false // asc(+) || desc (-)
  }
};

const getters = {
  getSortValue: state => state.sortQuery.value,
  getSortType: state => state.sortQuery.type,
  getOrdering: state =>
    `${state.sortQuery.type == true ? "" : "-"}${state.sortQuery.value}`
};

const actions = {
  executeSort(context, payload) {
    if (context.getters.getSortValue == payload) {
      context.commit("SET_TYPE_SORT");
    }
    if (context.getters.getSortValue != payload) {
      context.commit("SET_TRUE_TYPE_SORT");
    }
    context.commit("SET_VALUE_SORT", payload);
    let sortType = context.getters.getSortType;
    const currentQuery = context.getters.getPageQuery.query;
    router.push({
      ...router.currentRoute,
      query: { ...currentQuery, ordering: `${sortType ? "" : "-"}${payload}` }
    });
  },

  clearSortQuery(context) {
    context.commit("CLEAR_SORT_QUERY");
  }
};

const mutations = {
  SET_VALUE_SORT(state, payload) {
    state.sortQuery.value = payload;
  },
  SET_TYPE_SORT(state) {
    state.sortQuery.type = !state.sortQuery.type;
  },
  SET_TRUE_TYPE_SORT(state) {
    state.sortQuery.type = true;
  },
  CLEAR_SORT_QUERY(state) {
    state.sortQuery.value = "id";
    state.sortQuery.type = false;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};

import {
  createChildrenVisitLogRequest,
  downloadChildrenVisitLogListRequest,
  getChildrenVisitLogsRequest,
  patchChildrenVisitLogByIdRequest
} from "@/helpers/api/children-visit-log";
import {
  getChildrenRequest,
  getAllChildrenRequest
} from "@/helpers/api/Children/Children";
import { getKindergartenByIdClearRequest } from "@/helpers/api/kindergartens";
const state = {
  childrenVisitLogList: {
    count: null,
    next: null,
    previous: null,
    results: []
  },
  visitDeti: {
    count: null,
    next: null,
    previous: null,
    results: []
  },
  childrenVisitLog: {
    groups: null,
    date: ""
  },
  editChildrenVisitLog: {
    edited: {}
  }
};
const getters = {
  getChildrenVisitLogList: state => state.childrenVisitLogList,
  getChildrenVisitLog: state => state.childrenVisitLog,
  getEditChildrenVisitLog: state => state.editChildrenVisitLog,
  childTableData: state => {
    let childObj = {};
    state.visitDeti.results.forEach(el => {
      if (!childObj[el.id]) {
        childObj[el.id] = { child: el, visits: [], id: el.id };
      }
    });
    state.childrenVisitLogList.results.forEach(el => {
      if (!childObj[el.child.id]) {
        childObj[el.child.id] = { child: el.child, visits: [], id: el.id };
      }
      childObj[el.child.id].visits.push({
        date: el.date,
        status: el.status,
        id: el.id
      });
    });
    return childObj;
  }
};
const actions = {
  async downloadChildrenVisitLog(context) {
    const { query } = context.getters.getPageQuery;
    const id = context.getters.getChildrenVisitLog.groups;
    return downloadChildrenVisitLogListRequest({
      query: { ...query, child__children_group: id }
    });
  },
  async testFetch(context) {
    const { groups } = context.getters.getChildrenVisitLog;
    if (groups == -1) {
      await context.dispatch("fetchAllChildrensInKindergarden");
    } else {
      let deti = (
        await getChildrenRequest({
          query: { children_group: groups, ordering: "last_name" }
        })
      ).data;
      await context.dispatch("fetchChildrenVisitLogByChildrenGroup");
      context.commit("saveVisitDeti", { value: deti });
    }
  },

  async fetchAllChildrensInKindergarden(context) {
    const kindergarten_org = context.getters.getCurrentOrganization;
    const deti = (
      await getAllChildrenRequest({
        query: {
          ordering: "last_name",
          children_group__group__organization: kindergarten_org,
          limit: 1000,
          offset: 0
        }
      })
    ).data;
    await context.dispatch("fetchChildrenVisitLogByAllChildrenGroup");
    context.commit("saveVisitDeti", { value: deti });
  },

  async fetchChildrenVisitLogByChildrenGroup(context) {
    const { date, groups } = context.getters.getChildrenVisitLog;

    const limit = 1000;
    let offset = 0;
    const allDataResult = [];
    const request = (
      await getChildrenVisitLogsRequest({
        query: { date, child__children_group: groups, limit, offset }
      })
    ).data;
    const pages = Array.apply(null, {
      length: Math.round(request.count / limit)
    }).map((el, index) => index + 1);
    const frstPage = request.results;
    allDataResult.push(frstPage);
    for (const page of pages) {
      offset = limit * page;
      const resultPerPage = (
        await getChildrenVisitLogsRequest({
          query: { date, child__children_group: groups, limit, offset }
        })
      ).data.results;
      allDataResult.push(resultPerPage);
    }
    const data = {
      ...request,
      results: allDataResult.flat(Infinity)
    };

    const respPromise = new Promise(resolve => {
      resolve(data);
    });

    return respPromise.then(() => {
      context.commit("setChildrenVisitLog", {
        fieldName: "childrenVisitLogList",
        value: data
      });
    });
  },

  async fetchChildrenVisitLogByAllChildrenGroup(context) {
    const { date } = context.getters.getChildrenVisitLog;
    const kindergarten = context.getters.getCurrentOrganization;
    const kinderObjectId = (
      await getKindergartenByIdClearRequest({ id: kindergarten })
    ).data.object.id;

    const limit = 1000;
    let offset = 0;
    const allDataResult = [];
    const request = (
      await getChildrenVisitLogsRequest({
        query: { date, kindergarten: kinderObjectId, limit, offset }
      })
    ).data;
    const pages = Array.apply(null, {
      length: Math.round(request.count / limit)
    }).map((el, index) => index + 1);
    const frstPage = request.results;
    allDataResult.push(frstPage);
    for (const page of pages) {
      offset = limit * page;
      const resultPerPage = (
        await getChildrenVisitLogsRequest({
          query: { date, kindergarten: kinderObjectId, limit, offset }
        })
      ).data.results;
      allDataResult.push(resultPerPage);
    }
    const data = {
      ...request,
      results: allDataResult.flat(Infinity)
    };

    const respPromise = new Promise(resolve => {
      resolve(data);
    });

    return respPromise.then(() => {
      context.commit("setChildrenVisitLog", {
        fieldName: "childrenVisitLogList",
        value: data
      });
      context.commit("setNameChildrenGroup", {
        fieldName: "nameGroup",
        value: "Все группы"
      });
    });
  },

  async editChildrenVisitLog(context) {
    const editedChilds = context.getters.getEditChildrenVisitLog.edited;
    const kinderGartenOrgId = context.getters.getCurrentOrganization;
    const kinderObjectId = (
      await getKindergartenByIdClearRequest({ id: kinderGartenOrgId })
    ).data.object.id;

    const response = Object.keys(editedChilds).map(childId => {
      return editedChilds[childId].map(async function(visit) {
        if (!visit.id) {
          return await new Promise(resolve =>
            resolve(
              createChildrenVisitLogRequest({
                data: {
                  status: visit.status,
                  date: visit.date.format("YYYY-MM-DD"),
                  kindergarten: kinderObjectId,
                  child: childId
                }
              })
            )
          );
        } else {
          return await new Promise(resolve =>
            resolve(
              patchChildrenVisitLogByIdRequest({
                id: visit.id,
                data: {
                  status: visit.status,
                  date: visit.date.format("YYYY-MM-DD"),
                  kindergarten: kinderObjectId,
                  child: childId
                }
              })
            )
          );
        }
      });
    });

    const prom = Promise.all(response.flat());
    prom.then(() => {
      context.dispatch("testFetch");
      const { groups } = context.getters.getChildrenVisitLog;
      if (groups == -1) {
        context.commit("setNameChildrenGroup", {
          fieldName: "nameGroup",
          value: "Все группы"
        });
      } else {
        context.dispatch("fetchChildrenGroupByIdGetName", groups);
      }
      context.commit("clearEditedChilds");
    });
    return prom;
  },

  async getKinderObjectId(context, { kindergartenOrgId }) {
    return new Promise((resolve, reject) => {
      getKindergartenByIdClearRequest({ id: kindergartenOrgId })
        .then(response => {
          resolve(response.data.object.id);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const mutations = {
  saveVisitDeti(state, { value }) {
    state.visitDeti = value;
  },
  setChildrenVisitLog(state, { fieldName, value }) {
    state[fieldName] = value;
  },
  setChildrenVisitLogData(state, { fieldName, value }) {
    state.childrenVisitLog[fieldName] = value;
  },
  setCreateChildrenVisitLog(state, { value }) {
    const { status, childId, date, id } = value;
    if (!state.editChildrenVisitLog.edited[childId]) {
      state.editChildrenVisitLog.edited[childId] = [
        { date, status, edited: false, id }
      ];
    } else {
      const findIndex = state.editChildrenVisitLog.edited[
        childId
      ].findIndex(el => el.date.isSame(date, "day"));
      if (~findIndex)
        state.editChildrenVisitLog.edited[childId].splice(findIndex, 1, {
          date,
          status,
          id,
          edited: true
        });
      else
        state.editChildrenVisitLog.edited[childId].push({
          date,
          status,
          edited: false,
          id
        });
    }
  },
  clearEditedChilds(state) {
    state.editChildrenVisitLog.edited = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

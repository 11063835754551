import {
  getServiceTasksRequest,
  getServiceTaskByIdRequest,
  createServiceTaskRequest,
  getServiceTaskCommentsRequest,
  createServiceTaskCommentsRequest,
  patchServiceTaskByIdRequest
} from "@/helpers/api/serviceTasks";
import Vue from "vue";
import moment from "moment";
import router from "@/router";
import { csvExport } from "@/helpers/CsvHelper";

const state = {
  pageServiceTasksList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  serviceTaskInfo: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  commentForm: {
    commentText: {
      value: "",
      error: null
    }
  },
  serviceTaskComments: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  serviceTaskCreateForm: {
    task_type: null,
    priority: null,
    title: "",
    description: "",
    object: null,
    controller: null,
    deadline_at: null
  },
  serviceTaskEditForm: {
    task_type: { value: null, isEdit: false },
    priority: { value: null, isEdit: false },
    title: { value: "", isEdit: false },
    description: { value: "", isEdit: false },
    object: { value: null, isEdit: false },
    controller: { value: null, isEdit: false },
    deadline_at: { value: null, isEdit: false }
  },
  organizationServiceTasksList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
};
const getters = {
  getPageServiceTasksList: state => state.pageServiceTasksList,
  getServiceTaskInfo: state => state.serviceTaskInfo,
  getCommentForm: state => state.commentForm,
  getServiceTaskCreateForm: state => state.serviceTaskCreateForm,
  getServiceTaskComments: state => state.serviceTaskComments,
  getServiceTaskEditForm: state => state.serviceTaskEditForm,
  getOrganizationServiceTasksList: state => state.organizationServiceTasksList
};

const actions = {
  async prepareEditServiceTask(context, { id }) {
    const editServiceTask = (await getServiceTaskByIdRequest({ id })).data;
    editServiceTask.deadline_at = moment(
      editServiceTask.deadline_at * 1000
    ).format("YYYY-MM-DD");
    editServiceTask.object = editServiceTask.object_details.id;
    context.commit("prepareEditServiceTask", editServiceTask);
  },
  async editServiceTask(context, { id }) {
    const editForm = context.getters.getServiceTaskEditForm;
    let editedData = {};
    for (let key in editForm) {
      if (editForm[key].isEdit) {
        editedData[key] = editForm[key].value;
      }
    }
    if (editedData.deadline_at) {
      editedData.deadline_at = moment(editedData.deadline_at).unix();
    }
    const editProm = patchServiceTaskByIdRequest({ id, data: editedData });
    editProm.then(() => {
      context.commit("clearEditServiceTaskForm");
      router.go(-1);
    });
    return editProm;
  },
  fetchPageServiceTasksList(context) {
    const query = context.getters.getPageQuery.query;
    return new Promise((resolve, reject) => {
      getServiceTasksRequest({ query })
        .then(response => {
          context.commit("setServiceTasksList", {
            listName: "pageServiceTasksList",
            serviceTasks: response.data
          });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  downloadPageServiceTasksList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getServiceTasksRequest({ query: { ...query, limit: 5000, offset: null } })
        .then(response => {
          csvExport(response.data, header, "Заявки");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
    // return downloadServiceTasksListRequest({ query });
  },
  async downloadServiceOrganizationServiceTasksList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getServiceTasksRequest({
        query: { ...query, limit: 5000, offset: null, service_organization: id }
      })
        .then(response => {
          csvExport(response.data, header, "Заявки");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchOrganizationServiceTasksList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getServiceTasksRequest({
        query: { ...query, ordering, service_organization: id }
      })
        .then(response => {
          context.commit("setServiceTasksList", {
            listName: "organizationServiceTasksList",
            serviceTasks: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async clearServiceTasksPage(context) {
    context.commit("clearServiceTasksList", {
      listName: "pageServiceTasksList"
    });
    return Promise.resolve();
  },
  async fetchPageServiceTaskInfo(context) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getServiceTaskByIdRequest({ id, query })
        .then(response => {
          context.commit("setServiceInfoData", {
            listName: "serviceTaskInfo",
            serviceTaskInfo: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async createTask(context) {
    const {
      // eslint-disable-next-line no-unused-vars
      object,
      ...serviceTaskForm
    } = context.getters.getServiceTaskCreateForm;
    const createResult = createServiceTaskRequest({
      data: {
        ...serviceTaskForm,
        deadline_at: moment(serviceTaskForm.deadline_at).unix(),
        created_at: moment().unix()
      }
    });

    createResult.then(() => {
      router.go(-1);
      context.commit("clearCreateServiceTaskForm");
    });
    return createResult;
  },
  fetchServiceTaskComments(context) {
    const { id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getServiceTaskCommentsRequest({ id })
        .then(response => {
          context.commit("setServiceTaskComments", {
            listName: "serviceTaskComments",
            serviceTaskComments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async createComment(context) {
    const { id } = context.getters.getPageQuery;
    const commentForm = context.getters.getCommentForm;
    return createServiceTaskCommentsRequest({
      id: id,
      data: {
        text: commentForm.commentText.value,
        service_task: id
      }
    }).finally(() => {
      context.dispatch("fetchServiceTaskComments");
    });
  }
};

const mutations = {
  clearCreateServiceTaskForm(state) {
    state.serviceTaskCreateForm = {
      task_type: null,
      priority: null,
      title: "",
      description: "",
      object: null,
      controller: null,
      deadline_at: null
    };
  },
  prepareEditServiceTask(state, editServiceTask) {
    Object.keys(state.serviceTaskEditForm).forEach(key => {
      state.serviceTaskEditForm[key].value = editServiceTask[key];
      state.serviceTaskEditForm[key].isEdit = false;
    });
  },
  changeEditServiceTask(state, { fieldName, value }) {
    state.serviceTaskEditForm[fieldName].value = value;
    state.serviceTaskEditForm[fieldName].isEdit = true;
  },
  clearEditServiceTaskForm(state) {
    state.serviceTaskEditForm = {
      task_type: { value: null, isEdit: false },
      priority: { value: null, isEdit: false },
      title: { value: "", isEdit: false },
      description: { value: "", isEdit: false },
      object: { value: null, isEdit: false },
      controller: { value: null, isEdit: false },
      deadline_at: { value: null, isEdit: false }
    };
  },
  setServiceTaskCreateForm(state, { fieldName, value }) {
    Vue.set(state.serviceTaskCreateForm, fieldName, value);
  },
  setServiceTasksList(state, { listName, serviceTasks }) {
    state[listName] = serviceTasks;
  },
  clearServiceTasksList(state, { listName }) {
    state[listName].results = [];
  },
  setServiceInfoData(state, { listName, serviceTaskInfo }) {
    state[listName] = serviceTaskInfo;
  },
  setServiceTaskComments(state, { listName, serviceTaskComments }) {
    state[listName] = serviceTaskComments;
  },
  setComment(state, { formName, field, value }) {
    state[formName][field].value = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getAppPricesRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/app-prices/",
      query: {
        ...query
      }
    })
  );
}
export async function createAppPriceRequest({ data }) {
  return Axios.post("/app-prices/", data);
}
export async function getAppPriceByIdRequest({ id }) {
  return Axios.get(`/app-prices/${id}/`);
}

export async function putAppPriceByIdRequest({ id, data }) {
  return Axios.put(`/app-prices/${id}/`, data);
}
export async function patchAppPriceByIdRequest({ id, data }) {
  return Axios.patch(`/app-prices/${id}/`, data);
}
export async function deleteAppPriceByIdRequest({ id }) {
  return Axios.delete(`/app-prices/${id}/`);
}

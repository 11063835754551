import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getKindergartensRequest({ query }) {
  return new Promise((resolve, reject) => {
    Axios.get(
      getQueriedUrl({
        url: "/kindergartens/",
        query: {
          ...query,
          "optional-fields": [
            "org_admins",
            "diller_name",
            "controllers_count",
            "optional-fields",
            "diller_name",
            "users_count",
            "city",
            "city_details",
            "diller_payments_sum_all_time",
            "payments_sum_last_month",
            "payments_sum_last_year"
          ]
        }
      })
    )
      .then(response => {
        let data = response.data;
        data.results = data.results.map(el => ({
          ...el.children,
          ...el.object,
          ...el
        }));
        resolve({ ...response, data });
      })
      .catch(err => reject(err));
  });
}

export async function getKindergartensClearRequest({ query }) {
  return new Promise((resolve, reject) => {
    Axios.get(
      getQueriedUrl({
        url: "/kindergartens/",
        query: {
          ...query
        }
      })
    )
      .then(response => {
        let data = response.data;
        data.results = data.results.map(el => ({
          ...el.children,
          ...el.object,
          ...el
        }));
        resolve({ ...response, data });
      })
      .catch(err => reject(err));
  });
}
export async function getPersonnelKindergartensRequest({ id, query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${id}/personnel/`,
      query: {
        ...query
      }
    })
  );
}

export async function getPersonnelIdKindergartensRequest({
  id,
  kindergartens_pk
}) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${kindergartens_pk}/personnel/${id}/`
    })
  );
}

export async function deletePersonnelKindergartensRequest({
  id,
  kindergartens_pk
}) {
  return Axios.delete(`/kindergartens/${kindergartens_pk}/personnel/${id}`);
}

export async function downloadPageKindergartensListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/kindergartens/",
      query: {
        ...query,
        "optional-fields": [
          "org_admins",
          "diller_name",
          "optional-fields",
          "diller_name",
          "controllers_count",
          "users_count",
          "city",
          "city_details",
          "diller_payments_sum_all_time",
          "payments_sum_last_month",
          "payments_sum_last_year"
        ],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "детские сады.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function getRawKindergartenRequest({ query }) {
  return Axios.get(getQueriedUrl({ url: "/kindergartens/", query }));
}
export async function getKindergartenByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${id}/`,
      query: {
        "optional-fields": [
          "org_admins",
          "diller",
          "children_count",
          "optional-fields",
          "diller_name",
          "controllers_count",
          "users_count",
          "cards_count",
          "city",
          "city_details",
          "teachers_count",
          "diller_payments_sum_all_time",
          "payments_sum_last_month",
          "payments_sum_last_year",
          "additional_fields"
        ]
      }
    })
  );
}

export async function getKindergartenByIdClearRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${id}/`,
      query: {}
    })
  );
}

export async function getPersonnelGroupByRequest(query) {
  return Axios.get(
    getQueriedUrl({
      url: "/groups/role-groups/",
      query
    })
  );
}

export async function restoreKindergartenByIdRequest({ id, data }) {
  return Axios.post(`/kindergartens/${id}/restore_deleted/`, data);
}

/**
 * @typedef {Object} token
 * @property {string} access - access token
 * @property {string} user - user id
 * @property {number} expires - access token expires time
 * @property {string} refresh - refresh token
 * @property {number} refresh_expires - refresh token expires time
 * @property {number} now - current time
 *
 */

/**
 *
 * @param {token} token
 */
export function saveToken(token) {
  if (token) localStorage.setItem("token", JSON.stringify(token));
}

/**
 * @return {token|null}
 *         The location of the event
 */
export function getToken() {
  const strToken = localStorage.getItem("token");
  return strToken ? JSON.parse(strToken) : null;
}

export function removeToken() {
  localStorage.clear();
}

import { getUsersByAccessGroup } from "@/helpers/api/user";
import {
  postDeleteUserFromGroupRequest,
  createUserWithGroupRequest,
  postAddUserToGroupRequest
} from "@/helpers/api/user";
import router from "@/router";

const state = {
  admins: {
    count: "",
    results: []
  }
};
const getters = {
  getPageAdminsList: state => state.admins
};

const actions = {
  async fetchPageAdminsList(context, { group_id } = "") {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getUsersByAccessGroup({
        group_id,
        query: {
          ordering,
          ...query
        }
      })
        .then(response => {
          context.commit("SET_ADMINS", {
            listName: "admins",
            value: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async deleteAdminFromGroup(context, { id }) {
    const prom = postDeleteUserFromGroupRequest({ id, data: { group: 1 } });
    return prom;
  },

  async clearAdminsPage(context) {
    context.commit("CLEAR_ADMIN_LIST", {
      listName: "admins"
    });
    return Promise.resolve();
  },

  async addAdminToGroup(context) {
    const adminForm = context.getters.getAddAdminForm;
    let prom;
    if (!adminForm.selectedId) {
      prom = createUserWithGroupRequest({
        data: {
          ...adminForm,
          phone_number: adminForm.phone_number
            .replace(/[^\d+]/g, "")
            .slice(0, 13),
          group: 1
        }
      });
    } else {
      prom = postAddUserToGroupRequest({
        id: adminForm.selectedId,
        data: { group: 1 }
      });
    }
    prom.then(() => {
      router.go(-1);
    });
    return prom;
  }
};

const mutations = {
  SET_ADMINS(state, { listName, value }) {
    state[listName].count = value.count;
    state[listName].results = value.results;
  },

  CLEAR_ADMIN_LIST(state, { listName }) {
    state[listName].count = null;
    state[listName].results = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getChildrenVisitLogsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/child-visit-log/",
      query: {
        ...query,
        ordering: "-id"
      }
    })
  );
}

export async function downloadChildrenVisitLogListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/child-visit-log/",
      query: {
        ...query,
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "табель посещаемости.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function createChildrenVisitLogRequest({ data }) {
  return Axios.post("/child-visit-log/", data);
}
export async function getChildrenVisitLogByIdRequest({ id }) {
  return Axios.get(`/child-visit-log/${id}/`);
}

export async function putChildrenVisitLogByIdRequest({ id, data }) {
  return Axios.put(`/child-visit-log/${id}/`, data);
}
export async function patchChildrenVisitLogByIdRequest({ id, data }) {
  return Axios.patch(`/child-visit-log/${id}/`, data);
}
export async function deleteChildrenVisitLogByIdRequest({ id }) {
  return Axios.delete(`/child-visit-log/${id}/`);
}

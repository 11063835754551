const state = {
  notifyTitle: "Ошибка",
  notifyErrors: [],
  notifySuccess: null,
  notifyWarning: null
};
const getters = {
  getNotifyTitle: state => state.notifyTitle,
  getNotifyErrors: state => state.notifyErrors,
  getNotifySuccess: state => state.notifySuccess,
  getNotifyWarning: state => state.notifyWarning
};

const actions = {
  async errorNotification(context, { errors }) {
    context.commit("SET_ERROR_NOTIFICATION", { errors });
  },
  async successNotification(context, payload) {
    context.commit("SET_SUCCESS_NOTIFICATION", payload);
  },
  async warningNotification(context, payload) {
    context.commit("SET_WARNING_NOTIFICATION", payload);
  }
};

const mutations = {
  SET_ERROR_NOTIFICATION(state, { errors }) {
    state.notifyTitle = "Ошибка";
    state.notifyErrors = errors;
  },

  SET_SUCCESS_NOTIFICATION(state, payload) {
    state.notifyTitle = "Успех";
    state.notifySuccess = payload;
  },

  SET_WARNING_NOTIFICATION(state, payload) {
    state.notifyTitle = "Внимание";
    state.notifyWarning = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

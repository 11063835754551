import objectRoutes from "@/router/childRoutes/objectRoutes";
import controllersRoutes from "@/router/childRoutes/controllersRoutes";
import userGroupRoutes from "@/router/childRoutes/userGroupRoutes";

const CompanyRoutes = [
  {
    name: "UsersCreate",
    path: "/createUser",
    component: () => import("@/views/UsersPages/CreateUserPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateUserGroup",
    path: "/createUserGroup",
    component: () => import("@/views/UserGroupsPages/CreateUserGroupPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateController",
    path: "/createController",
    component: () => import("@/views/ControllersPages/ControllerCreatePage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateObject",
    path: "/createObject",
    component: () => import("@/views/ObjectsPages/CreateObjectPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "ObjectsInfo",
    path: "/objects/:id",
    component: () => import("@/views/ObjectsPages/ObjectsInfosPages"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  ...objectRoutes(),
  {
    name: "ControllersInfo",
    path: "/controllers/:id",
    component: () => import("@/views/ControllersPages/ControllersInfosPages"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  ...controllersRoutes(),
  {
    name: "UserGroupsInfo",
    path: "/userGroups/:id",
    component: () => import("@/views/UserGroupsPages/UserGroupsInfosPages"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  ...userGroupRoutes(),
  {
    name: "UsersInfo",
    path: "/users/:id",
    component: () => import("@/views/UsersPages/UsersInfosPages"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "ServesOrganizationInfo",
    path: "/serviceOrganization/:id",
    component: () =>
      import("@/views/ServesOrganizationPages/ServiceOrganizationInfosPages"),
    meta: {
      requiresAuth: true
    },
    props: true
  },

  {
    name: "CreateAdmin",
    path: "/createAdmin",
    component: () => import("@/views/AdminsPage/CreateAdminPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  }
];

function fromCompany() {
  return CompanyRoutes.map(el => {
    return {
      ...el,
      name: "FromCompany" + el.name,
      path: "/company/:companyId" + el.path,
      props: true
    };
  });
}

export default fromCompany;

import CompanyRoutes from "@/router/childRoutes/CompanyRoutes";
import objectRoutes from "@/router/childRoutes/objectRoutes";
import KindergartenRoutes from "@/router/childRoutes/KindergartenRoutes";
import controllersRoutes from "@/router/childRoutes/controllersRoutes";

const DealerRoutes = [
  {
    name: "CreateCompany",
    path: "/createCompany",
    component: () => import("@/views/CompanyPages/CreateCompanyPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateController",
    path: "/createController",
    component: () => import("@/views/ControllersPages/ControllerCreatePage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateObject",
    path: "/createObject",
    component: () => import("@/views/ObjectsPages/CreateObjectPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "createKindergartens",
    path: "/createKindergartens",
    component: () =>
      import("@/views/KindergartensPages/CreateKindergartenPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CompanyInfo",
    path: "/company/:id",
    component: () => import("@/views/CompanyPages/CompanyInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "KindergartenInfo",
    path: "/kindergartens/:id",
    component: () =>
      import("@/views/KindergartensPages/KindergartensInfosPages"),
    meta: {
      requiresAuth: true
    }
  },

  {
    name: "CreateAdmin",
    path: "/createAdmin",
    component: () => import("@/views/AdminsPage/CreateAdminPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  ...CompanyRoutes(),
  {
    name: "ObjectsInfo",
    path: "/objects/:id",
    component: () => import("@/views/ObjectsPages/ObjectsInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  ...objectRoutes(),
  ...KindergartenRoutes(),
  {
    name: "ControllersInfo",
    path: "/controllers/:id",
    component: () => import("@/views/ControllersPages/ControllersInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  ...controllersRoutes()
];

function fromDealer() {
  return DealerRoutes.map(el => {
    return {
      ...el,
      name: "FromDealer" + el.name,
      path: "/dealer/:dealerId" + el.path
    };
  });
}

export default fromDealer;

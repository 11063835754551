import Axios from "axios";

export async function loginRequest({ phone, password }) {
  return Axios.post("/auth/login/", {
    phone,
    password
  });
}

export async function captchaRequest({ phone }) {
  return Axios.post("/captcha/", {
    phone
  });
}

export async function checkRegistrationCodeRequest({ temp_token, code }) {
  return Axios.post("/auth/check-registration-code/", {
    temp_token,
    code
  });
}

export async function passwordResetConfirmRequest({
  temp_token,
  code,
  password
}) {
  return Axios.post("/auth/password-reset-confirm/", {
    temp_token,
    code,
    password
  });
}

export async function passwordResetRequest({ phone, code_type }) {
  return Axios.post("/auth/password-reset/", { phone, code_type });
}

export function refreshTokenRequest({ refresh }) {
  return Axios.post("/auth/refresh-token/", { refresh });
}

export async function registrationRequest({
  phone,
  password,
  temp_token,
  code,
  captcha
}) {
  return Axios.post("/auth/registration/", {
    phone,
    password,
    temp_token,
    code,
    captcha
  });
}

export async function registrationByAccountRequest({
  object_id,
  object_uuid,
  account,
  phone,
  code_type
}) {
  return Axios.post("/auth/registration/by-account/", {
    object_id,
    object_uuid,
    account,
    phone,
    code_type
  });
}

export async function registrationByPhoneRequest({ phone, code_type }) {
  return Axios.post("/auth/registration/by-phone/", { phone, code_type });
}

export async function registrationCompleteRequest({ temp_token, password }) {
  return Axios.post("/auth/registration/complete/", { temp_token, password });
}

export async function registrationConfirmRegistrationCodeRequest({
  temp_token,
  code
}) {
  return Axios.post("/auth/registration/confirm/", {
    temp_token,
    code
  });
}

export async function registrationSendConfirmationCodeRequest({ phone }) {
  return Axios.post("/auth/registration/send-confirmation-code/ ", {
    phone,
    code_type: "phone_numbers"
  });
}

export async function logoutRequest({ userId }) {
  return Axios.post(`users/${userId}/logout/`);
}

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getControllerFirmwaresRequest({ id, query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/controller-firmwares/`,
      query: { ...query, "in-use": true, object: id }
    })
  );
}
export async function getControllerFirmwareRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/controller-firmwares/`,
      query: { ...query }
    })
  );
}

export async function getControllerFirmwaresByIdsRequest({ id, query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/controllers/${id}/firmwares/`,
      query: { ...query }
    })
  );
}

export async function getControllerFirmwaresClearRequest() {
  return Axios.get(
    getQueriedUrl({
      url: "/controller-firmwares/"
    })
  );
}

export async function createControllerFirmwaresRequest({ data }) {
  return Axios.post("/controller-firmwares/", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export async function getControllerFirmwaresByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/controller-firmwares/${id}/`
    })
  );
}

export async function updateControllersPrimaryFirmware({ id, data }) {
  return Axios.post(`/controllers/${id}/update_primary_firmware/`, data);
}

export async function updateControllersSecondaryFirmware({ id, data }) {
  return Axios.post(`/controllers/${id}/update_secondary_firmware/`, data);
}

export async function updatePatchFirmwaresRequest({ id, data }) {
  return Axios.patch(`/controller-firmwares/${id}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export async function deleteFirmwareByIdRequest({ id }) {
  return Axios.delete(`/controller-firmwares/${id}`);
}

export async function deleteHashByIdRequest({ id }) {
  return Axios.delete(`/controllers/${id}/hash-ttl/`);
}

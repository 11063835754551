import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getPromocodeRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/payments/coupons/",
      query: {
        ...query,
        ordering: "-created_at",
        "optional-fields": ["created_by_detail"]
      }
    })
  );
}

export async function getPromocodeClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/payments/coupons/",
      query: {
        ...query
      }
    })
  );
}

export async function CheckPromocodesRequest({ data }) {
  return Axios.post("/payments/coupons/check/", data);
}

export async function createPromocodeRequest({ data }) {
  return Axios.post("/payments/coupons/", data);
}

export async function deleteArrPromocodesRequest({ data }) {
  return Axios.post(`/payments/coupons/delete/`, data);
}

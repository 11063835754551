import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getUserCountStats({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/stats/users/count",
      query
    })
  );
}

export async function getPaymentsStats({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/stats/payments/dealers",
      query
    })
  );
}

export async function getPaymentsDealer({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/stats/dealer",
      query
    })
  );
}

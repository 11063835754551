const children = [
  {
    name: "ParentsCreate",
    path: "/parentsCreate",
    component: () => import("@/views/ParentPage/ParentsCreateFormPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "ChildrenInfo",
    path: "/children/:id",
    component: () => import("@/views/ChildrenPages/ChildrenInfosPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "ParentInfos",
    path: "/parentInfos/:id",
    component: () => import("@/views/ParentPage/ParentInfosPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "EditParent",
    path: "/parentInfos/:parentId/editParent/:id",
    component: () => import("@/views/ParentPage/EditParent"),
    meta: {
      requiresAuth: true
    }
  }
];
function fromChildrenRoutes() {
  return children.map(el => {
    return {
      ...el,
      name: "FromChildren" + el.name,
      path: "/children/:childrenId" + el.path
    };
  });
}

export default fromChildrenRoutes;

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getMessagesRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/messages/",
      query: {
        ...query,
        "optional-fields": [
          `by_city_details`,
          `by_diller_details`,
          "by_organization_details",
          `by_object_details`,
          `by_group_details`,
          `sender_details`
        ]
      }
    })
  );
}

export async function getMessagesClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/messages/",
      query: {
        ...query
      }
    })
  );
}

export async function createMessageRequest({ data }) {
  return Axios.post("/messages/", data);
}
export async function getMessageByIdRequest({ id }) {
  return Axios.get(`/messages/${id}/`);
}

export async function putMessageByIdRequest({ id, data }) {
  return Axios.put(`/messages/${id}/`, data);
}
export async function patchMessageByIdRequest({ id, data }) {
  return Axios.patch(`/messages/${id}/`, data);
}
export async function deleteMessageByIdRequest({ id }) {
  return Axios.delete(`/messages/${id}/`);
}

import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth";
import cities from "@/store/modules/cities";
import objects from "@/store/modules/objects";
import organizations from "@/store/modules/organizations";
import searchQuery from "@/store/modules/searchQuery";
import kindergartens from "@/store/modules/kindergartens";
import users from "@/store/modules/users";
import controllers from "@/store/modules/controllers";
import tabbar from "@/store/modules/tabbar";
import cards from "@/store/modules/cards";
import accounts from "@/store/modules/accounts";
import groups from "@/store/modules/userGroups";
import payments from "@/store/modules/payments";
import serviceTasks from "@/store/modules/serviceTasks";
import messages from "@/store/modules/messages";
import Notify from "@/store/modules/Notify";
import userInterface from "@/store/modules/userInterface";
import kindergartensChildrenGroups from "@/store/modules/kindergartensChildrenGroups";
import Children from "@/store/modules/Childrens";
import Passagelog from "@/store/modules/kindergartensPassLog";
import Admins from "@/store/modules/Admins";
import profile from "@/store/modules/profile";
import serviceOrganizationStaff from "@/store/modules/serviceOrganizatonStaff";
import serviceGroups from "@/store/modules/serviceGroups";
import childrenVisitLog from "@/store/modules/childrenVisitLog";
import parents from "@/store/modules/parents";
import visits from "@/store/modules/visitLogs";
import adminsOppen from "@/store/modules/adminsOppen";
import firmwares from "@/store/modules/controllersFirmwares";
import sortQuery from "@/store/modules/sortQuery";
import others from "@/store/modules/others";
import stats from "@/store/modules/stats";
import statsInfopanel from "@/store/modules/statsInfopanel";
import promocode from "@/store/modules/promocode";
import codes from "@/store/modules/codes";
import accesses from "@/store/modules/accesses";
import review from "@/store/modules/review";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    cities,
    objects,
    organizations,
    searchQuery,
    kindergartens,
    users,
    controllers,
    tabbar,
    cards,
    accounts,
    groups,
    payments,
    serviceTasks,
    messages,
    userInterface,
    kindergartensChildrenGroups,
    Children,
    Passagelog,
    Notify,
    Admins,
    profile,
    serviceOrganizationStaff,
    serviceGroups,
    childrenVisitLog,
    parents,
    visits,
    adminsOppen,
    firmwares,
    sortQuery,
    others,
    stats,
    statsInfopanel,
    promocode,
    codes,
    accesses,
    review
  }
});

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getServiceGroupsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/service-groups/",
      query: {
        ...query,
        "optional-fields": [`specialist_details`, `object_count`]
      }
    })
  );
}

export async function getServiceGroupsClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/service-groups/",
      query: {
        ...query
      }
    })
  );
}
export async function createServiceGroupRequest({ data }) {
  return Axios.post("/service-groups/", data);
}
export async function getServiceGroupByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/service-groups/${id}/`,
      query: {
        "optional-fields": [`specialist_details`, `object_count`]
      }
    })
  );
}
export async function putServiceGroupByIdRequest({ id, data }) {
  return Axios.put(`/service-groups/${id}/`, data);
}
export async function patchServiceGroupByIdRequest({ id, data }) {
  return Axios.patch(`/service-groups/${id}/`, data);
}
export async function deleteServiceGroupByIdRequest({ id }) {
  return Axios.delete(`/service-groups/${id}/`);
}
export async function downloadServiceGroupsListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/service-groups/",
      query: {
        ...query,
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "группы_объектов.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}

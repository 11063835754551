import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getObjectsAddressRequest({ search = null, city = null }) {
  return Axios.get(
    getQueriedUrl({ url: "/objects/address/", query: { search, city } })
  );
}

export async function getObjectsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/objects/",
      query: {
        ...query,
        "optional-fields": [
          "service_group_name",
          `accounts_count`,
          `controllers_count`,
          `groups_count`,
          `users_count`,
          `tarifs_count`,
          "organization_details",
          "organization_diller",
          "service_org_display",
          "city_details",
          "service_technician",
          "organization",
          "additional_fields"
        ],
        "exclude-type": "kindergarten"
      }
    })
  );
}
export async function getObjectsRequestFromChildren({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/objects/",
      query: {
        ...query,
        "optional-fields": [
          "service_group_name",
          `accounts_count`,
          `controllers_count`,
          `groups_count`,
          `users_count`,
          `tarifs_count`,
          "organization_details",
          "organization_diller",
          "service_org_display",
          "city_details",
          "service_technician",
          "organization",
          "additional_fields"
        ]
        // "exclude-type": "kindergarten"
      }
    })
  );
}
export async function getObjectsClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/objects/",
      query: {
        ...query
      }
    })
  );
}
export async function downloadObjectsListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/objects/",
      query: {
        ...query,
        "optional-fields": [
          `accounts_count`,
          `controllers_count`,
          `groups_count`,
          `users_count`,
          `tarifs_count`,
          "organization_details",
          "organization_diller",
          "service_org_display",
          "city_details",
          "service_technician",
          "additional_fields"
        ],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "объекты.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function createObjectRequest({ data }) {
  return Axios.post("/objects/", data);
}
export async function getObjectClearByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/objects/${id}/`,
      query: {}
    })
  );
}

export async function getObjectByIdRequest({ id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/objects/${id}/`,
      query: {
        "optional-fields": [
          `accounts_count`,
          `controllers_count`,
          `access_groups_count`,
          `users_count`,
          `tarifs_count`,
          "organization_details",
          "organization_diller",
          "service_org_display",
          "city_details",
          "service_technician",
          "cards_count",
          "additional_fields"
        ]
      }
    })
  );
}

export async function putObjectByIdRequest({ id, data }) {
  return Axios.put(`/objects/${id}/`, data);
}
export async function patchObjectByIdRequest({ id, data }) {
  return Axios.patch(`/objects/${id}/`, data);
}
export async function patchObjectGuestAccessByIdRequest({ id, data }) {
  return Axios.patch(`/objects/${id}/guest-access-allowed/`, data);
}
export async function deleteObjectByIdRequest({ id }) {
  return Axios.delete(`/objects/${id}/`);
}

export async function getObjectsExtendedRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/objects/extended/",
      query: {
        ...query
      }
    })
  );
}
export async function getObjectExtendedByIdRequest({ id }) {
  return Axios.get(`/objects/extended/${id}/`);
}
export async function getObjectAccessibleGroupsByIdRequest({ id }) {
  return Axios.get(`/objects/${id}/accessible-groups/`);
}
export async function getObjectInaccessibleGroupsByIdRequest({ id }) {
  return Axios.get(`/objects/${id}/inaccessible-groups/`);
}
export async function restoreObjectByIdRequest({ id, data }) {
  return Axios.post(`/objects/${id}/restore_deleted/`, data);
}

import {
  createMessageRequest,
  getMessagesRequest
} from "@/helpers/api/messages";
import { getUsersClearRequest } from "@/helpers/api/user";
import { csvExport } from "@/helpers/CsvHelper";
import { getChildrenGroupsRequest } from "@/helpers/api/childrenGroup";
import { getUsersRequest } from "@/helpers/api/user";
import { getKindergartenByIdClearRequest } from "@/helpers/api/kindergartens";

const state = {
  pageMessagesList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  createMessageForm: {
    id: [],
    by_children_group: null,
    subject: "",
    phone_numbers: [],
    file: null,
    type: "push",
    text: "",
    user_count: null,
    by_city: null,
    by_ownership: null,
    by_diller: null,
    by_organization: null,
    by_object_type: null,
    by_service_organization: null,
    by_object: null,
    by_group: null,
    by_push_device_type: null
  }
};
const getters = {
  getPageMessagesList: state => state.pageMessagesList,
  getCreateMessageForm: state => state.createMessageForm
};

const actions = {
  async sendMessage(context) {
    const messageForm = context.state.createMessageForm;

    let formData;
    const phoneNumbersArray = [];

    if (messageForm.file) {
      formData = new FormData();
      formData.append("phone_numbers_file", messageForm.file);
      if (messageForm.phone_numbers) {
        messageForm.phone_numbers.forEach(item =>
          phoneNumbersArray.push(item.text.replace(/\D/g, ""))
        );
      }
    } else {
      formData = { ...messageForm };
      if ([31, 30].includes(context.getters.getCurrentRole)) {
        const { id: kinderObjectId } = await getKindergartenByIdClearRequest({
          id: localStorage.getItem("organization")
        });
        formData.by_object = kinderObjectId;
      }
      delete formData.user_count;
      if (messageForm.phone_numbers) {
        formData.phone_numbers = messageForm.phone_numbers.map(item =>
          item.text.replace(/\D/g, "")
        );
      }
    }

    const prom = createMessageRequest({
      data: formData,
      headers: messageForm.file ? { "Content-Type": "multipart/form-data" } : {}
    });

    prom.then(() => {
      context.commit("clearMessageForm");
    });

    return prom;
  },
  async fetchUserCount(context) {
    const messageForm = context.state.createMessageForm;
    let arrObjectsId = [];

    for (let i = 0; i < messageForm.by_object.length; i++) {
      arrObjectsId.push(messageForm.by_object[i].value);
    }
    const users = (
      await getUsersClearRequest({
        query: {
          limit: 1,
          groups: messageForm.by_group,
          groups__object: arrObjectsId,
          groups__object__city: messageForm.by_city,
          groups__organization__diller: messageForm.by_diller,
          groups__object__type: messageForm.by_object_type,
          groups__object__service_organization:
            messageForm.by_service_organization,
          groups__organization: messageForm.by_organization,
          groups__organization__ownership: messageForm.by_organization_type,
          push_device_type: messageForm.by_push_device_type
        }
      })
    ).data;
    context.commit("setMessageCreateFormValue", {
      fieldName: "user_count",
      value: users.count
    });
  },
  async fetchUserCountInChildrenGroup(
    context,
    { childrenGroupId, organization }
  ) {
    const childrenGroups = (
      await getChildrenGroupsRequest({
        // нашли все детские группы в ДС
        query: { kindergarten_org: organization }
      })
    ).data.results;
    const groupId = childrenGroups.filter(el => el.id == childrenGroupId)[0]
      .group; // получили id группы пользователей к которой привязана детская группа
    const users = (
      await getUsersRequest({
        // получили пользователей входящие в группу пользователей
        query: {
          groups: groupId,
          limit: 1
        }
      })
    ).data;
    context.commit("setMessageCreateFormValue", {
      fieldName: "user_count",
      value: users.count
    });
  },
  downloadMessagesPage(context, header) {
    const query = context.getters.getPageQuery.query;
    return new Promise((resolve, reject) => {
      getMessagesRequest({
        query: { ...query, limit: 5000, offset: null }
      })
        .then(response => {
          csvExport(response.data, header, "Уведомления");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPageMessagesList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getMessagesRequest({
        query: {
          ordering,
          ...query
        }
      })
        .then(response => {
          context.commit("setMessagesList", {
            listName: "pageMessagesList",
            messages: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async clearMessagesPage(context) {
    context.commit("clearMessagesList", {
      listName: "pageMessagesList"
    });
    return Promise.resolve();
  }
};

const mutations = {
  setMessageCreateFormValue(state, { fieldName, value }) {
    state.createMessageForm[fieldName] = value;
  },
  setMessagesList(state, { listName, messages }) {
    state[listName] = messages;
  },
  clearMessagesList(state, { listName }) {
    state[listName].results = [];
  },
  clearMessageForm(state) {
    state.createMessageForm = {
      id: [],
      subject: "",
      type: "push",
      text: "",
      user_count: null,
      by_city: null,
      by_ownership: null,
      by_organization: null,
      by_object_type: null,
      by_service_organization: null,
      by_object: null,
      by_group: null,
      by_children_group: null,
      by_push_device_type: null
    };
  },
  deleteMessageObject(state, { number }) {
    state.createMessageForm.by_object.splice(number, 1);
    state.createMessageForm.id.splice(number, 1);
  },

  setMessageAllObjects(state, { objects }) {
    if (objects) {
      state.createMessageForm.id = objects.map(el => el.value);
      state.createMessageForm.by_object = objects;
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

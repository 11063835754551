import { getUsersRequest } from "@/helpers/api/user";

const state = {
  admins: {
    results: null
  }
};
const getters = {
  getAdmins(state) {
    return state.admins;
  }
};

const actions = {
  async fetchAdmins(context) {
    const { id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getUsersRequest({
        query: {
          "organization-admins": id
        }
      })
        .then(response => {
          context.commit("setAdmins", {
            listName: "admins",
            value: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  setAdmins(state, { listName, value }) {
    state[listName] = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

export const YMLoginMetrik = action => {
  if (typeof window !== "undefined" && window.ym !== undefined) {
    if (process.env.NODE_ENV === "production") {
      window.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, "reachGoal", action);
    }
  }
};

export const YMUserIdMetcrik = userId => {
  if (typeof window !== "undefined" && window.ym !== undefined) {
    if (process.env.NODE_ENV === "production") {
      window.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, "userParams", {
        userId
      });
    }
  }
};

export const YMRestoreAccess = action => {
  if (typeof window !== "undefined" && window.ym !== undefined) {
    if (process.env.NODE_ENV === "production") {
      window.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, "reachGoal", action);
    }
  }
};

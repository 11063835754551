import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";
export async function getPassageLogsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/visit-logs/",
      query: {
        "optional-fields": [
          "controller_details",
          "user_details",
          "object_details"
        ],
        ...query
      }
    })
  );
}

export async function getKindergartensPassageLogsRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${id}/visit-logs/`,
      query: {
        "optional-fields": [
          "controller_details",
          "user_details",
          "object_details"
        ],
        ...query
      }
    })
  );
}

export async function getPassageLogsObjectRequest({ query, id }) {
  return Axios.get(
    getQueriedUrl({
      url: `/objects/${id}/visit-logs/`,
      query: {
        "optional-fields": [
          "controller_details",
          "user_details",
          "object_details"
        ],
        ...query
      }
    })
  );
}

export async function getAllPassageLogsRequest({ query = {}, item }) {
  return Axios.get(
    getQueriedUrl({
      url: `/reports/${query.id}/file/`,
      query: {
        ...query
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    let date = item.start_date.split(" ");
    if (item.name == "Журнал проходов") {
      fileDownload(
        res.data,
        `Журнал_проходов_${date[0]}.csv`,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    } else if (item.name == "Список карт") {
      fileDownload(
        res.data,
        `Список_карт_${date[0]}.csv`,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    } else if (item.name == "Реестр оплаты") {
      fileDownload(
        res.data,
        `Реестр оплаты_${date[0]}.csv`,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    } else if (item.name == "Список контроллеров") {
      fileDownload(
        res.data,
        `Список контроллеров_${date[0]}.csv`,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    }
  });
}

export async function getPassageLogsRequests({ query, data }) {
  return Axios.post(`/visit-logs/objects/${query.id}/reports/`, data);
}

export async function downloadPassageLogRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/visit-logs/",
      query: {
        ...query,
        "optional-fields": ["controller_details", "user_details"],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "журнал проходов.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}

export async function downloadObjectPassageLogsListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/visit-logs/",
      query: {
        ...query,
        ["optional-fields"]: ["controller_details", "user_details"],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "журнал проходов.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}

export async function getPassageLogsClearRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/visit-logs/",
      query: {
        ...query
      }
    })
  );
}
// export async function createPassLogRequest({}) {
// return Axios.post("//", );
// }
// export async function getPassLogByRequest({}) {
// return Axios.get(`//${}/`);
// }
//
// export async function putPassLogByRequest({, }) {
// return Axios.put(`//${}/`, );
// }
// export async function patchPassLogByRequest({, }) {
// return Axios.patch(`//${}/`, );
// }
// export async function deletePassLogByRequest({}) {
//     return Axios.delete(`//${}/`);
// }

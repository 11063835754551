<template>
  <fragment
    v-if="
      'MyObjects' != this.$route.name ||
        this.$store.state.promocode.errMessages.detail !==
          'Данный промокод не найден. Возможно, он введен неверно или уже не действует.'
    "
  >
    <v-snackbar v-if="title == 'Успех'" bottom right v-model="status">
      <v-alert dense text type="success">
        <div class="success__text">{{ success }}</div>
      </v-alert>
    </v-snackbar>
    <v-snackbar v-else-if="title == 'Внимание'" bottom right v-model="status">
      <v-alert dense text type="warning">
        <div class="warning__text">{{ warning }}</div>
      </v-alert>
    </v-snackbar>

    <v-snackbar v-else type="error" bottom right v-model="status">
      <div>{{ title }}</div>
      <div>
        <div v-for="(error, index) in errors" :key="index">
          {{
            error.field_verbose != null && error.field_verbose != undefined
              ? error.field_verbose + " - "
              : ""
          }}
          {{ error.message }}
        </div>
      </div>
    </v-snackbar>
  </fragment>
</template>

<script>
export default {
  name: "Notify",
  data() {
    return {
      status: false
    };
  },
  created() {
    this.$store.subscribe(mutation => {
      if (mutation.type === "SET_ERROR_NOTIFICATION") {
        this.status = false;
        setTimeout(() => {
          this.status = true;
        }, 100);
      }
      if (mutation.type === "SET_SUCCESS_NOTIFICATION") {
        this.status = false;
        setTimeout(() => {
          this.status = true;
        }, 500);
      }
      if (mutation.type === "SET_WARNING_NOTIFICATION") {
        this.status = false;
        setTimeout(() => {
          this.status = true;
        }, 100);
      }
    });
  },
  computed: {
    title() {
      return this.$store.getters.getNotifyTitle;
    },
    errors() {
      return this.$store.getters.getNotifyErrors;
    },
    success() {
      return this.$store.getters.getNotifySuccess;
    },
    warning() {
      return this.$store.getters.getNotifyWarning;
    }
  }
};
</script>

<style scoped>
.success__text {
  color: green;
}
.warning__text {
  color: #fb8c00;
}
</style>

import router from "@/router";
import {
  getUserByIdRequest,
  patchUserByIdRequest,
  patchUserPhoneByIdRequest,
  changeUserPhoneByIdRequest,
  changeUserPhoneConfirm,
  deleteProfileRequest,
  getReports,
  deleteProfileConfirmRequest,
  restoreProfileRequest,
  deleteProfileForceRequest
} from "@/helpers/api/user";

const state = {
  id: null,
  fullName: "",

  userProfile: {
    phone_number: null,
    last_name: null,
    first_name: null,
    middle_name: null,
    email: null
  },
  desserts: {
    name: null,
    start_date: null,
    end_date: null,
    status: null
  },
  userProfileForm: {
    phone_number: {
      value: null,
      isEdit: false
    },
    last_name: {
      value: "",
      isEdit: false
    },
    first_name: {
      value: "",
      isEdit: false
    },
    middle_name: {
      value: "",
      isEdit: false
    },
    email: {
      value: null,
      isEdit: false
    }
  },
  userProfileNumber: {
    value: null,
    isEdit: false
  },
  tempToken: null
};
const getters = {
  getDesserts: state => state.desserts,
  getFullName: state => state.fullName,
  getUserProfile: state => state.userProfile,
  getUserProfileForm: state => state.userProfileForm,
  getUserProfileNumber: state => state.userProfileNumber,
  getUserConfirmTempToken: state => state.tempToken
};

const actions = {
  fetchUserProfile: async function(context) {
    const id = context.getters.getUserId;
    return new Promise((resolve, reject) => {
      getUserByIdRequest({
        id
      })
        .then(response => {
          context.commit("setUserProfile", response.data);
          context.commit("SET_USER_PROFILE_NUMBER", response.data.phone_number);
          context.commit("setFullName", response.data);
          context.commit("prepareEditUserProfile", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      if (
        context.getters.getCurrentRole == 30 ||
        context.getters.getCurrentRole == 60 ||
        context.getters.getCurrentRole == 70 ||
        context.getters.getCurrentRole == 31
      ) {
        return new Promise((resolve, reject) => {
          getReports({})
            .then(response => {
              context.commit("setReports", response.data);
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    });
  },
  async deleteProfile(context, { id, data }) {
    const prom = await deleteProfileRequest({ id, data }).then(response => {
      context.commit("SET_TEMP_TOKEN", response.data.temp_token);
    });
    return prom;
  },
  async deleteProfileForce(context) {
    let id;
    if (context.getters.getUserInfo.results.id) {
      id = context.getters.getUserInfo.results.id;
    } else {
      id = context.getters.getUserId;
    }

    const prom = await deleteProfileForceRequest({ id });
    return prom;
  },

  async deleteProfileConfirm(context, { code }) {
    const temp_token = context.getters.getUserConfirmTempToken;
    let id = context.getters.getUserId;

    const data = {
      temp_token,
      code
    };
    const prom = await deleteProfileConfirmRequest({ id, data });
    return prom;
  },

  async restoreProfile(context, { id }) {
    const prom = await restoreProfileRequest({ id });
    return prom;
  },

  prepareEditUserProfile(context) {
    context.commit("prepareEditUserProfile");
  },

  async editUserProfile(context) {
    const id = context.getters.getUserId;
    const editForm = context.getters.getUserProfileForm;

    let editedData = {};
    for (let key in editForm) {
      if (editForm[key].isEdit) {
        editedData[key] = editForm[key].value;
      }
    }
    if (editForm.phone_number.isEdit) {
      await patchUserPhoneByIdRequest({
        id,
        data: { phone_number: editForm.phone_number.value }
      });
    }
    const prom = patchUserByIdRequest({
      id,
      data: editedData
    });
    prom.finally(() => {
      router.go(-1);
    });
    return prom;
  },

  async editUserProfileFromPaymentAccess(context) {
    const id = context.getters.getUserId;
    const editForm = context.getters.getUserProfileForm;

    let editedData = {};
    for (let key in editForm) {
      if (editForm[key].isEdit) {
        editedData[key] = editForm[key].value;
      }
    }
    if (editForm.phone_number.isEdit) {
      await patchUserPhoneByIdRequest({
        id,
        data: { phone_number: editForm.phone_number.value }
      });
    }
    const prom = patchUserByIdRequest({
      id,
      data: editedData
    });

    return prom;
  },

  async editUserNumberByUser(context, { phone_number }) {
    const id = context.getters.getUserId;
    const data = {
      phone_number: phone_number
    };

    return new Promise((resolve, reject) => {
      changeUserPhoneByIdRequest({
        id,
        data
      })
        .then(response => {
          context.commit("SET_TEMP_TOKEN", response.data.temp_token);
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  async confirmUserNumber(context, { code }) {
    const temp_token = context.getters.getUserConfirmTempToken;
    const id = context.getters.getUserId;
    const data = {
      temp_token,
      code
    };
    return new Promise((resolve, reject) => {
      changeUserPhoneConfirm({
        id,
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  clearUserProfile(context) {
    context.commit("CLEAR_USER_PROFILE");
    return Promise.resolve();
  }
};

const mutations = {
  setFullName(state, data) {
    let { last_name, first_name, middle_name } = data;
    state.fullName = [last_name, first_name, middle_name].join(" ");
  },
  setUserProfile(state, data) {
    state.userProfile = data;
  },
  setReports(state, data) {
    state.desserts = data;
  },
  prepareEditUserProfile(state) {
    for (var fieldName in state.userProfile) {
      if (state.userProfileForm[fieldName])
        state.userProfileForm[fieldName].value = state.userProfile[fieldName];
    }
  },
  changeEditProfileValue(state, { fieldName, value }) {
    state.userProfileForm[fieldName].value = value;
    state.userProfileForm[fieldName].isEdit = true;
  },
  SET_USER_PROFILE_NUMBER(state, payload) {
    state.userProfileNumber.value = payload;
    state.userProfileNumber.isEdit = true;
  },

  SET_TEMP_TOKEN(state, payload) {
    state.tempToken = payload;
  },

  CLEAR_USER_PROFILE(state) {
    state.userProfileNumber.value = null;
    state.userProfileNumber.isEdit = null;
    state.tempToken = null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

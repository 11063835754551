import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getRoleGroupsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/groups/role-groups/",
      query: {
        ...query
      }
    })
  );
}
export async function createRoleGroupRequest({ data }) {
  return Axios.post("/groups/role-groups/", data);
}
export async function getRoleGroupByIdRequest({ id }) {
  return Axios.get(`/groups/role-groups/${id}/`);
}

export async function getRoleGroupOrganizationsByIdRequest({ id }) {
  return Axios.get(`/groups/role-groups/?organization=${id}`);
}

export async function putRoleGroupByIdRequest({ id, data }) {
  return Axios.put(`/groups/role-groups/${id}/`, data);
}
export async function patchRoleGroupByIdRequest({ id, data }) {
  return Axios.patch(`/groups/role-groups/${id}/`, data);
}
export async function deleteRoleGroupByIdRequest({ id }) {
  return Axios.delete(`/groups/role-groups/${id}/`);
}

import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import fileDownload from "js-file-download";

export async function getChildrenGroupsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/children-groups/",
      query: {
        ...query,
        limit: 5000,
        "optional-fields": ["teachers_details", "group_details"]
      }
    })
  );
}

export async function getChildrenGroupsClearRequest() {
  return Axios.get(
    getQueriedUrl({
      url: "/children-groups/",
      query: {
        limit: 5000,
        "optional-fields": ["teachers_details", "group_details"]
      }
    })
  );
}

export async function getKindergartensChildrenGroupsClearRequest({
  id,
  query
}) {
  return Axios.get(
    getQueriedUrl({
      url: `/kindergartens/${id}/children-groups/`,
      query: {
        ...query,
        limit: 5000,
        "optional-fields": ["teachers_details", "group_details"]
      }
    })
  );
}

export async function downloadChildrenGroupsListRequest({ query = {} }) {
  return Axios.get(
    getQueriedUrl({
      url: "/children-groups/",
      query: {
        ...query,
        "optional-fields": ["teachers_details", "group_details"],
        format: "xlsx"
      }
    }),
    {
      responseType: "blob"
    }
  ).then(res => {
    fileDownload(
      res.data,
      "группы детей.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  });
}
export async function createChildrenGroupRequest({ data }) {
  return Axios.post("/children-groups/", data);
}
export async function createManageressRequest({ id, data }) {
  return Axios.post(`/children-groups/${id}/teachers/`, data);
}

export async function getChildrenGroupByIdRequest({ id, query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/children-groups/${id}/`,
      query: {
        ...query,
        "optional-fields": ["group_details"]
      }
    })
  );
}

export async function putChildrenGroupByIdRequest({ id, data }) {
  return Axios.put(`/children-groups/${id}/`, data);
}
export async function patchChildrenGroupByIdRequest({ id, data }) {
  return Axios.patch(`/children-groups/${id}/`, data);
}
export async function deleteChildrenGroupByIdRequest({ id }) {
  return Axios.delete(`/children-groups/${id}/`);
}
export async function postTransferChildrenRequest({ kinderId, id, data }) {
  return Axios.post(
    `/kindergartens/${kinderId}/children/${id}/change-group/`,
    data
  );
}

import {
  getPassageLogsRequest,
  getPassageLogsRequests,
  getAllPassageLogsRequest,
  getPassageLogsObjectRequest,
  getKindergartensPassageLogsRequest
} from "@/helpers/api/PassageLog";
import { csvExport } from "@/helpers/CsvHelper";
import { getIdentificatorUserRole } from "../../../helpers/Constants/roles";

const state = {
  PassLog: {
    count: 1,
    next: null,
    previous: null,
    results: []
  },
  PassLogCount: {
    count: 1,
    next: null,
    previous: null,
    results: []
  },
  objectPassageLogList: {
    count: 1,
    next: null,
    previous: null,
    results: []
  }
};
const getters = {
  getPassLog: state => state.PassLog,
  getPassLogCount: state => state.PassLogCount
};

const actions = {
  downloadPassLog(context, header) {
    const { query } = context.getters.getPageQuery;

    return new Promise((resolve, reject) => {
      getPassageLogsRequest({
        query: { ...query, limit: 5000, offset: null }
      })
        .then(response => {
          csvExport(response.data, header, "Журнал Проходов");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  downloadKindergartensPassLog(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getKindergartensPassageLogsRequest({
        query: { ...query, limit: 5000, offset: null },
        id: getIdentificatorUserRole()
      })
        .then(response => {
          csvExport(response.data, header, "Журнал Проходов");
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  downloadPassLogs(context, item) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getAllPassageLogsRequest({
        query: { ...query, id: item.id, offset: null },
        item
      })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  downloadReportsPassLog(context, { data }) {
    const { query } = context.getters.getPageQuery;

    return new Promise((resolve, reject) => {
      getPassageLogsRequests({
        query: { ...query, offset: null, id: data.id },
        data: {
          moment_less: data.moment_lte,
          moment_greater: data.moment_gte
        }
      })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPassLog(context) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getPassageLogsRequest({
        query: { ...query }
      })
        .then(response => {
          context.commit("setPassLog", {
            listName: "PassLog",
            messages: response.data
          });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchKindergartensPassLog(context) {
    const { query } = context.getters.getPageQuery;

    return new Promise((resolve, reject) => {
      getKindergartensPassageLogsRequest({
        query: { ...query },
        id: getIdentificatorUserRole()
      })
        .then(response => {
          context.commit("setPassLog", {
            listName: "PassLog",
            messages: response.data
          });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchKindergartensPassLogCounts(context) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getKindergartensPassageLogsRequest({
        query: { ...query, limit: 5000 },
        id: getIdentificatorUserRole()
      })
        .then(response => {
          context.commit("setPassLog", {
            listName: "PassLogCount",
            messages: response.data
          });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchObjectPassLog(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPassageLogsObjectRequest({
        query: { ...query, controller__object: id, ordering },
        id
      })
        .then(response => {
          context.commit("setPassLog", {
            listName: "PassLog",
            messages: response.data
          });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  downloadObjectPassLog(context, header) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getPassageLogsRequest({
        query: { ...query, limit: 5000, offset: null, controller__object: id }
      })
        .then(response => {
          csvExport(response.data, header, "Журнал Проходов");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchUserPassLog(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPassageLogsRequest({ query: { ...query, ordering, user: id } })
        .then(response => {
          context.commit("setPassLog", {
            listName: "PassLog",
            messages: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchObjectPassageLog(context) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getPassageLogsRequest({ query: { ...query, controller__object_id: id } })
        .then(response => {
          context.commit("setPassLog", {
            listName: "objectPassageLogList",
            messages: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async clearPassLog(context) {
    context.commit("clearPassLog", {
      listName: "PassLog"
    });
    return Promise.resolve();
  }
};

const mutations = {
  setPassLog(state, { listName, messages }) {
    state[listName] = messages;
  },
  clearPassLog(state, { listName }) {
    state[listName].results = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
